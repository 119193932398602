import styles from './leads-active-tabs.module.scss';
interface TabButtonProps {
  label: string;
  tabId: number;
  activeTab: number;
  disabled: boolean;
  onClick: (tabId: number) => void;
}
export function TabButton({
  label,
  tabId,
  activeTab,
  disabled,
  onClick,
}: TabButtonProps) {
  return (
    <button
      disabled={disabled}
      onClick={() => onClick(tabId)}
      className={`${styles['tab']} ${
        activeTab === tabId && styles['tab--active']
      }`}
    >
      {label}
    </button>
  );
}

export default TabButton;
