import {
  OptionValue,
  PaginationInterface,
  UnitInterface,
} from '@orascom/api-interfaces';
import {
  CompareButton,
  UnitsListing,
} from '@orascom/broker-sales-man-common-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Unit as UnitUtils } from '../../utils/unit';
import { Loader, MultiSelectDropdown } from '@orascom/common-components';
import styles from './saved-units.module.scss';
import { Destination as DestinationUtils } from '../../utils/destination.utils';
import { ROUTES } from '../../api/routes';
import noteIcon from '../../assets/icons/note.svg';
import UnitsListingPagination from '../../components/unit-listing-pagination/units-listing-pagination';

export function SavedUnits() {
  const [savedUnits, setSavedUnits] = useState<UnitInterface[]>([]);
  const [selectedDestinations, setSelectedDestinations] = useState<
    OptionValue[]
  >([]);
  const [availableDestinations, setAvailableDestinations] = useState<
    OptionValue[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [paginationData, setPaginationData] = useState<PaginationInterface>();
  const [propertyRemoved, setPropertyRemoved] = useState(false);

  const { t } = useTranslation();
  const unitsCurrentPage = paginationData?.current_page
    ? paginationData.current_page + 1
    : 1;
  const unitsPage = (page?: number) => {
    return page ?? unitsCurrentPage;
  };
  const loadUnitsHandler = (loadMore: boolean, page?: number) => {
    setLoadingMore(true);
    if (!loadMore) {
      setLoading(true);
    }
    UnitUtils.getSavedUnits(unitsPage(page), 12, selectedDestinations)
      .then((res) => {
        if (loadMore) {
          setSavedUnits((prevState) => {
            return [...prevState, ...res.data];
          });
        } else {
          setSavedUnits(res.data);
        }
        setPaginationData(res.meta);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoadingMore(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    DestinationUtils.listSalesManDestinations().then((res) => {
      const destinationOptions = res.map((d) => {
        return {
          label: String(d.name),
          value: d.slug ?? '',
        };
      });
      setAvailableDestinations(destinationOptions);
    });
  }, []);

  useEffect(() => {
    loadUnitsHandler(false, 1);
  }, [selectedDestinations, propertyRemoved]);

  const handleRemoveFromSavedUnits = (id: number) => {
    UnitUtils.deleteSavedUnit(id)
      .then(() => {
        setPropertyRemoved(!propertyRemoved);
        // toast.success(t('unitRemovedFromWishlist') as string);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className={styles['wrapper']}>
      <h1 className={styles['title']}>Saved Units</h1>
      <p className={styles['note']}>
        <img src={noteIcon} alt="" role="presentation" />
        {t('savedUnitsNote')}
      </p>
      <MultiSelectDropdown
        options={availableDestinations}
        placeholder={t('destination')}
        onChange={(value) => {
          setSelectedDestinations(value);
        }}
        className={styles['select']}
      />
      {loading && <Loader />}
      {Boolean(savedUnits?.length) && !loading && (
        <>
          <div className={styles['total-count']}>
            {paginationData?.total} {t('availableSavedUnits')}
          </div>

          <UnitsListing
            id="units-listing"
            units={savedUnits}
            handleRemove={handleRemoveFromSavedUnits}
            unitDetailsPath={ROUTES['UnitDetails'].getPath}
            addSavedUnit={UnitUtils.addSavedUnit}
            deleteSavedUnit={UnitUtils.deleteSavedUnit}
            portal="broker"
          />
        </>
      )}
      {!savedUnits?.length && !loading && <h1>{t('noUnitsToDisplay')}</h1>}

      <UnitsListingPagination
        units={savedUnits}
        loading={loading}
        paginationData={paginationData}
        loadUnitsHandler={loadUnitsHandler}
        loadingMore={loadingMore}
      />

      <CompareButton comparePropertiesPath={ROUTES['CompareProperties'].path} />
    </div>
  );
}

export default SavedUnits;
