import { useContext, useEffect, useState } from 'react';
import {
  UnitCompareInterface,
  UnitPaymentTerms,
} from '@orascom/api-interfaces';
import styles from './compare-properties.module.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LeftArrowIcon from '../../assets/icons/left-arrow.svg?react';
import AddToCompareIcon from '../../assets/icons/add-to-compare.svg?react';
import { Loader } from '@orascom/common-components';
import {
  BROKER_COMPARE_UNITS_LIMIT,
  useCompareUnitsContext,
  USER_CONTEXT,
} from '@orascom/broker-sales-man-common-components';
import { CommonEventParameters, CurrencyContext } from '@orascom/utils';
import ReportIcon from '../../assets/icons/download-report.svg';
import CompareSingleUnit from './compare-single-unit';

export interface ComparePropertiesPageProps {
  getCompareUnits(unitsIds: number[]): Promise<UnitCompareInterface[]>;
  getUnitPaymentTerms(unitId: number): Promise<UnitPaymentTerms[]>;
  primaryUnitsRoute: string;
  unitDetailsGetPath?: Function;
  unitDealGetPath?: Function;
  analyticsNewtDealCustomEvent?(params: CommonEventParameters): void;
  getExportCompareUnits?: (
    unitsIds: number[],
    selectedCurrency: string
  ) => Promise<Blob>;
  downloadOmanSalesOffer?(unitId: number, unitName: string): Promise<any>;
  salesOffer?: boolean;
}

export const ComparePropertiesPage = (
  props: Readonly<ComparePropertiesPageProps>
) => {
  const [compareUnits, setCompareUnits] = useState<UnitCompareInterface[]>([]);
  const [isLoadingUnits, setIsLoadingUnits] = useState(true);
  const { compareUnitsIds, clearCompareUnits, removeUnitFromCompare } =
    useCompareUnitsContext();
  const userContext = useContext(USER_CONTEXT);
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { currency: selectedCurrency } = useContext(CurrencyContext);

  const getColumnPosition = (index: number, length: number) => {
    const columnPosition = {
      START: 'start',
      MIDDLE: 'middle',
      END: 'end',
      SINGLE: 'single',
    };

    if (length === 3) {
      return index === 0
        ? columnPosition.START
        : index === 1
        ? columnPosition.MIDDLE
        : columnPosition.END;
    } else if (length === 2) {
      return index === 0 ? columnPosition.START : columnPosition.END;
    } else return columnPosition.SINGLE;
  };

  useEffect(() => {
    // we are updating the state on the client side only for better UX
    if (compareUnits?.length || !compareUnitsIds) return;

    if (compareUnitsIds?.length) {
      setIsLoadingUnits(true);
      props
        .getCompareUnits(compareUnitsIds)
        .then((res) => setCompareUnits(res))
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setIsLoadingUnits(false);
        });
    } else {
      setIsLoadingUnits(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareUnitsIds]);

  function onClearCompareUnits() {
    clearCompareUnits();
    setCompareUnits([]);
  }

  if (isLoadingUnits) {
    return <Loader />;
  }

  const shouldShowEmptyState = compareUnitsIds?.length === 0;

  if (shouldShowEmptyState) {
    return (
      <div className={`container ${styles['compare-units__empty-state']}`}>
        <p>{t('noPropertiesToCompare')}</p>
        <Link className={`btn btn--navy`} to={props.primaryUnitsRoute}>
          {t('browseUnits')}
        </Link>
      </div>
    );
  }

  const shouldShowAddBtn = compareUnits.length < BROKER_COMPARE_UNITS_LIMIT;

  const shouldShowClearBtn = compareUnits.length > 0;

  const handleExportCompare = () => {
    if (compareUnitsIds) {
      props
        .getExportCompareUnits?.(compareUnitsIds, selectedCurrency)
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'compare-units.xlsx';
          document.body.appendChild(a);
          a.click();
          a.remove();
        })
        .catch((error) => {
          console.error('There was an error downloading the file:', error);
        });
    }
  };

  return (
    <div className={styles['compare-units']}>
      <button
        type="button"
        className={styles['compare-units__back-btn']}
        onClick={() => navigate(props.primaryUnitsRoute)}
      >
        <LeftArrowIcon fontSize="28px" />
        {t('back')}
      </button>
      <div className={styles['compare-units__header']}>
        <div className={styles['compare-units__header__title']}>
          {' '}
          <h1>
            {t('compareProperties', {
              count: compareUnits.length,
            })}
          </h1>
          {shouldShowClearBtn && (
            <button
              type="button"
              onClick={onClearCompareUnits}
              className={styles['compare-units__header__title-clear']}
            >
              {t('clearAll')}
            </button>
          )}
        </div>

        {props.getExportCompareUnits && (
          <button
            onClick={handleExportCompare}
            className={styles['compare-units__header__export']}
          >
            <img src={ReportIcon} alt="" role="presentation" />
            {t('downloadCompareUnits')}
          </button>
        )}
      </div>
      <div className={`card ${styles['comparison-table']}`}>
        <div className={styles['row-units']}>
          {compareUnits.map((unit, index) => (
            <CompareSingleUnit
              key={unit.id}
              getUnitPaymentTerms={props.getUnitPaymentTerms}
              setCompareUnits={setCompareUnits}
              unit={unit}
              index={index}
              unitDealGetPath={props.unitDealGetPath}
              unitDetailsGetPath={props.unitDetailsGetPath}
              columnPosition={getColumnPosition(index, compareUnits.length)}
            />
          ))}

          {shouldShowAddBtn && (
            <div className={styles['add-button-column']}>
              <button
                type="button"
                className={styles['add-button']}
                onClick={() => navigate(props.primaryUnitsRoute)}
              >
                <AddToCompareIcon />
              </button>
              <span>{t('addProperty')}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ComparePropertiesPage;
