import { UnitDetails as UnitDetailsUi } from '@orascom/broker-sales-man-common-components';
import { Unit as UnitUtils } from '../../utils/unit';
import { ROUTES } from '../../api/routes';

export function UnitDetails() {
  return (
    <UnitDetailsUi
      getUnitDetails={UnitUtils.getSalesManUnitDetails}
      getUnitPaymentTerms={UnitUtils.getSalesManUnitPaymentTerms}
      downloadOmanSalesOffer={UnitUtils.downloadSalesManOmanSalesOffer}
      primaryUnitsPath={ROUTES['PrimaryUnits'].path}
      portal="Sales Man"
      unitDetailsGetPath={ROUTES['UnitDetails'].getPath}
      addSavedUnit={UnitUtils.addSavedUnit}
      deleteSavedUnit={UnitUtils.deleteSavedUnit}
      comparePropertiesPath={ROUTES['CompareProperties'].path}
      unitDealGetPath={ROUTES['UnitDeal'].getPath}
    />
  );
}

export default UnitDetails;
