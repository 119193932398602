import { QualifyCustomer as QualifyCustomerAPI } from '../api/qualify-customer';
import {
  QualifyCustomerResponse,
  QualifyCustomer as QualifyCustomerInterface,
} from '../definitions/interfaces/qualify-customer';
import { OptionValue } from '@orascom/api-interfaces';
import { mapToOption } from './options-maping';

export class QualifyCustomer {
  public static getCustomerNationalities(
    country: string
  ): Promise<OptionValue[]> {
    return QualifyCustomerAPI.getCustomerNationalities(country)
      .then((result) => {
        const mappedResult: OptionValue[] = mapToOption(result.data, {
          label: 'nationality_name',
          value: 'nationality_id',
        });
        return Promise.resolve(mappedResult);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  public static getCustomerCities(country: string): Promise<OptionValue[]> {
    return QualifyCustomerAPI.getCustomerCities(country)
      .then((result) => {
        const mappedResult: OptionValue[] = mapToOption(result.data, {
          label: 'city_name',
          value: 'city_id',
        });
        return Promise.resolve(mappedResult);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  public static getCustomerCountries(country: string): Promise<OptionValue[]> {
    return QualifyCustomerAPI.getCustomerCountries(country)
      .then((result) => {
        const mappedResult: OptionValue[] = mapToOption(result.data, {
          label: 'country_name',
          value: 'country_id',
        });
        return Promise.resolve(mappedResult);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  public static getCustomerOccupations(
    country: string
  ): Promise<OptionValue[]> {
    return QualifyCustomerAPI.getCustomerOccupations(country)
      .then((result) => {
        const mappedResult: OptionValue[] = mapToOption(result.data, {
          label: 'label',
          value: 'code',
        });
        return Promise.resolve(mappedResult);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  public static qualifyCustomer(
    formData: QualifyCustomerInterface,
    country: string
  ): Promise<QualifyCustomerResponse> {
    return QualifyCustomerAPI.qualifyCustomer(formData, country)
      .then((result) => {
        return Promise.resolve(result.data?.data);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static attachementCustomer(
    customerId: string,
    destination: string,
    file: File,
    country: string
  ): Promise<void> {
    return QualifyCustomerAPI.attachementCustomer(
      customerId,
      destination,
      file,
      country
    )
      .then((result) => {
        return Promise.resolve(result.data);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }
}
