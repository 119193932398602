import {
  SidebarComponent,
  SidebarMenuItem,
} from '@orascom/broker-sales-man-common-components';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../api/routes';
import { MenuItem, SubMenu } from 'react-pro-sidebar';
import { Destination, Route } from '@orascom/api-interfaces';
import DealsIcon from '../../assets/icons/my-deals.svg?react';
import DealsActiveIcon from '../../assets/icons/my-deals-active.svg?react';
import DestinationsIcon from '../../assets/icons/destinations.svg?react';
import SalesIcon from '../../assets/icons/my-sales.svg?react';
import SalesActiveIcon from '../../assets/icons/my-sales-active.svg?react';
import CustomerIcon from '../../assets/icons/my-customers.svg?react';
import CustomerActiveIcon from '../../assets/icons/my-customers-active.svg?react';
import DestinationsActiveIcon from '../../assets/icons/destinations-active.svg?react';
import { Destination as DestinationUtils } from '../../utils/destination.utils';
import PrimaryIcon from '../../assets/icons/primary-units.svg?react';
import PrimaryActiveIcon from '../../assets/icons/primary-units-active.svg?react';
import { CommonEventParameters } from '@orascom/utils';
import {
  analyticsSalesManDestinationCustomEvent,
  analyticsSalesManDestinationsCustomEvent,
  analyticsSalesManPrimaryUnitsCustomEvent,
} from '../../utils/analytics-events';
import { removeTokenDetails } from '../../utils/authentication';
import SavedIcon from '../../assets/icons/saved-units.svg?react';
import SavedActiveIcon from '../../assets/icons/saved-units-active.svg?react';
import {
  BrokerWalkthroughIds,
  SalesManWalkthroughIds,
  SelectDropdown,
  WalkthroughComponent,
} from '@orascom/common-components';
import { USER_CONTEXT } from '../../context/user-context';
import { mapToOption } from '../../utils/options-maping';
import { COUNTRY_SELECTED_CONTEXT } from '../../context/country-selected-context';
import { CallBackProps, LIFECYCLE, Step } from 'react-joyride';
import { User } from '../../api/user';

export function Sidebar() {
  const { t } = useTranslation();
  const [loggingOut, setLoggingOut] = useState(false);
  const location = useLocation();
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [availableDestinations, setAvailableDestinations] = useState<
    Destination[]
  >([]);
  const navigate = useNavigate();
  const userContext = useContext(USER_CONTEXT);
  const activeCountry = useContext(COUNTRY_SELECTED_CONTEXT);

  //    walkthrough logic starts here
  const [runWalkthrough, setRunWalkthrough] = useState(false);

  const joyrideSteps: Step[] = [
    {
      data: {
        id: SalesManWalkthroughIds.STEP_1,
        route: '/',
      },
      target: `body`,
      title: `Welcome to Orascom Sales Portal`,
      content: `Welcome to Orascom Developments Sales Portal! This walkthrough will guide you through the platform's features and help you get started seamlessly.`,
      disableBeacon: true,
      placement: `center`,
    },
    {
      data: {
        id: SalesManWalkthroughIds.STEP_1,
        route: '/',
      },
      target: `#${SalesManWalkthroughIds.STEP_1}`,
      title: `Your Overview at a Glance`,
      content: `This is your home base. Stay updated on the latest news, access new units, track analytics, and manage everything in one place.`,
      disableBeacon: true,
      placement: `right`,
    },
    {
      data: {
        id: SalesManWalkthroughIds.STEP_2,
        route: '/',
      },
      target: `#${SalesManWalkthroughIds.STEP_2}`,
      title: `Gain Insights with Analytics`,
      content: `Stay on top of your performance with real-time data. Track key metrics to make informed decisions and optimize your strategy.`,
      disableBeacon: true,
      placement: `top`,
    },
    {
      data: {
        id: SalesManWalkthroughIds.STEP_3,
        route: '/destination/o-west',
      },
      target: `#${SalesManWalkthroughIds.STEP_3}`,
      title: `Explore Destinations`,
      content: `Use this page as a mini brochure to showcase key details to your clients. View masterplans, explore nearby landmarks, and highlight what makes each destination unique.`,
      disableBeacon: true,
      placement: `right`,
    },
    {
      data: {
        id: SalesManWalkthroughIds.STEP_4,
        route: ROUTES['PrimaryUnits'].path,
      },
      target: `#${SalesManWalkthroughIds.STEP_4}`,
      title: `Browse Units Stock`,
      content: `Easily browse available units, including primary listings, resale options, and units you've saved for later.`,
      disableBeacon: true,
      placement: `right`,
    },
    {
      data: {
        id: SalesManWalkthroughIds.STEP_5,
        route: '/unit-details/walkthrough',
      },
      target: `body`,
      title: `Explore Property Details`,
      content: `Get a complete view of the unit. Check key information, including size, price, availability, and see its exact location on the masterplan to help your client make informed decisions.`,
      disableBeacon: true,
      placement: `center`,
    },
    {
      data: {
        id: SalesManWalkthroughIds.STEP_6,
        route: '/unit-details/walkthrough',
      },
      target: `#${BrokerWalkthroughIds.STEP_4}`, // We are using this id because its the assigned id in the unit details
      title: `Floorplan & Payment Options`,
      content: `Visualize the unit with detailed floor plans. Review flexible payment plan options to present the best fit for your client’s needs.`,
      disableBeacon: true,
      placement: `top`,
    },
    {
      data: {
        id: SalesManWalkthroughIds.STEP_7,
        route: ROUTES['MyDeals'].path,
      },
      target: `#${SalesManWalkthroughIds.STEP_7}`,
      title: `Manage Your Leads`,
      content: `Track and manage all your leads in one place. Monitor their status, follow up at the right time, and take the next steps to move them through the sales process efficiently.`,
      disableBeacon: true,
      placement: `right`,
    },
    {
      data: {
        id: SalesManWalkthroughIds.STEP_8,
        route: '/leads/walkthrough/pipelines/1',
      },
      target: `body`,
      title: `Navigate to Lead Information`,
      content: `Open the lead’s inner page to view detailed information. Here, you can review client details, previous interactions, and any notes to help you prepare for the next steps.`,
      disableBeacon: true,
      placement: `center`,
    },
    {
      data: {
        id: SalesManWalkthroughIds.STEP_9,
        route: '/leads/walkthrough/pipelines/1',
      },
      target: `#${SalesManWalkthroughIds.STEP_9}`,
      title: `Stay on Top of Every Lead’s Progress`,
      content: `Follow your leads through each stage, from submission to conversion. Understand their status, take timely actions, and ensure a smooth follow-up process to close your deals.`,
      disableBeacon: true,
      placement: `bottom`,
    },
    {
      data: {
        id: SalesManWalkthroughIds.STEP_10,
        route: ROUTES['NewDeal'].path,
      },
      target: `body`,
      title: `Start a New Lead`,
      content: `Navigate to the lead creation form to begin capturing client information. This is where you’ll initiate the process of adding a new potential buyer.`,
      disableBeacon: true,
      placement: `center`,
    },
    {
      data: {
        id: SalesManWalkthroughIds.STEP_11,
        route: ROUTES['NewDeal'].path,
      },
      target: `#${SalesManWalkthroughIds.STEP_11}`,
      title: `Complete Lead Information`,
      content: `Enter all required details to ensure a smooth follow-up. Make sure to capture essential information such as client name, contact details, and specific preferences to help personalize your approach.`,
      disableBeacon: true,
      placement: `right`,
    },
  ];

  const handleJoyrideCallBackStep = (data: CallBackProps) => {
    if (data.action === 'skip' || data.action === 'close') {
      localStorage.removeItem('walkthrough_status');
      setRunWalkthrough(false);
      User.updateWalkthroughStatus();
      navigate('/', { replace: true });
      return;
    }

    if (
      (data.step.data.id === SalesManWalkthroughIds.STEP_5 ||
        data.step.data.id === SalesManWalkthroughIds.STEP_8 ||
        data.step.data.id === SalesManWalkthroughIds.STEP_10) &&
      LIFECYCLE.INIT
    ) {
      navigate(data.step.data.route, { replace: true });
    }

    if ((data.action === 'next' || data.action === 'prev') && LIFECYCLE.READY) {
      if (
        data.step.data.id === SalesManWalkthroughIds.STEP_1 &&
        data.action === 'next'
      ) {
        document
          .getElementById(`${SalesManWalkthroughIds.STEP_3}-btn`)
          ?.click();
        navigate(data.step.data.route, { replace: true });
      } else {
        navigate(data.step.data.route, { replace: true });
      }
    }
    if (data.status === 'finished') {
      localStorage.removeItem('walkthrough_status');
      User.updateWalkthroughStatus();
      navigate('/', { replace: true });
    }
  };

  useEffect(() => {
    if (userContext.user?.is_walkthrough_done) return;
    if (window.innerWidth < 768) {
      setRunWalkthrough(false);
    }
    localStorage.setItem('walkthrough_status', 'running');
    setRunWalkthrough(true);
  }, []);

  // walkthrough logic ends here

  const logoutHandler = () => {
    setLoggingOut(true);
    removeTokenDetails();
    userContext?.setUser?.(null);
    navigate(ROUTES['Login'].path);
  };

  const isActive = (routeName: Route, id?: string | number) =>
    location.pathname === routeName.path ||
    location.pathname === routeName.getPath?.(id);

  useEffect(() => {
    DestinationUtils.listSalesManDestinations().then((res) => {
      setAvailableDestinations(res);
    });
  }, []);

  const analyticsSalesManEventParams: CommonEventParameters = {
    userId: userContext.user?.id.toString(),
    timestamp: Date.now().toString(),
    portal: 'Sales Man',
    pageName: location.pathname,
  };
  return (
    <SidebarComponent
      subtitle={t('internalSalesPortal')}
      loggingOut={loggingOut}
      logoutHandler={logoutHandler}
      countrySelection={
        userContext.user?.access_countries && (
          <SelectDropdown
            disabled={activeCountry.disableCountrySelection}
            options={mapToOption(userContext.user?.access_countries, {
              label: 'name',
              value: 'id',
            })}
            placeholder={activeCountry.activeCountry?.label.toString() ?? ''}
            defaultValue={activeCountry.activeCountry}
            onChange={(val) => {
              if (val) {
                activeCountry.setActiveCountry?.(val);
              }
            }}
            selectedOption={activeCountry.activeCountry}
          />
        )
      }
    >
      <WalkthroughComponent
        steps={joyrideSteps}
        startWalkthrough={runWalkthrough}
        callBackFunction={handleJoyrideCallBackStep}
        disableScrolling={false}
        showProgress
        lastBtnMsg="finish (12/12)"
      />

      <SidebarMenuItem
        id={SalesManWalkthroughIds.STEP_1}
        route={ROUTES['Overview']}
        icon={<DealsIcon />}
        activeIcon={<DealsActiveIcon />}
        isActive={isActive(ROUTES['Overview'])}
        label={t('overview')}
      />
      <SidebarMenuItem
        id={SalesManWalkthroughIds.STEP_7}
        route={ROUTES['MyDeals']}
        icon={<DealsIcon />}
        activeIcon={<DealsActiveIcon />}
        isActive={
          isActive(ROUTES['MyDeals']) || location.pathname.includes('leads')
        }
        label={t('myLeads')}
      />
      <SidebarMenuItem
        route={ROUTES['MyCustomers']}
        icon={<CustomerIcon />}
        activeIcon={<CustomerActiveIcon />}
        isActive={
          isActive(ROUTES['MyCustomers']) ||
          location.pathname.includes('customers')
        }
        label={t('myCustomers')}
      />
      <div id={SalesManWalkthroughIds.STEP_3}>
        <SubMenu
          id={`${SalesManWalkthroughIds.STEP_3}-btn`}
          label={t('destinations')}
          icon={
            location.pathname.includes('destination') ? (
              <DestinationsActiveIcon />
            ) : (
              <DestinationsIcon />
            )
          }
          active={location.pathname.includes('destination')}
          open={subMenuOpen}
          onOpenChange={() => setSubMenuOpen(!subMenuOpen)}
          onClick={() =>
            analyticsSalesManDestinationsCustomEvent(
              analyticsSalesManEventParams
            )
          }
        >
          {availableDestinations.map((destination) => (
            <MenuItem
              key={destination.id}
              component={
                <Link to={ROUTES['Destination'].getPath?.(destination.slug)} />
              }
              active={isActive(ROUTES['Destination'], destination.slug)}
              onClick={() =>
                analyticsSalesManDestinationCustomEvent({
                  name: destination.slug,
                  userId: userContext.user?.id.toString(),
                  timestamp: Date.now().toString(),
                  portal: 'Sales Man',
                  pageName: location.pathname,
                })
              }
            >
              {destination.name}
            </MenuItem>
          ))}
        </SubMenu>
      </div>

      <SidebarMenuItem
        id={SalesManWalkthroughIds.STEP_4}
        route={ROUTES['PrimaryUnits']}
        icon={<PrimaryIcon />}
        activeIcon={<PrimaryActiveIcon />}
        isActive={isActive(ROUTES['PrimaryUnits'])}
        label={t('primaryUnits')}
        onClick={() =>
          analyticsSalesManPrimaryUnitsCustomEvent(analyticsSalesManEventParams)
        }
      />
      <SidebarMenuItem
        route={ROUTES['MySales']}
        icon={<SalesIcon />}
        activeIcon={<SalesActiveIcon />}
        isActive={isActive(ROUTES['MySales'])}
        label={t('mySales')}
      />
      <SidebarMenuItem
        route={ROUTES['SavedUnits']}
        icon={<SavedIcon />}
        activeIcon={<SavedActiveIcon />}
        isActive={isActive(ROUTES['SavedUnits'])}
        label={t('savedUnits')}
      />
    </SidebarComponent>
  );
}
export default Sidebar;
