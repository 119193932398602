import { useState } from 'react';
import { UnitMasterPlan } from '@orascom/api-interfaces';
import styles from './unit-plan.module.scss';
import { useTranslation } from 'react-i18next';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import { downloadImage } from '@orascom/utils';

export interface UnitPlanProps {
  title: string;
  plans: UnitMasterPlan[];
  id?: string;
}

export function UnitPlan(props: Readonly<UnitPlanProps>) {
  const [planIndex, setPlanIndex] = useState(0);

  const { t } = useTranslation();

  const { title, plans } = props;

  return (
    <div id={props.id} className={`${styles['wrapper']} card`}>
      <h3>{title}</h3>
      {plans.length > 1 && (
        <div className={styles['tabs']}>
          {plans.map((plan, index) => (
            <button
              key={plan.id}
              onClick={() => setPlanIndex(index)}
              className={`${styles['tab']} ${
                planIndex === index && styles['tab--active']
              }`}
            >
              {plan.title}
            </button>
          ))}
        </div>
      )}
      <PhotoProvider maskOpacity={0.8}>
        <div className={styles['image']}>
          <PhotoView src={plans[planIndex].image}>
            <img
              src={plans[planIndex].image}
              alt={plans[planIndex].title}
              loading="lazy"
            />
          </PhotoView>
        </div>
      </PhotoProvider>
      <div className={styles['download']}>
        <button
          className="anchor"
          onClick={() =>
            downloadImage(
              plans[planIndex].image,
              ` ${plans[planIndex].title} floorplan`
            )
          }
        >
          {t('downloadFloorPlanImage')}
        </button>
      </div>
    </div>
  );
}

export default UnitPlan;
