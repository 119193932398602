import { OptionValue } from '@orascom/api-interfaces';
import { SelectDropdown } from '@orascom/common-components';
import { ErrorMessage, useFormikContext } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './visit-component.module.scss';
import { Value } from '../initial-meeting/initial-meeting';
import { ReusableCalendar } from '../reusable-calendar/reusable-calendar';
import {
  SecondStageInitialValues,
  VisitOptions,
} from '../../definitions/interfaces/montenegro-lead-stages.interface';

function VisitComponent() {
  const { t } = useTranslation();
  const formContext = useFormikContext<SecondStageInitialValues>();

  const [selectedVisitDate, setSelectedVisitDate] = useState<Value>(null);
  const [selectedVisitType, setSelectedVisitType] =
    useState<OptionValue | null>(null);

  return (
    <div className={styles['visit-wrapper']}>
      <div className={styles['header']}>
        <p className={styles['title']}>{t('visit')}</p>
      </div>

      {/* TODO uncomment when needed */}
      {/* <div className={styles['checkbox-wrapper']}>
        <input
          id="visitCheckbox"
          name="visitCheckbox"
          type="checkbox"
          onBlur={formContext.handleBlur}
          onChange={(value) =>
            formContext.setFieldValue('visitCheckbox', value.target.checked)
          }
        />
        <label htmlFor="visitCheckbox">{t('visitDone')}</label>
      </div> */}

      <ReusableCalendar
        label={t('visitDate')}
        name="visitDate"
        onDateChange={(date) => {
          setSelectedVisitDate(date as Value);
          formContext.setFieldValue('visitDate', date);
        }}
        selectedDate={selectedVisitDate}
        view="month"
      />

      <div className={` ${styles['visit-types-select']}`}>
        <label>{t('visitType')}</label>
        <SelectDropdown
          name="visitType"
          className={`${styles['select']}`}
          invalid={
            formContext.touched.visitType && !!formContext.errors.visitType
          }
          options={VisitOptions}
          placeholder={t('selectPlaceholder')}
          onChange={(val) => {
            formContext.setFieldValue('visitType', val?.value);
            setSelectedVisitType(val);
          }}
          selectedOption={selectedVisitType || null}
          isClearable
        />

        <ErrorMessage
          name="visitType"
          component="div"
          className="form__error"
        />
      </div>
    </div>
  );
}

export default VisitComponent;
