import { useRef } from 'react';
import { format } from 'date-fns';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import styles from './reusable-calendar.module.scss';
import ChevronLeft from '../../assets/icons/left-arrow.svg';
import LeftArrowIcon from '../../assets/icons/calendar-left-arrow.svg';
import RightArrowIcon from '../../assets/icons/calendar-right-arrow.svg';
import { ErrorMessage } from 'formik';

interface Props {
  label: string;
  name: string;
  selectedDate: Date | null;
  onDateChange: (date: Date) => void;
  placeholder?: string;
  view: 'century' | 'decade' | 'month' | 'year';
  btnNotInForm?: boolean;
}

export function ReusableCalendar({
  label,
  name,
  selectedDate,
  onDateChange,
  placeholder = 'Select Date',
  view,
  btnNotInForm,
}: Props) {
  const dialogRef = useRef<HTMLDialogElement | null>(null);

  const toggleDialog = () => {
    if (dialogRef.current) {
      if (dialogRef.current.open) {
        dialogRef.current.close();
      } else {
        dialogRef.current.show();
      }
    }
  };

  return (
    <div className={styles['meeting-date']}>
      <label htmlFor={name}>{label}</label>
      <div className={styles['modal-wrapper']}>
        <button
          type="button"
          onClick={toggleDialog}
          className={
            btnNotInForm ? styles['btn-outline'] : styles['btn-outline-form']
          }
        >
          <p>
            {selectedDate ? format(selectedDate, 'dd MMMM yyyy') : placeholder}
          </p>
          <img
            style={{ height: 'auto', rotate: '-90deg' }}
            src={ChevronLeft}
            alt=""
            role="presentation"
          />
        </button>
        <dialog ref={dialogRef} className={styles['modal']}>
          <Calendar
            value={selectedDate}
            onChange={(date) => {
              onDateChange(date as Date);
              toggleDialog();
            }}
            view={view}
            prevLabel={
              <img
                style={{ height: 'auto' }}
                src={LeftArrowIcon}
                alt=""
                role="presentation"
              />
            }
            nextLabel={
              <img
                style={{ height: 'auto' }}
                src={RightArrowIcon}
                alt=""
                role="presentation"
              />
            }
          />
        </dialog>
      </div>
      <ErrorMessage name={name} component="div" className="form__error" />
    </div>
  );
}
