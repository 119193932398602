import { UnitPaymentTerms } from '@orascom/api-interfaces';
import { useCurrencyConverter } from '@orascom/utils';

const GetConvertedCurrency = (paymentTerms: UnitPaymentTerms | null) => {
  const { convertToSelectedCurrency } = useCurrencyConverter();

  if (paymentTerms) {
    const unitPrice = convertToSelectedCurrency(
      paymentTerms?.unit_price ?? 0,
      paymentTerms?.currency
    ).price;
    const unitPriceAfterDiscount = convertToSelectedCurrency(
      paymentTerms?.price_after_discount ?? 0,
      paymentTerms?.currency
    ).price;
    const unitDiscountAmount = convertToSelectedCurrency(
      paymentTerms?.discount_amount ?? 0,
      paymentTerms?.currency
    ).price;
    const unitBeforeDeliveryInstallmentAmount = convertToSelectedCurrency(
      paymentTerms?.before_delivery_installment_amount ?? 0,
      paymentTerms?.currency
    ).price;
    const unitAfterDeliveryInstallmentAmount = convertToSelectedCurrency(
      paymentTerms?.after_delivery_installment_amount ?? 0,
      paymentTerms?.currency
    ).price;
    const unitClubhouseFees = convertToSelectedCurrency(
      paymentTerms?.clubhouse_fees ?? 0,
      paymentTerms?.currency
    ).price;
    const unitClubhouseInstallments = convertToSelectedCurrency(
      paymentTerms?.clubhouse_fees_installment_amount ?? 0,
      paymentTerms?.currency
    ).price;
    const unitMaintenanceFees = convertToSelectedCurrency(
      paymentTerms?.maintenance_fees ?? 0,
      paymentTerms?.currency
    ).price;
    const unitDownpaymentAmount = convertToSelectedCurrency(
      paymentTerms?.downpayment_amount ?? 0,
      paymentTerms?.currency
    ).price;
    const unitReservationFeesAmount = convertToSelectedCurrency(
      paymentTerms?.prereservation_amount ?? 0,
      paymentTerms?.currency
    ).price;
    const unitTotalUnitPrice = convertToSelectedCurrency(
      paymentTerms?.total_unit_price ?? 0,
      paymentTerms?.currency
    ).price;

    return {
      unitPrice,
      unitPriceAfterDiscount,
      unitDiscountAmount,
      unitBeforeDeliveryInstallmentAmount,
      unitAfterDeliveryInstallmentAmount,
      unitClubhouseFees,
      unitClubhouseInstallments,
      unitMaintenanceFees,
      unitDownpaymentAmount,
      unitReservationFeesAmount,
      unitTotalUnitPrice,
    };
  } else {
    return {};
  }
};

export default GetConvertedCurrency;
