import {
  ContactTimeEnum,
  ContactTypeEnum,
  MultiplePhoneNumbersFields,
} from '@orascom/broker-sales-man-common-components';
import {
  SalesManWalkthroughIds,
  SelectDropdown,
} from '@orascom/common-components';
import { ErrorMessage, Field, Form, Formik } from 'formik';
// eslint-disable-next-line @nx/enforce-module-boundaries
import styles from 'libs/broker-sales-man-common-components/src/styles/new-deal.module.scss';
import { useTranslation } from 'react-i18next';
import DropdownIcon from '../../assets/icons/dropdown.svg';
import { ROUTES } from '../../api/routes';
import { Link, useNavigate } from 'react-router-dom';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Deal as DealUtils,
  feedbackOptionsMontenegro,
} from '../../utils/deal.utils';
import {
  NewDealMontenegroFormInterface,
  NewDealMontenegroInputInterface,
} from '../../definitions/interfaces/deals.interface';
import { OptionValue, UnitDetails } from '@orascom/api-interfaces';
import { toast } from 'react-toastify';
import { COUNTRY_SELECTED_CONTEXT } from '../../context/country-selected-context';
import { Destination as DestinationUtils } from '../../utils/destination.utils';
import { montenegroSchema } from '../../utils/new-deal.utils';

export interface NewDealMontenegroProps {
  setIsSubmitting: Dispatch<SetStateAction<boolean>>;
  unit?: UnitDetails;
  unitId?: string;
}
export function NewDealMontenegro({
  setIsSubmitting,
  unitId,
  unit,
}: NewDealMontenegroProps) {
  const { t } = useTranslation();
  const activeCountry = useContext(COUNTRY_SELECTED_CONTEXT);

  const navigate = useNavigate();

  const [dropdownsLoading, setDropdownsLoading] = useState(true);
  const [subSourceLoading, setSubSourceLoading] = useState(false);

  const [redirectPage, setRedirectPage] = useState<'leads' | 'lead-details'>();
  const [submitBtns, setSubmitBtns] = useState(false);

  const [brokerList, setBrokerList] = useState<OptionValue[]>();
  const [sourceList, setSourceList] = useState<OptionValue[]>();
  const [subSourceList, setSubSourceList] = useState<OptionValue[]>();
  const [countryList, setCountryList] = useState<OptionValue[]>();
  const [destinations, setDestinations] = useState<OptionValue[]>([]);
  const [systemUsers, setSystemUsers] = useState<OptionValue[]>([]);

  const [selectedSource, setSelectedSource] = useState<OptionValue>();

  useEffect(() => {
    if (sourceList && selectedSource) {
      setSubSourceLoading(true);
      DealUtils.getLeadSubSources(
        selectedSource.value.toString(),
        activeCountry.activeCountry?.value.toString() ?? ''
      )
        .then((res) => setSubSourceList(res))
        .catch((err) => console.log(err))
        .finally(() => setSubSourceLoading(false));
    }
  }, [selectedSource, sourceList]);

  useEffect(() => {
    Promise.all([
      DealUtils.getBrokerList(
        activeCountry.activeCountry?.value.toString() ?? ''
      ),
      DealUtils.getLeadSources(
        activeCountry.activeCountry?.value.toString() ?? ''
      ),
      DealUtils.getLeadCountries(
        activeCountry.activeCountry?.value.toString() ?? ''
      ),
      DestinationUtils.listSalesManDestinations(
        activeCountry.activeCountry?.value.toString()
      ),
      DealUtils.listSystemUsers(
        activeCountry.activeCountry?.value.toString() ?? ''
      ),
    ])
      .then((res) => {
        setBrokerList(res[0]);
        setSourceList(res[1]);
        setCountryList(res[2]);
        setDestinations(
          res[3].map((destination) => ({
            label: destination.name,
            value: destination.slug,
          }))
        );
        setSystemUsers(res[4]);
      })
      .catch((err) => console.log(err))
      .finally(() => setDropdownsLoading(false));
  }, []);

  const submitHandler = (values: NewDealMontenegroInputInterface) => {
    const mappedData: NewDealMontenegroFormInterface = {
      first_name: values.firstName,
      last_name: values.lastName,
      phone:
        '+' + values.phoneNumbers[0].countryCode + values.phoneNumbers[0].phone,
      feedback_id: values.feedback?.value
        ? Number(values.feedback?.value)
        : undefined,
      preferred_communication_method: values.contactType?.value
        ? String(values.contactType?.value)
        : '',
      preferred_communication_time: values.contactTime?.value
        ? String(values.contactTime?.value)
        : '',
      portal_comments: values.additionalInfo ?? '',
      salesman_country_id: Number(activeCountry.activeCountry?.value),
      lead_source_source_id: values.source?.value.toString() ?? '',
      lead_subsource_source_id: values.subSource?.value.toString() ?? '',
      lead_country_source_id: values.country?.value.toString() ?? '',
      lead_type_id: Number(values.leadType?.value),
      broker_source_id: values.broker?.value.toString() ?? '',
      destination_slug: unitId
        ? unit?.project.destination.slug ?? ''
        : values.destination?.value.toString() ?? '',
      system_user_source_id: values.systemUser?.value.toString(),
    };

    if (values.phoneNumbers[1]?.countryCode && values.phoneNumbers[1]?.phone) {
      mappedData.phone_2 = `+${values.phoneNumbers[1].countryCode}${values.phoneNumbers[1].phone}`;
    }
    if (values.phoneNumbers[2]?.countryCode && values.phoneNumbers[2]?.phone) {
      mappedData.phone_3 = `+${values.phoneNumbers[2].countryCode}${values.phoneNumbers[2].phone}`;
    }
    if (unitId) {
      mappedData.interested_in_unit_id = unitId;
    }

    setIsSubmitting(true);

    DealUtils.createLead(
      mappedData,
      activeCountry.activeCountry?.value.toString() ?? ''
    )
      .then((res) => {
        setSubmitBtns(false);
        toast.success('Deal Created Successfully');
        if (redirectPage === 'leads') {
          navigate(ROUTES['NewDeal'].path);
        } else {
          navigate(
            ROUTES['LeadPipelines'].getPath?.(
              res.data.data.lead_source_id,
              activeCountry.activeCountry?.value
            )
          );
        }
      })
      .catch((err) => {
        toast.error('failure to add lead');
        toast.error(err.message);
        console.log(err);
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <div className={styles['form__wrapper']}>
      <h3>{t('leadInformation')}</h3>
      <Formik
        enableReinitialize
        initialValues={{
          firstName: '',
          lastName: '',
          phoneNumbers: [{ countryCode: '20', phone: '' }],
          source: null as OptionValue | null,
          subSource: null as OptionValue | null,
          broker: null as OptionValue | null,
          feedback: null as OptionValue | null,
          country: null as OptionValue | null,
          leadType: null as OptionValue | null,
          destination: destinations[0],
          contactType: null,
          contactTime: null,
          additionalInfo: '',
          systemUser: null,
        }}
        validationSchema={montenegroSchema}
        onSubmit={submitHandler}
      >
        {({ errors, setFieldValue, values, touched, isValid }) => {
          return (
            <Form
              id={SalesManWalkthroughIds.STEP_11}
              className={styles['form']}
            >
              <div className="form__input">
                <label htmlFor="firstName">{t('firstName')}</label>
                <Field
                  id="firstName"
                  name="firstName"
                  type="text"
                  className={
                    errors.firstName && touched.firstName ? 'error' : ''
                  }
                  placeholder={t('prospectiveBuyerFirstName')}
                />

                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="form__error"
                />
              </div>
              <div className="form__input">
                <label htmlFor="lastName">{t('lastName')}</label>
                <Field
                  id="lastName"
                  name="lastName"
                  type="text"
                  className={errors.lastName && touched.lastName ? 'error' : ''}
                  placeholder={t('prospectiveBuyerLastName')}
                />

                <ErrorMessage
                  name="lastName"
                  component="div"
                  className="form__error"
                />
              </div>

              <MultiplePhoneNumbersFields
                phoneNumbers={values.phoneNumbers}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
              />

              {!unitId && (
                <div className="form__input">
                  <label>{t('destination')}</label>
                  <SelectDropdown
                    name="destination"
                    className={`${styles['select']} ${
                      errors.destination && touched.destination ? 'error' : ''
                    }`}
                    options={destinations}
                    placeholder={t('selectPlaceholder')}
                    onChange={(val) => {
                      setFieldValue('destination', val);
                    }}
                    selectedOption={values.destination || destinations[0]}
                    isClearable
                    isLoading={dropdownsLoading}
                  />

                  <ErrorMessage
                    name="destination"
                    component="div"
                    className="form__error"
                  />
                </div>
              )}

              <div className="form__input">
                <label htmlFor="source">{t('source')}</label>
                <SelectDropdown
                  name="source"
                  className={`${styles['select']} ${
                    errors.source && touched.source ? 'error' : ''
                  }`}
                  placeholder={t('selectPlaceholder')}
                  onChange={(option) => {
                    setSelectedSource(option ?? undefined);
                    setFieldValue('source', option);
                  }}
                  options={sourceList}
                  selectedOption={values.source || null}
                  isClearable
                  isLoading={dropdownsLoading}
                />

                <ErrorMessage
                  name="source"
                  component="div"
                  className="form__error"
                />
              </div>

              {selectedSource?.label !== 'Broker' && (
                <div className="form__input">
                  <label htmlFor="subSource">{t('subSource')}</label>
                  <SelectDropdown
                    name="subSource"
                    className={`${styles['select']} ${
                      errors.subSource && touched.subSource ? 'error' : ''
                    }`}
                    placeholder={t('selectPlaceholder')}
                    onChange={(option) => setFieldValue('subSource', option)}
                    options={subSourceList}
                    selectedOption={values.subSource || null}
                    isClearable
                    isLoading={subSourceLoading}
                  />

                  <ErrorMessage
                    name="subSource"
                    component="div"
                    className="form__error"
                  />
                </div>
              )}

              {selectedSource?.label === 'Personal' && (
                <div className="form__input">
                  <label htmlFor="systemUser">{t('systemUser')}</label>
                  <SelectDropdown
                    name="systemUser"
                    className={`${styles['select']} ${
                      errors.systemUser && touched.systemUser ? 'error' : ''
                    }`}
                    placeholder={t('selectPlaceholder')}
                    onChange={(option) => setFieldValue('systemUser', option)}
                    options={systemUsers}
                    selectedOption={values.systemUser || null}
                    isClearable
                    isLoading={dropdownsLoading}
                  />

                  <ErrorMessage
                    name="systemUser"
                    component="div"
                    className="form__error"
                  />
                </div>
              )}

              {selectedSource?.label === 'Broker' && (
                <div className="form__input">
                  <label htmlFor="broker">{t('broker')}</label>
                  <SelectDropdown
                    name="broker"
                    className={`${styles['select']} ${
                      errors.broker && touched.broker ? 'error' : ''
                    }`}
                    placeholder={t('selectPlaceholder')}
                    onChange={(option) => setFieldValue('broker', option)}
                    options={brokerList}
                    selectedOption={values.broker || null}
                    isClearable
                    isLoading={dropdownsLoading}
                  />

                  <ErrorMessage
                    name="broker"
                    component="div"
                    className="form__error"
                  />
                </div>
              )}
              <div className="form__input">
                <label htmlFor="feedback">{t('feedback')}</label>
                <SelectDropdown
                  name="feedback"
                  className={`${styles['select']} ${
                    errors.feedback && touched.feedback ? 'error' : ''
                  }`}
                  placeholder={t('selectPlaceholder')}
                  onChange={(option) => setFieldValue('feedback', option)}
                  options={feedbackOptionsMontenegro}
                  selectedOption={values.feedback || null}
                  isClearable
                  isLoading={dropdownsLoading}
                />

                <ErrorMessage
                  name="feedback"
                  component="div"
                  className="form__error"
                />
              </div>

              <div className="form__input">
                <label htmlFor="country">{t('leadCountry')}</label>
                <SelectDropdown
                  name="country"
                  className={`${styles['select']} ${
                    errors.country && touched.country ? 'error' : ''
                  }`}
                  placeholder={t('selectPlaceholder')}
                  onChange={(option) => setFieldValue('country', option)}
                  options={countryList}
                  selectedOption={values.country || null}
                  isClearable
                  isLoading={dropdownsLoading}
                />

                <ErrorMessage
                  name="country"
                  component="div"
                  className="form__error"
                />
              </div>

              <div className="form__input">
                <label htmlFor="leadType">{t('leadType')}</label>
                <SelectDropdown
                  name="leadType"
                  className={`${styles['select']} ${
                    errors.leadType && touched.leadType ? 'error' : ''
                  }`}
                  placeholder={t('selectPlaceholder')}
                  onChange={(option) => setFieldValue('leadType', option)}
                  options={[
                    { label: 'Sale', value: 1 },
                    { label: 'Rental', value: 2 },
                  ]}
                  selectedOption={values.leadType || null}
                  isClearable
                />

                <ErrorMessage
                  name="leadType"
                  component="div"
                  className="form__error"
                />
              </div>

              <div className="form__input">
                <label htmlFor="contactType">
                  {t('preferredWayOfContact')} ({t('optional')})
                </label>
                <SelectDropdown
                  name="contactType"
                  className={`${styles['select']} ${
                    errors.contactType && touched.contactType ? 'error' : ''
                  }`}
                  placeholder={t('selectPlaceholder')}
                  onChange={(option) => setFieldValue('contactType', option)}
                  options={[
                    {
                      value: ContactTypeEnum.VIRTUAL_MEETING,
                      label: 'Virtual Meeting',
                    },
                    { value: ContactTypeEnum.MOBILE, label: 'Mobile' },
                    { value: ContactTypeEnum.EMAIL, label: 'Email' },
                    { value: ContactTypeEnum.WHATS_APP, label: 'WhatsApp' },
                  ]}
                  selectedOption={values.contactType || null}
                />

                <ErrorMessage
                  name="contactType"
                  component="div"
                  className="form__error"
                />
              </div>
              <div className="form__input">
                <label htmlFor="contactTime">
                  {t('preferredTimeOfContact')} ({t('optional')})
                </label>
                <SelectDropdown
                  name="contactTime"
                  className={`${styles['select']} ${
                    errors.contactTime && touched.contactTime ? 'error' : ''
                  }`}
                  placeholder={t('selectPlaceholder')}
                  onChange={(option) => setFieldValue('contactTime', option)}
                  options={[
                    { value: ContactTimeEnum.MORNING, label: 'Morning' },
                    {
                      value: ContactTimeEnum.AFTERNOON,
                      label: 'Afternoon',
                    },
                    { value: ContactTimeEnum.NIGHT, label: 'Night' },
                  ]}
                  selectedOption={values.contactTime || null}
                />

                <ErrorMessage
                  name="contactTime"
                  component="div"
                  className="form__error"
                />
              </div>

              <div className="form__input">
                <label htmlFor="additionalInfo">
                  {t('additionalInfo')} ({t('optional')})
                </label>
                <Field
                  id="additionalInfo"
                  name="additionalInfo"
                  as="textarea"
                  className={styles['textarea']}
                  placeholder={t('additionalNotes')}
                />

                <ErrorMessage
                  name="additionalInfo"
                  component="div"
                  className="form__error"
                />
              </div>

              <div className={styles['button-wrapper']}>
                <div className={styles['create-wrapper']}>
                  <button
                    className={`${styles['create-btn']} btn btn--navy`}
                    disabled={!isValid}
                    type="button"
                    onClick={() => {
                      if (isValid) {
                        setSubmitBtns(true);
                      }
                    }}
                  >
                    {t('createLead')}
                    <hr />
                    <img src={DropdownIcon} alt="" role="presentation" />
                  </button>
                  <div
                    style={{ display: submitBtns ? 'flex' : 'none' }}
                    className={styles['submit-btns']}
                  >
                    <button
                      onClick={() => setRedirectPage('lead-details')}
                      type="submit"
                      disabled={!isValid}
                    >
                      {t('createLeadAndLeadPage')}
                    </button>
                    <hr />
                    <button
                      onClick={() => setRedirectPage('leads')}
                      type="submit"
                      disabled={!isValid}
                    >
                      {t('createLeadandNewLead')}
                    </button>
                  </div>
                </div>

                <Link to={ROUTES['MyDeals'].path} className="anchor">
                  {t('cancel')}
                </Link>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
export default NewDealMontenegro;
