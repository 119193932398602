import { OptionValue } from '@orascom/api-interfaces';

export enum MaritalStatusEnum {
  SINGLE = '1',
  MARRIED = '2',
}

export enum GenderEnum {
  MALE = '1',
  FEMALE = '2',
}
export interface CustomerNationality {
  nationality_id: string;
  nationality_name: string;
}

export interface CustomerCountry {
  country_id: string;
  country_name: string;
}

export interface CustomerCity {
  city_id: string;
  city_name: string;
}

export interface CustomerOccupation {
  label: string;
  code: number;
}

export interface QualifyCustomerInput {
  issuedFrom: string | null;
  arabicIssueFrom: string | null;
  arabicAddress: string | null;
  email: string | null;
  originNationalityId: OptionValue | null;
  nationality: OptionValue | null;
  country: OptionValue | null;
  city: OptionValue | null | string;
  district: string;
  address: string;
  occupation: OptionValue | null;
  maritalStatus: MaritalStatusEnum;
  gender: GenderEnum;
  identificationForm: string;
  nationalIdNumber: string;
  expiryDate: number | null;
  issueDate: number | null;
  dateOfBirth: number | null;
  nationalIdFrontImage?: File;
  nationalIdBackImage?: File;
  passportNumber: string;
  passportImage?: File;
  otherDistrictName: string | null;
}

export interface QualifyCustomer {
  issued_from: string | null;
  arabic_issue_from: string | null;
  arabic_address: string | null;
  email: string | null;
  destination_slug?: string;
  lead_source_id: string;
  origin_nationality_id: string;
  nationality_id: string;
  expiry_date: number | null;
  issue_date: number | null;
  date_of_birth: number | null;
  country_id: string;
  city_id: string | null;
  city_name: string | null;
  district_id: string;
  district_name: string;
  address: string;
  occupation_code: string;
  other_occupation_name: '';
  marital_status: number;
  gender: number;
  passport_number: string;
  national_id_number: string;
  other_district_name: string | null;
}
export interface QualifyCustomerResponse {
  customer_source_id: string;
}
