import { useTranslation } from 'react-i18next';
import styles from './login.module.scss';
import logo from '../../assets/icons/logo-horizontal.png';
import { AuthWrapper } from '@orascom/broker-sales-man-common-components';
import { NavyAnchor } from '@orascom/common-components';
import MicrosoftIcon from '../../assets/icons/microsoft.svg';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../api/routes';
import { USER_CONTEXT } from '../../context/user-context';

const baseUrl = process.env['NX__SALESMAN_API_ENDPOINT']?.replace(/\/api$/, '');
if (!baseUrl) {
  throw new Error('NX__SALESMAN_API_ENDPOINT is not defined');
}

export function Login() {
  const { t } = useTranslation();

  const userContext = useContext(USER_CONTEXT);
  const navigate = useNavigate();
  useEffect(() => {
    if (userContext.user) {
      navigate(ROUTES['Overview'].path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext.user]);

  return (
    <AuthWrapper>
      <div className={styles['wrapper']}>
        <div className={styles['heading']}>
          <img
            src={logo}
            alt=""
            role="presentation"
            className={styles['logo']}
          />
          <h2 className={styles['subtitle']}>{t('internalSalesPortal')}</h2>
        </div>
        <div>
          <p>{t('signinUsingMicrosoft')}</p>
          <NavyAnchor
            className={styles['signin-btn']}
            href={`${baseUrl}/login/microsoft`}
          >
            <img src={MicrosoftIcon} alt="" role="presentation" />
            {t('signIn')}
          </NavyAnchor>
        </div>
      </div>
    </AuthWrapper>
  );
}

export default Login;
