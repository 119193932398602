import { OptionValue } from '@orascom/api-interfaces';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

interface ActiveCountryContext {
  countryOptions?: OptionValue<string | number>[];
  setActiveCountry?: (country: OptionValue<string | number>) => void;
  setDisableCountrySelection?: (disable: boolean) => void;
}
export function useSetActiveCountryFromParams(
  activeCountry: ActiveCountryContext
) {
  const { countryId } = useParams();

  useEffect(() => {
    // Ensure activeCountry and its options are available and that countryId exists.
    if (!activeCountry || !activeCountry.countryOptions || !countryId) return;

    const foundCountry = activeCountry.countryOptions.find(
      (country) => country.value === countryId
    );

    if (foundCountry) {
      activeCountry.setActiveCountry?.(foundCountry);
    }

    activeCountry.setDisableCountrySelection?.(true);

    return () => {
      activeCountry.setDisableCountrySelection?.(false);
    };
  }, [activeCountry, countryId]);
}
