export enum SalesManWalkthroughIds {
  STEP_1 = 'sales-man-walkthrough-step-1',
  STEP_2 = 'sales-man-walkthrough-step-2',
  STEP_3 = 'sales-man-walkthrough-step-3',
  STEP_4 = 'sales-man-walkthrough-step-4',
  STEP_5 = 'sales-man-walkthrough-step-5',
  STEP_6 = 'sales-man-walkthrough-step-6',
  STEP_7 = 'sales-man-walkthrough-step-7',
  STEP_8 = 'sales-man-walkthrough-step-8',
  STEP_9 = 'sales-man-walkthrough-step-9',
  STEP_10 = 'sales-man-walkthrough-step-10',
  STEP_11 = 'sales-man-walkthrough-step-11',
}

export enum BrokerWalkthroughIds {
  STEP_1 = 'borker-walkthrough-step-1',
  STEP_2 = 'borker-walkthrough-step-2',
  STEP_3 = 'borker-walkthrough-step-3',
  STEP_4 = 'borker-walkthrough-step-4',
  STEP_5 = 'borker-walkthrough-step-5',
  STEP_6 = 'borker-walkthrough-step-6',
  STEP_7 = 'borker-walkthrough-step-7',
  STEP_8 = 'borker-walkthrough-step-8',
  STEP_9 = 'borker-walkthrough-step-9',
}

export enum BrokerFunctionStep {
  step_1 = 'step-1',
  step_2 = 'step-2',
  step_3 = 'step-3',
}
