import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../api/routes';
import arrowIcon from '../../assets/icons/white-arrow.svg';
import styles from './recent-updates.module.scss';
// import { Loader } from '@orascom/common-components';
// import useNotifications from '../../hooks/use-notifications'; TODO check this when integration is needed
import NotificationsUpdates from '../notifications-wrapper/notifications-wrapper';
import { UpdatesInterface } from '@orascom/api-interfaces';

export function RecentUpdates() {
  const { t } = useTranslation();
  // const { notifications, loading } = useNotifications(1, 2, true); TODO check this when integration is needed
  // if (loading) {
  //   return <Loader />;
  // }
  const dateForStaticData = Math.floor(Date.now() / 1000) - 2 * 24 * 60 * 60;

  const staticNotification: UpdatesInterface[] = [
    {
      id: 10,
      message:
        'your sales invoice on the OW/HV3-3-VB Standalone Villa deal has been confirmed',
      created_at: dateForStaticData,
      is_read: false,
      redirect_data: {
        type: 'test',
        country_id: 1,
        lead_id: '10',
        sale_id: '12',
      },
    },
    {
      id: 11,
      message:
        'your sales invoice on the OW/QA1-18-1-1 Apartment + Nanny Room deal has been confirmed',
      created_at: dateForStaticData,
      is_read: false,
      redirect_data: {
        type: 'test 2',
        country_id: 1,
        lead_id: '11',
        sale_id: '13',
      },
    },
  ];

  return (
    <div className={styles['wrapper']}>
      <div className={styles['header']}>
        <h3>{t('recentUpdates')}</h3>
        {/* TODO uncomment when needed */}
        {/* <Link to={ROUTES['Notifications'].path} className={styles['anchor']}>
          <span className="anchor">{t('viewAllUpdates')}</span>
          <img src={arrowIcon} alt="" role="presentation" />
        </Link> */}
      </div>
      <div className={styles['flex']}>
        {staticNotification.map((u) => (
          <NotificationsUpdates key={u.id} notification={u} dashboard />
        ))}
      </div>
    </div>
  );
}

export default RecentUpdates;
