import { Loader } from '@orascom/common-components';
import { format } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PipelineStageEnum,
  Meetings,
  MontenegroPipelineStageEnum,
} from '../../definitions/interfaces/deals.interface';
import { PipelineDetails } from '../../definitions/interfaces/lead-stages.interface';
import { Deal as DealUtils } from '../../utils/deal.utils';
import AddNewMeeting from './add-new-meeting';
import styles from './deal-scheduled-meetings.module.scss';
import { COUNTRY_SELECTED_CONTEXT } from '../../context/country-selected-context';

export interface ScheduledMeetingsProps {
  selectedPipeline: PipelineDetails;
  dealStatus?: PipelineStageEnum | MontenegroPipelineStageEnum;
}
export function ScheduledMeetings({
  selectedPipeline,
  dealStatus,
}: Readonly<ScheduledMeetingsProps>) {
  const { t } = useTranslation();
  const [meetingsLoading, setMeetingsLoading] = useState(true);
  const [meetings, setMeetings] = useState<Meetings[]>();
  const [addAnotherMeeting, setAddAnotherMeeting] = useState(false);
  const [forceMeetingRefetch, setForceMeetingRefetch] = useState(false);

  const pipelineSourceId = selectedPipeline?.pipeline_source_id;

  const activeCountry = useContext(COUNTRY_SELECTED_CONTEXT);

  const isClosingOrPitchPhase =
    dealStatus &&
    [PipelineStageEnum.DEAL_CLOSING, PipelineStageEnum.SALES_PITCH].includes(
      dealStatus as PipelineStageEnum
    );

  const isThirdOrFourthPhaseMontenegro =
    dealStatus &&
    [
      MontenegroPipelineStageEnum.THIRD_STAGE,
      MontenegroPipelineStageEnum.FOURTH_STAGE,
    ].includes(dealStatus as MontenegroPipelineStageEnum);

  const hasMeetings = meetings && meetings?.length > 0;
  const canAddMeeting =
    !hasMeetings && (isClosingOrPitchPhase || isThirdOrFourthPhaseMontenegro);
  const showNoMeetings = !hasMeetings && !canAddMeeting;

  useEffect(() => {
    setMeetingsLoading(true);
    DealUtils.listMeetings(
      pipelineSourceId,
      activeCountry.activeCountry?.value.toString() ?? ''
    )
      .then((res) => {
        setMeetings(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setMeetingsLoading(false));
  }, [pipelineSourceId, forceMeetingRefetch]);

  const refetchMeetgins = () => {
    setForceMeetingRefetch(!forceMeetingRefetch);
    setAddAnotherMeeting(false);
  };

  if (meetingsLoading) {
    return (
      <div className={`${styles['meetings']}`}>
        <p className={styles['title']}>
          {t('scheduledMeetings')}
          <div className={styles['loading-meetings']}>
            <Loader />
          </div>
        </p>
      </div>
    );
  }
  return (
    <div className={styles['meetings']}>
      <div className={styles['header']}>
        <p className={styles['title']}>{t('scheduledMeetings')}</p>
        {hasMeetings && (
          <button
            onClick={() => setAddAnotherMeeting((val) => !val)}
            className={styles['add-another-meeting']}
            type="button"
          >
            {t('addAnotherMeeting')}
          </button>
        )}
      </div>

      {!hasMeetings && (
        <>
          <p className={styles['description']}>{t('addMeetingDescription')}</p>
          <AddNewMeeting refetchMeetgins={refetchMeetgins} />
        </>
      )}

      {hasMeetings && (
        <>
          <div className={styles['meetings-wrapper']}>
            {meetings.map((meeting, index) => {
              const [datePart, timePart] = meeting.meeting_date.split(' ');
              const [year, month, day] = datePart.split('-');
              const dateObject = new Date(
                `${year}-${month}-${day}T${timePart}`
              );

              return (
                <div
                  key={meeting.meeting_source_id}
                  className={styles['meeting']}
                >
                  <p className={styles['label']}>
                    {t('meeting')} {index + 1}
                  </p>
                  <div className={styles['info']}>
                    <p>{t('meetingDate')}</p>
                    <span>
                      {format(new Date(dateObject), 'EEE, d MMM yyyy')}
                    </span>
                  </div>
                  {meeting.meeting_comment && (
                    <div className={styles['info']}>
                      <p>{t('meetingDescription')}:</p>
                      <p>{meeting.meeting_comment}</p>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          {addAnotherMeeting && (
            <AddNewMeeting refetchMeetgins={refetchMeetgins} />
          )}
        </>
      )}

      {showNoMeetings && (
        <p className={styles['no-meetings']}>{t('noMeetingsFound')}</p>
      )}
    </div>
  );
}

export default ScheduledMeetings;
