import { useTranslation } from 'react-i18next';
import { PipelineStageEnum } from '../../definitions/interfaces/deals.interface';
import styles from './deal-details-breadcrumbs.module.scss';

interface DealDetailsBreadcrumbsProps {
  dealStatus: PipelineStageEnum;
  page: 'Customer' | 'Lead';
  id?: string;
}

export function DealDetailsBreadcrumbs({
  id,
  dealStatus,
  page,
}: Readonly<DealDetailsBreadcrumbsProps>) {
  const { t } = useTranslation();

  const isLostPipeline = dealStatus === PipelineStageEnum.LOST;

  const isDiscoveryActive = true; // it is always active unless the pipeline is lost
  const isSalesPitchActive = [
    PipelineStageEnum.SALES_PITCH,
    PipelineStageEnum.DEAL_CLOSING,
  ].includes(dealStatus);
  const isDealClosingActive = dealStatus === PipelineStageEnum.DEAL_CLOSING;

  const getActiveClass = (isActive: boolean) => {
    let className = styles['breadcrumb'];
    if (isActive && !isLostPipeline) {
      className += ` ${styles['breadcrumb--active']}`;
    }
    return className;
  };

  return (
    <div id={id} className={styles['wrapper']}>
      <button
        className={`${styles['breadcrumb']} ${getActiveClass(
          isDiscoveryActive
        )}`}
      >
        <h4>{page === 'Lead' ? t('leadDiscovery') : t('customerDiscovery')}</h4>
        <p>
          {page === 'Lead'
            ? t('leadDicoveryDescription')
            : t('customerDiscoveryDescription')}
        </p>
      </button>
      <button className={getActiveClass(isSalesPitchActive)}>
        <h4>{t('salesPitch')}</h4>
        <p>{t('salesPitchDescription')}</p>
      </button>
      <button className={getActiveClass(isDealClosingActive)}>
        <h4>{t('dealClosing')}</h4>
        <p>{t('dealClosingDescription')}</p>
      </button>
    </div>
  );
}

export default DealDetailsBreadcrumbs;
