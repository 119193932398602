import { useTranslation } from 'react-i18next';
import styles from './pipeline-type.module.scss';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import { PipelineTypeEnum } from '../../utils/new-deal.utils';

export function PipelineType() {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<{
    pipelineType: number;
  }>();

  const handleRadioChange = (value: number) => {
    setFieldValue('pipelineType', value);
  };

  return (
    <div className={styles['lead-type']}>
      <div className={styles['header']}>{t('pipelineType')}</div>
      <div role="group" className="input-wrapper radio-buttons--small">
        <label htmlFor="pipelineType">{t('selectPipelineType')}</label>
        <div>
          <label>
            <div>
              <p>{t('multipleUnits')}</p>
              <Field
                type="radio"
                name="multipleUnits"
                value={PipelineTypeEnum.MULTIPLE}
                onClick={() => handleRadioChange(PipelineTypeEnum.MULTIPLE)}
                checked={values.pipelineType === PipelineTypeEnum.MULTIPLE}
              />
            </div>
          </label>
          <label>
            <div>
              <p>{t('singleUnit')}</p>
              <Field
                type="radio"
                name="singleUnit"
                value={PipelineTypeEnum.SINGLE}
                onClick={() => handleRadioChange(PipelineTypeEnum.SINGLE)}
                checked={values.pipelineType === PipelineTypeEnum.SINGLE}
              />
            </div>
          </label>
        </div>
        <ErrorMessage
          name="pipelineType"
          component="div"
          className="form__error"
        />
      </div>
    </div>
  );
}

export default PipelineType;
