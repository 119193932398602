import { useTranslation } from 'react-i18next';
import { FiltersUnits } from '@orascom/broker-sales-man-common-components';
import { Unit as UnitUtils } from '../../utils/unit';
import { Filter as FilterApi } from '../../utils/filter';
import { ROUTES } from '../../api/routes';
import { useContext, useEffect } from 'react';
import { USER_CONTEXT } from '../../context/user-context';
import { useSearchParams } from 'react-router-dom';

export function PrimaryUnits() {
  const { t } = useTranslation();
  const { user } = useContext(USER_CONTEXT);
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    setSearchParams(
      (param) => {
        if (!user?.main_access_destinations.length) {
          param.delete('destinations');
        } else {
          param.set(
            'destinations',
            user?.main_access_destinations.map((v) => v).join(',')
          );
        }

        return param;
      },
      { replace: true }
    );
  }, []);

  return (
    <FiltersUnits
      portal="sales-man"
      title={t('primaryUnits')}
      onResale={false}
      getUnitFilters={FilterApi.getSalesManUnitFilters}
      getUnits={UnitUtils.getSalesManUnits}
      comparePropertiesPath={ROUTES['CompareProperties'].path}
      unitDetailsPath={ROUTES['UnitDetails'].getPath}
      addSavedUnit={UnitUtils.addSavedUnit}
      deleteSavedUnit={UnitUtils.deleteSavedUnit}
    />
  );
}

export default PrimaryUnits;
