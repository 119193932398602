import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './my-sales.module.scss';
import { PaginationStyled } from '@orascom/broker-sales-man-common-components';
import {
  SalesEgyptInterface,
  SalesMontenegroInterface,
} from '../../definitions/interfaces/sales.interface';
import { Sales, SaleTabs } from '../../utils/sale';
import { Loader } from '@orascom/common-components';
import { format } from 'date-fns';
import { COUNTRY_SELECTED_CONTEXT } from '../../context/country-selected-context';
import ActiveTabs from '../../components/leads-active-tabs/leads-active-tabs';
import TableComponent, { TableColumn } from '../../components/table/table';
import { SalesManagerCountries } from '../../utils/country-selection';

export function MySales() {
  const { t } = useTranslation();
  const activeCountry = useContext(COUNTRY_SELECTED_CONTEXT);

  const [salesData, setSalesData] = useState<
    SalesMontenegroInterface[] | SalesEgyptInterface[]
  >([]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = salesData.slice(indexOfFirstItem, indexOfLastItem);

  const [tableLoading, setTableLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);

  const activeDealsTabData = [
    { id: SaleTabs.All, label: t('allSales') },
    { id: SaleTabs.Pending, label: t('pendingSales') },
    { id: SaleTabs.Reserved, label: t('reservedSales') },
    { id: SaleTabs.Contracted, label: t('contractedSales') },
  ];

  useEffect(() => {
    setTableLoading(true);
    Sales.getSales(
      activeTab,
      activeCountry.activeCountry?.value.toString() ?? ''
    )
      .then((res) => {
        setSalesData(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setTableLoading(false);
      });
  }, [activeTab, activeCountry]);

  const handleTabBtn = (tabId: number) => {
    if (activeTab !== tabId) {
      setActiveTab(tabId);
      setCurrentPage(1);
      setTableLoading(true);
    }
  };

  const isColumnsMontenegro =
    activeCountry.activeCountry?.value === SalesManagerCountries.MONTENEGRO
      ? true
      : false;
  const isColumnsEgypt =
    activeCountry.activeCountry?.value === SalesManagerCountries.EGYPT
      ? true
      : false;

  const columns = [
    {
      label: t('unitName'),
      columnClassName: 'manager-th',
      renderColumn: (label: string) => label,
      rowClassName: 'manager-td--name',
      renderRow: (row: SalesEgyptInterface) =>
        row.unit_name ? row.unit_name : '-',
      condition: isColumnsEgypt,
    },
    {
      label: t('customerName'),
      columnClassName: 'manager-th',
      renderColumn: (label: string) => label,
      rowClassName: 'manager-td--name',
      rowInlineStyles: { whiteSpace: 'normal' },
      renderRow: (row: SalesEgyptInterface) =>
        row.customer_name ? row.customer_name : '-',
      condition: isColumnsEgypt,
    },
    {
      label: t('status'),
      columnClassName: 'manager-th',
      renderColumn: (label: string) => label,
      rowClassName: 'sales-man-td',
      renderRow: (row: SalesEgyptInterface) =>
        row.sale_status ? row.sale_status : '-',
      condition: isColumnsEgypt,
    },
    {
      label: t('reservationDate'),
      columnClassName: 'manager-th',
      renderColumn: (label: string) => label,
      rowClassName: 'sales-man-td',
      renderRow: (row: SalesEgyptInterface) => {
        if (!row.reservation_date) return '-';
        const date = new Date(row.reservation_date);
        const formattedDate = format(date, 'dd MMMM yyyy');
        return formattedDate;
      },
      condition: isColumnsEgypt,
    },
    {
      label: t('unitPrice'),
      columnClassName: 'manager-th',
      renderColumn: (label: string) => label,
      rowClassName: 'sales-man-td',
      renderRow: (row: SalesEgyptInterface) =>
        row.unit_price_with_currency ? row.unit_price_with_currency : '-',
      condition: isColumnsEgypt,
    },
    {
      label: t('sellingPrice'),
      columnClassName: 'manager-th',
      renderColumn: (label: string) => label,
      rowClassName: 'sales-man-td',
      renderRow: (row: SalesEgyptInterface) => (row.price ? row.price : '-'),
      condition: isColumnsEgypt,
    },
    {
      label: t('paymentTerm'),
      columnClassName: 'manager-th',
      renderColumn: (label: string) => label,
      rowClassName: 'sales-man-td',
      renderRow: (row: SalesEgyptInterface) =>
        row.payment_term_name ? row.payment_term_name : '-',
      condition: isColumnsEgypt,
    },
    {
      label: t('saleSource'),
      columnClassName: 'manager-th',
      renderColumn: (label: string) => label,
      rowClassName: 'manager-td--name',
      renderRow: (row: SalesEgyptInterface) =>
        row.sale_source ? row.sale_source : '-',
      condition: isColumnsEgypt,
    },
    // Montenegro Columns
    {
      label: t('unitName'),
      columnClassName: 'manager-th',
      renderColumn: (label: string) => label,
      rowClassName: 'manager-td--name',
      renderRow: (row: SalesMontenegroInterface) =>
        row.unit_name ? row.unit_name : '-',
      condition: isColumnsMontenegro,
    },
    {
      label: t('customerName'),
      columnClassName: 'manager-th',
      renderColumn: (label: string) => label,
      rowClassName: 'manager-td--name',
      rowInlineStyles: { whiteSpace: 'normal' },
      renderRow: (row: SalesMontenegroInterface) =>
        row.customer_name ? row.customer_name : '-',
      condition: isColumnsMontenegro,
    },
    {
      label: t('ownerId'),
      columnClassName: 'manager-th',
      renderColumn: (label: string) => label,
      rowClassName: 'manager-td--name',
      renderRow: (row: SalesMontenegroInterface) =>
        row.owner_id ? row.owner_id : '-',
      condition: isColumnsMontenegro,
    },
    {
      label: t('status'),
      columnClassName: 'manager-th',
      renderColumn: (label: string) => label,
      rowClassName: 'sales-man-td',
      renderRow: (row: SalesMontenegroInterface) =>
        row.sale_status ? row.sale_status : '-',
      condition: isColumnsMontenegro,
    },
    {
      label: t('unitPrice'),
      columnClassName: 'manager-th',
      renderColumn: (label: string) => label,
      rowClassName: 'sales-man-td',
      renderRow: (row: SalesMontenegroInterface) =>
        row.price ? row.price : '-',
      condition: isColumnsMontenegro,
    },
    {
      label: t('sellingPrice'),
      columnClassName: 'manager-th',
      renderColumn: (label: string) => label,
      rowClassName: 'sales-man-td',
      renderRow: (row: SalesMontenegroInterface) =>
        row.selling_price ? row.selling_price : '-',
      condition: isColumnsMontenegro,
    },
    {
      label: t('currency'),
      columnClassName: 'manager-th',
      renderColumn: (label: string) => label,
      rowClassName: 'manager-td--name',
      renderRow: (row: SalesMontenegroInterface) =>
        row.currency ? row.currency : '-',
      condition: isColumnsMontenegro,
    },
    {
      label: t('paymentPlan'),
      columnClassName: 'manager-th',
      renderColumn: (label: string) => label,
      rowClassName: 'sales-man-td',
      renderRow: (row: SalesMontenegroInterface) =>
        row.payment_plan ? row.payment_plan : '-',
      condition: isColumnsMontenegro,
    },
    {
      label: t('saleSource'),
      columnClassName: 'manager-th',
      renderColumn: (label: string) => label,
      rowClassName: 'manager-td--name',
      renderRow: (row: SalesMontenegroInterface) =>
        row.source_of_sale ? row.source_of_sale : '-',
      condition: isColumnsMontenegro,
    },
    {
      label: t('subSourceName'),
      columnClassName: 'manager-th',
      renderColumn: (label: string) => label,
      rowClassName: 'manager-td--name',
      renderRow: (row: SalesMontenegroInterface) =>
        row.subsource_name ? row.subsource_name : '-',
      condition: isColumnsMontenegro,
    },
  ];

  return (
    <div className={styles['wrapper']}>
      <h1 className={styles['title']}>{t('mySales')}</h1>

      <ActiveTabs
        tableLoading={tableLoading}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        handleTabBtn={handleTabBtn}
        tabData={activeDealsTabData}
      />
      <div
        style={{
          overflow: 'auto',
          display: 'grid',
          marginBlockStart: '20px',
        }}
        className={`${styles['table']} ${
          currentItems.length ? styles['scroll-bar'] : ''
        }`}
      >
        {tableLoading ? (
          <div className={styles['loader-wrapper']}>
            <Loader />
          </div>
        ) : currentItems.length > 0 ? (
          <TableComponent
            columns={
              columns as TableColumn<
                SalesMontenegroInterface | SalesEgyptInterface
              >[]
            }
            data={
              currentItems as (SalesMontenegroInterface | SalesEgyptInterface)[]
            }
          />
        ) : (
          <p>{t('noSalesToDisplay')}</p>
        )}
      </div>
      {!tableLoading && currentItems.length ? (
        <div className={styles['pagination-info']}>
          <p className={styles['count']}>
            {t('showing')}{' '}
            <span>
              {' '}
              {indexOfFirstItem + 1} -{' '}
              {indexOfLastItem > salesData.length
                ? salesData.length
                : indexOfLastItem}{' '}
            </span>{' '}
            {t('outOf')} <span>{salesData.length} </span>
            {t('sales')}
          </p>

          <PaginationStyled
            currentPage={currentPage}
            totalCount={salesData.length}
            pageSize={itemsPerPage}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        </div>
      ) : null}
    </div>
  );
}

export default MySales;
