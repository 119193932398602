import { OptionValue } from '@orascom/api-interfaces';
import * as React from 'react';

export const COUNTRY_SELECTED_CONTEXT = React.createContext<{
  activeCountry?: OptionValue;
  setActiveCountry?: (country: OptionValue) => void;
  disableCountrySelection?: boolean;
  setDisableCountrySelection?: (value: boolean) => void;
  countryOptions?: OptionValue[];
  setCountryOptions?: (value: OptionValue[]) => void;
}>({
  activeCountry: undefined,
  setActiveCountry: () => {},
  disableCountrySelection: false,
  setDisableCountrySelection: () => {},
  countryOptions: [],
  setCountryOptions: () => {},
});
