import { Payload } from '@orascom/api-interfaces';
import { handleError } from '@orascom/utils';
import { Sale as SaleAPI } from '../api/sales';
import {
  SaleSubmission,
  SalesEgyptInterface,
  SalesMontenegroInterface,
} from '../definitions/interfaces/sales.interface';

export class Sales {
  public static getSales(
    viewNumber: number,
    countryId: string
  ): Promise<Payload<SalesMontenegroInterface[] | SalesEgyptInterface[]>> {
    const query = new URLSearchParams('');
    query.append('view_number', String(viewNumber));

    return SaleAPI.getSales(query, countryId)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static createSale(
    saleData: SaleSubmission,
    unit: string,
    countryId: string
  ) {
    return SaleAPI.createSale(saleData, unit, countryId)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static updateSale(
    saleData: Partial<SaleSubmission>,
    saleId: string,
    unitId: string,
    countryId: string
  ) {
    return SaleAPI.updateSale(saleData, saleId, unitId, countryId)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static uploadAttachment(
    unitId: string,
    saleId: string,
    file: File,
    countryId: string
  ): Promise<void> {
    return SaleAPI.uploadAttachment(unitId, saleId, file, countryId)
      .then((result) => {
        return Promise.resolve(result.data);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }
}

export enum ReservationPaymentMethods {
  CASH = '127990000',
  BANK_TRANSFER = '127990001',
  CREDIT_DEBIT_CARD = '127990002',
  CHEQUE = '127990003',
  UNIT_TRANSFER = '127990004',
}

export enum SaleTabs {
  All = 1,
  Pending = 2,
  Reserved = 3,
  Contracted = 4,
}

export const reservationPaymentOptions = [
  { label: 'Cash', value: ReservationPaymentMethods.CASH },
  { label: 'Bank Transer', value: ReservationPaymentMethods.BANK_TRANSFER },
  {
    label: 'Credit/Debit Card',
    value: ReservationPaymentMethods.CREDIT_DEBIT_CARD,
  },
  { label: 'Cheque', value: ReservationPaymentMethods.CHEQUE },
  { label: 'Unit Transfer', value: ReservationPaymentMethods.UNIT_TRANSFER },
];
