import styles from './montenegro-lead-stages.module.scss';
import PipelineType from '../pipeline-type-montenegro/pipeline-type';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import LeadFeedback from '../lead-feedback/lead-feedback';
import {
  MontenegroPipelineStageEnum,
  UpdateMontenegroPipeline,
} from '../../definitions/interfaces/deals.interface';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { COUNTRY_SELECTED_CONTEXT } from '../../context/country-selected-context';
import { useUserPipelineContext } from '../../context/user-pipeline-context';
import { Customer as CustomerApi } from '../../utils/customer';
import { Deal as DealUtils, Deal as LeadApi } from '../../utils/deal.utils';
import {
  LeadCreationResponse,
  LeadDiscoveryInterface,
} from '../../definitions/interfaces/lead-stages.interface';
import { toast } from 'react-toastify';
import { LoadingButton } from '@orascom/common-components';
import { UnitType } from '../unit-types/unit-types';
import { useParams } from 'react-router-dom';

export function FirstStage() {
  const { t } = useTranslation();
  const activeCountry = useContext(COUNTRY_SELECTED_CONTEXT);
  const { countryId } = useParams();

  const [updatingPipeline, setUpdatingPipeline] = useState(false);
  const [selectingNewPipeline, setSelectingNewPipeline] = useState(false);

  const {
    selectedPipeline,
    setPipelines,
    setSelectedPipelineId,
    setRefetchingPipelines,
    leadId,
    customerId,
  } = useUserPipelineContext();

  // Function to fetch and set pipelines after update
  const fetchAndSetPipelines = async (
    userId: string,
    newPipelineId: string
  ) => {
    const getPipeLines = customerId
      ? CustomerApi.getCustomerPipeline
      : LeadApi.getPipelineDetails;

    const pipelines = await getPipeLines(userId, countryId ?? '');
    setPipelines(pipelines);

    const updatedPipeline = pipelines.find(
      (pipeline) => pipeline.pipeline_source_id === newPipelineId
    );

    const phaseChanged =
      selectedPipeline?.current_phase !== updatedPipeline?.current_phase;

    if (updatedPipeline) {
      // Select the updated pipeline to move the form to the next step
      setSelectedPipelineId(updatedPipeline.pipeline_source_id);
    }

    return { phaseChanged };
  };

  const handleUpdate = async () => {
    const userId = customerId ?? leadId;

    if (!userId) return;

    setUpdatingPipeline(true);

    const updatePipelineData: UpdateMontenegroPipeline = {
      lead_source_id: leadId ?? '',
      feedback_id: selectedPipeline?.feedback_id,
      is_unit_type_selected: true,
      is_interest_identified: true,
      customer_source_id: customerId ?? '',
    };
    try {
      // just a guard
      if (!selectedPipeline) return;

      // In createmode we update after creation because the create request doesn't allow sending all required data.
      const updateResponse = await DealUtils.updateDealPipeline(
        selectedPipeline?.pipeline_source_id,
        updatePipelineData,
        activeCountry.activeCountry?.value.toString() ?? ''
      );

      setUpdatingPipeline(false);

      setSelectingNewPipeline(true); // loading state for creating button
      setRefetchingPipelines(true); // loading state for pipeline dropdown

      // Fetch and set pipelines after the update
      const { phaseChanged } = await fetchAndSetPipelines(
        userId,
        updateResponse.pipeline_source_id
      );

      const successMessage = t('pipelineUpdatedSuccessfully');
      toast.success(successMessage);

      if (phaseChanged) window.scrollTo(0, 0);
    } catch (error) {
      toast.error('Pipeline Creation Failed');
    } finally {
      setUpdatingPipeline(false);
      setSelectingNewPipeline(false);
      setRefetchingPipelines(false);
    }
  };

  const handleSubmit = async (values?: {
    pipelineType: number | null;
    feedbackId: number | null;
    feedbackFollowUpDate: string | null;
    salesComment: string | null;
  }) => {
    const userId = customerId ?? leadId;

    if (!userId) return;

    setUpdatingPipeline(true);
    const createPipelineData: LeadDiscoveryInterface = {
      lead_source_id: leadId ?? '',
      pipeline_type: values?.pipelineType ?? 0,
      feedback_id: values?.feedbackId ?? 0,
      customer_source_id: customerId ?? '',
      followup_date: values?.feedbackFollowUpDate
        ? Math.floor(new Date(values?.feedbackFollowUpDate).getTime() / 1000)
        : undefined,
      sales_comment: values?.salesComment ?? '',
    };

    try {
      const createResponse: LeadCreationResponse | undefined =
        await DealUtils.createLeadDiscoveryPipeline(
          createPipelineData,
          activeCountry.activeCountry?.value.toString() ?? ''
        );

      const createResponsePipelineId = createResponse?.pipeline_source_id;

      // just a guard
      if (!createResponsePipelineId && !selectedPipeline) return;

      setUpdatingPipeline(false);

      setSelectingNewPipeline(true); // loading state for creating button
      setRefetchingPipelines(true); // loading state for pipeline dropdown

      const { phaseChanged } = await fetchAndSetPipelines(
        userId,
        createResponsePipelineId
      );
      const successMessage = t('pipelineCreatedSuccessfully');
      toast.success(successMessage);
      if (phaseChanged) window.scrollTo(0, 0);
    } catch (error) {
      toast.error('Pipeline Creation Failed');
    } finally {
      setUpdatingPipeline(false);
      setSelectingNewPipeline(false);
      setRefetchingPipelines(false);
    }
  };

  const loadingText = updatingPipeline
    ? selectedPipeline
      ? t('updatingPipeline..')
      : t('initializingPipeline..')
    : selectingNewPipeline
    ? t('configuringPipeline..')
    : '';

  const isCreatingPipeline = updatingPipeline || selectingNewPipeline;
  if (selectedPipeline) {
    return (
      <div className={styles['discovery-sections']}>
        <div>
          <p>
            {t('pipelineType')}
            {': '} {selectedPipeline.pipeline_type}
          </p>
        </div>
        <UnitType fetchAndSetPipelines={fetchAndSetPipelines} />
        <div>
          <p>{t('feedback')}:</p>
          <div className={styles['pipeline-feedback']}>
            <p>Feedback:</p>
            <p>{selectedPipeline.feedback_value ?? '-'}</p>
          </div>
        </div>
        <LoadingButton
          className={`btn btn--navy ${styles['create-pipeline-btn']}`}
          onClick={() => handleUpdate()}
          loading={isCreatingPipeline}
          loadingText={loadingText}
          spinnerSize="sm"
          disabled={selectedPipeline?.unit_type_count_rollup === 0}
        >
          {t('updatePipeline')}
        </LoadingButton>
      </div>
    );
  } else {
    return (
      <Formik
        initialValues={{
          pipelineType: null,
          feedbackId: null,
          salesComment: '',
          feedbackFollowUpDate: '',
        }}
        validationSchema={yup.object({
          pipelineType: yup.number().nullable().required('Required'),
          feedbackId: yup
            .number()
            .required(t('required'))
            .moreThan(0, t('pleaseChooseAnOption')),
        })}
        onSubmit={handleSubmit}
      >
        {({ isValid }) => {
          return (
            <Form className={styles['discovery-sections']}>
              <PipelineType />
              <LeadFeedback
                dealStatus={MontenegroPipelineStageEnum.FIRST_STAGE}
              />
              <LoadingButton
                className={`btn btn--navy ${styles['create-pipeline-btn']}`}
                disabled={!isValid}
                type="submit"
                loading={isCreatingPipeline}
                loadingText={loadingText}
                spinnerSize="sm"
              >
                {t('createPipeline')}
              </LoadingButton>
            </Form>
          );
        }}
      </Formik>
    );
  }
}
