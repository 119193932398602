import { useState } from 'react';
import styles from './montenegro-lead-stages.module.scss';
import { useUserPipelineContext } from '../../context/user-pipeline-context';
import LeadActivityLog from '../lead-activity-log/lead-activity-log';
import SubmitFeedback from '../editable-feedback/submit-feedback';
import { MontenegroPipelineStageEnum } from '../../definitions/interfaces/deals.interface';
import ScheduledMeetings from '../deal-scheduled-meetings/deal-scheduled-meetings';
import ChooseUnits from '../choose-units/choose-units';

export function ThirdStage() {
  const { selectedPipeline } = useUserPipelineContext();

  const [refetchActivityLog, setRefetchActivityLog] = useState(true);
  const refetchLogs = () => setRefetchActivityLog(!refetchActivityLog);

  if (!selectedPipeline) return;
  return (
    <div className={styles['discovery-sections']}>
      <ChooseUnits />
      <SubmitFeedback
        refetchLogs={refetchLogs}
        dealStatus={MontenegroPipelineStageEnum.THIRD_STAGE}
      />
      <ScheduledMeetings
        selectedPipeline={selectedPipeline}
        dealStatus={MontenegroPipelineStageEnum.THIRD_STAGE}
      />
      <LeadActivityLog
        pipelineSourceId={selectedPipeline.pipeline_source_id}
        refetchActivityLog={refetchActivityLog}
      />
    </div>
  );
}
