import soldUnits from '../../assets/icons/sold-units.svg';
import unitsRevenue from '../../assets/icons/units-revenue.svg';
import { useTranslation } from 'react-i18next';
import { PieChart } from 'react-minimal-pie-chart';
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Title,
  Legend,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import styles from './analytics-overview.module.scss';
import {
  getMonthName,
  SalesManWalkthroughIds,
} from '@orascom/common-components';
import { useContext, useEffect, useState } from 'react';
import { AnalyticsUtils } from '../../utils/analytics';
import {
  QuarterBreakdownChartInterface,
  TotalUnitsSoldInterface,
} from '../../definitions/interfaces/analytics';
import { CurrencyContext } from '@orascom/utils';
import { toast } from 'react-toastify';
import { COUNTRY_SELECTED_CONTEXT } from '../../context/country-selected-context';

// Register the plugins to the chart
Chart.register(
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Title,
  Legend,
  Tooltip
);

export function AnalyticsOverview() {
  const { t } = useTranslation();

  const { currency: selectedCurrency } = useContext(CurrencyContext);

  const activeCountry = useContext(COUNTRY_SELECTED_CONTEXT);

  // TODO replace with filter timestamps when feature is ready
  const timestampStart = 1735689600;
  const timestampEnd = 1743379200;

  const [totalUnitsSold, setTotalUnitsSold] =
    useState<TotalUnitsSoldInterface>();
  const [quarterBreakdownChartData, setQuarterBreakdownChartData] =
    useState<QuarterBreakdownChartInterface[]>();

  const noOfDealsPieChartData =
    totalUnitsSold && totalUnitsSold.total_number_of_deals > 0
      ? Math.floor(
          (totalUnitsSold.total_number_of_closed_deals /
            totalUnitsSold.total_number_of_deals) *
            100
        )
      : 0;

  useEffect(() => {
    AnalyticsUtils.totalUnitsSold(
      timestampStart,
      timestampEnd,
      activeCountry.activeCountry?.value.toLocaleString() ?? ''
    )
      .then((result) => {
        setTotalUnitsSold(result);
      })
      .catch((error) => {
        toast.error(error.message);
      });

    AnalyticsUtils.QuarterBreakdownChart(
      timestampStart,
      timestampEnd,
      activeCountry.activeCountry?.value.toLocaleString() ?? ''
    )
      .then((result) => {
        setQuarterBreakdownChartData(result);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }, [activeCountry]);

  console.log(quarterBreakdownChartData);

  return (
    <div id={SalesManWalkthroughIds.STEP_2} className={styles['wrapper']}>
      <div className={styles['title']}>
        <h3>{t('analyticsOverview')}</h3>
      </div>
      <div className="card">
        <div className={styles['grid']}>
          <div className={styles['overview-card']}>
            <div className={styles['icon']}>
              <img src={soldUnits} alt="" role="presentation" />
            </div>
            <div className={styles['content']}>
              <p>{t('totalSoldUnits')}</p>
              <h2>
                {totalUnitsSold?.total_number_of_closed_deals} {t('units')}
              </h2>
            </div>
          </div>
          <div className={styles['overview-card']}>
            <div className={styles['icon']}>
              <img src={unitsRevenue} alt="" role="presentation" />
            </div>
            <div className={styles['content']}>
              <p>{t('totalUnitsRevenue')}</p>
              <h2>
                {totalUnitsSold?.total_revenues}{' '}
                {totalUnitsSold?.currency ?? selectedCurrency}
              </h2>
            </div>
          </div>
        </div>
        <div className={styles['grid']}>
          <div className={`${styles['pie-card']} card`}>
            <h3 className={styles['title']}>{t('salesRevenueVsTarget')}</h3>
            <div className={styles['content']}>
              <PieChart
                data={[
                  {
                    title: t('salesRevenue'),
                    value: 75,
                    color: '#B4905C',
                  },
                  {
                    title: t('remaining'),
                    value: 25,
                    color: '#ffffff',
                  },
                ]}
                style={{
                  width: '114px',
                  height: '114px',
                  border: '1px solid rgba(181, 149, 102, 0.3)',
                  padding: '10px',
                  borderRadius: '50%',
                  boxShadow: '0px 10px 17px -5px rgba(18, 43, 94, 0.25)',
                }}
                startAngle={270}
                animate
              />
              <div>
                <h2>75%</h2>
                <h5>75,450,654 {t('egp')}</h5>
                <span>/ 100,000,000 {t('egp')}</span>
              </div>
            </div>
          </div>

          <div className={`${styles['pie-card']} card`}>
            <h3 className={styles['title']}>{t('noOfSoldUnitsVsTarget')}</h3>
            <div className={styles['content']}>
              <PieChart
                data={[
                  {
                    title: t('soldUnits'),
                    value: 68,
                    color: '#B4905C',
                  },
                  {
                    title: t('remaining'),
                    value: 32,
                    color: '#ffffff',
                  },
                ]}
                style={{
                  width: '114px',
                  height: '114px',
                  border: '1px solid rgba(181, 149, 102, 0.3)',
                  padding: '10px',
                  borderRadius: '50%',
                  boxShadow: '0px 10px 17px -5px rgba(18, 43, 94, 0.25)',
                }}
                startAngle={270}
                animate
              />
              <div>
                <h2>68%</h2>
                <h5>6,840 {t('units')}</h5>
                <span>/ 10,000 {t('units')}</span>
              </div>
            </div>
          </div>

          <div className={`${styles['pie-card']} card`}>
            <h3 className={styles['title']}>
              {t('totalNoOfDealsVsClosedDeals')}
            </h3>
            <div className={styles['content']}>
              <PieChart
                data={[
                  {
                    title: t('closedDeals'),
                    value: noOfDealsPieChartData,
                    color: '#B4905C',
                  },
                  {
                    title: t('remaining'),
                    value: 100 - noOfDealsPieChartData,
                    color: '#ffffff',
                  },
                ]}
                style={{
                  width: '114px',
                  height: '114px',
                  border: '1px solid rgba(181, 149, 102, 0.3)',
                  padding: '10px',
                  borderRadius: '50%',
                  boxShadow: '0px 10px 17px -5px rgba(18, 43, 94, 0.25)',
                }}
                startAngle={270}
                animate
              />
              <div>
                <h2>{noOfDealsPieChartData}%</h2>
                <h5>
                  {totalUnitsSold?.total_number_of_closed_deals}{' '}
                  {t('closedDeals')}
                </h5>
                <span>
                  / {totalUnitsSold?.total_number_of_deals} {t('totalDeals')}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className={`card ${styles['chart-wrapper']}`}>
          <h3 className={styles['title']}>{t('quarterBreakdown')}</h3>
          <p className={styles['date']}>01 Jan 2022 - 31 Mar 2022</p>
          <div className={styles['chart']}>
            <h5 className={styles['name']}>{t('noOfDeals')}</h5>
            <Bar
              data={{
                labels: quarterBreakdownChartData?.map((d) =>
                  getMonthName(d.month)
                ),
                datasets: [
                  {
                    label: t('closedDeals'),
                    data: quarterBreakdownChartData?.map(
                      (d) => d.no_of_closed_deals
                    ),
                    backgroundColor: '#003A70',
                    hoverBackgroundColor: '#4C759B',
                    borderRadius: 8,
                    borderSkipped: false,
                    maxBarThickness: 30,
                    categoryPercentage: 0.5,
                    barPercentage: 1,
                  },
                  {
                    label: t('totalDeals'),
                    data: quarterBreakdownChartData?.map((d) => d.no_of_deals),
                    backgroundColor: '#B4905C',
                    hoverBackgroundColor: '#DAB463',
                    borderRadius: 8,
                    borderSkipped: false,
                    maxBarThickness: 30,
                    categoryPercentage: 0.5,
                    barPercentage: 1,
                  },
                ],
              }}
              options={{
                responsive: true,
                maintainAspectRatio: window.innerWidth > 480,
                plugins: {
                  legend: {
                    position: window.innerWidth > 480 ? 'right' : 'top',
                    labels: {
                      boxWidth: 18,
                      boxHeight: 18,
                      borderRadius: 9,
                      useBorderRadius: true,
                    },
                  },
                },
                scales: {
                  x: {
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    grid: {
                      color: 'rgba(21, 44, 91, 0.1)',
                      lineWidth: 0.5,
                    },
                  },
                },
                onHover: (event, chartElement) => {
                  (event.native?.target as HTMLDivElement).style.cursor =
                    chartElement[0] ? 'pointer' : 'default';
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnalyticsOverview;
