import { LoadingButton, SelectDropdown } from '@orascom/common-components';
import { isValid } from 'date-fns';
import { ErrorMessage, Form, Formik } from 'formik';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useUserPipelineContext } from '../../context/user-pipeline-context';
import { PipelineStageEnum } from '../../definitions/interfaces/deals.interface';
import {
  LeadCreationResponse,
  LeadDiscoveryInput,
} from '../../definitions/interfaces/lead-stages.interface';
import useDestinations from '../../hooks/useDestinations';
import { Customer as CustomerApi } from '../../utils/customer';
import { Deal as DealUtils, Deal as LeadApi } from '../../utils/deal.utils';
import InitialMeetingInput from '../initial-meeting/initial-meeting';
import LeadFeedback from '../lead-feedback/lead-feedback';
import PipelineType from '../pipeline-type/pipeline-type';
import UnitCategory from '../unit-category/unit-category';
import styles from './lead-stages.module.scss';
import {
  buildUpdatePipelinePayload,
  getInitialPipelineFormValues,
  transformDiscoveryFormToData,
} from './leadFormData';
import { useDiscoverySchema } from './usePipelineSchemas';
import { COUNTRY_SELECTED_CONTEXT } from '../../context/country-selected-context';
import { useParams } from 'react-router-dom';

export function DicoveryStage() {
  const { t } = useTranslation();

  const activeCountry = useContext(COUNTRY_SELECTED_CONTEXT);
  const { countryId } = useParams();

  const [updatingPipeline, setUpdatingPipeline] = useState(false);
  const [selectingNewPipeline, setSelectingNewPipeline] = useState(false);

  const { destinationsOptions, pendingDestinations } = useDestinations(
    activeCountry.activeCountry?.value.toString()
  );
  const discoverySchema = useDiscoverySchema();

  const {
    selectedPipeline,
    setPipelines,
    setSelectedPipelineId,
    setRefetchingPipelines,
    leadId,
    customerId,
    lead,
  } = useUserPipelineContext();

  const leadDestination = lead?.destination_gouna
    ? destinationsOptions.find((option) => option.value === 'el-gouna')
    : lead?.destination_makadi_byoum
    ? destinationsOptions.find((option) => option.value === 'makadi-heights')
    : destinationsOptions.find((option) => option.value === 'o-west');

  // Function to fetch and set pipelines after update
  const fetchAndSetPipelines = async (
    userId: string,
    newPipelineId: string
  ) => {
    const getPipeLines = customerId
      ? CustomerApi.getCustomerPipeline
      : LeadApi.getPipelineDetails;

    const pipelines = await getPipeLines(userId, countryId ?? '');
    setPipelines(pipelines);

    const updatedPipeline = pipelines.find(
      (pipeline) => pipeline.pipeline_source_id === newPipelineId
    );

    const phaseChanged =
      selectedPipeline?.current_phase !== updatedPipeline?.current_phase;

    if (updatedPipeline) {
      // Select the updated pipeline to move the form to the next step
      setSelectedPipelineId(updatedPipeline.pipeline_source_id);
    }

    return { phaseChanged };
  };

  const createMode = !selectedPipeline;

  const handleSubmit = async (values: LeadDiscoveryInput) => {
    const userId = customerId ?? leadId;

    if (!userId) return;

    setUpdatingPipeline(true);

    const createPipelineData = transformDiscoveryFormToData({
      formValues: values,
      dealId: leadId ?? '',
      customerId: customerId ?? '',
    });

    const updatePipelineData = buildUpdatePipelinePayload({
      dealId: leadId,
      customerId: customerId,
      values: values,
    });

    try {
      let createResponse: LeadCreationResponse | undefined;

      // Create the pipeline
      if (createMode) {
        createResponse = await DealUtils.createLeadDiscoveryPipeline(
          createPipelineData,
          activeCountry.activeCountry?.value.toString() ?? ''
        );
      }

      const createResponsePipelineId = createResponse?.pipeline_source_id;

      // just a guard
      if (!createResponsePipelineId && !selectedPipeline) return;

      const updateId = createMode
        ? createResponsePipelineId
        : selectedPipeline?.pipeline_source_id;

      // In createmode we update after creation because the create request doesn't allow sending all required data.
      const updateResponse = await DealUtils.updateDealPipeline(
        updateId!,
        updatePipelineData,
        activeCountry.activeCountry?.value.toString() ?? ''
      );

      setUpdatingPipeline(false);

      setSelectingNewPipeline(true); // loading state for creating button
      setRefetchingPipelines(true); // loading state for pipeline dropdown

      // Fetch and set pipelines after the update
      const { phaseChanged } = await fetchAndSetPipelines(
        userId,
        updateResponse.pipeline_source_id
      );

      const successMessage = createMode
        ? t('pipelineCreatedSuccessfully')
        : t('pipelineUpdatedSuccessfully');
      toast.success(successMessage);

      if (phaseChanged) window.scrollTo(0, 0);
    } catch (error) {
      toast.error('Pipeline Creation Failed');
    } finally {
      setUpdatingPipeline(false);
      setSelectingNewPipeline(false);
      setRefetchingPipelines(false);
    }
  };

  const loadingText = updatingPipeline
    ? selectedPipeline
      ? t('updatingPipeline..')
      : t('initializingPipeline..')
    : selectingNewPipeline
    ? t('configuringPipeline..')
    : '';

  const isCreatingPipeline = updatingPipeline || selectingNewPipeline;

  return (
    <Formik
      initialValues={{
        ...getInitialPipelineFormValues(selectedPipeline),
        destinationSlug: leadDestination?.value ?? '',
      }}
      validationSchema={discoverySchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ touched, errors, setFieldValue, values }) => (
        <Form className={styles['discovery-sections']}>
          <PipelineType />
          <LeadFeedback dealStatus={PipelineStageEnum.LEAD_DISCOVERY} />

          <InitialMeetingInput />

          <div className="form__input">
            <label>{t('destination')}</label>
            <SelectDropdown
              name="destinationSlug"
              invalid={touched.destinationSlug && !!errors.destinationSlug}
              className={`${styles['select']}`}
              options={destinationsOptions}
              isLoading={pendingDestinations}
              placeholder={t('selectPlaceholder')}
              onChange={(val) => {
                setFieldValue('destinationSlug', val?.value);
              }}
              defaultValue={leadDestination}
              selectedOption={
                destinationsOptions.find(
                  (option) => option.value === values.destinationSlug
                ) || leadDestination
              }
            />

            <ErrorMessage
              name="destinationSlug"
              component="div"
              className="form__error"
            />
          </div>

          <UnitCategory />

          <LoadingButton
            className={`btn btn--navy ${styles['create-pipeline-btn']}`}
            disabled={!isValid || pendingDestinations}
            type="submit"
            loading={isCreatingPipeline}
            loadingText={loadingText}
            spinnerSize="sm"
          >
            {selectedPipeline ? t('updatePipeline') : t('createPipeline')}
          </LoadingButton>
        </Form>
      )}
    </Formik>
  );
}
