import styles from './montenegro-lead-stages.module.scss';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import VisitComponent from '../visit-component/visit-component';
import { useTranslation } from 'react-i18next';
import {
  SecondStageInitialValues,
  VisitTypeValues,
} from '../../definitions/interfaces/montenegro-lead-stages.interface';
import { Deal as DealUtils } from '../../utils/deal.utils';
import { MontenegroPipelineStageEnum } from '../../definitions/interfaces/deals.interface';
import SubmitFeedback from '../editable-feedback/submit-feedback';
import { useContext, useState } from 'react';
import LeadActivityLog from '../lead-activity-log/lead-activity-log';
import { useUserPipelineContext } from '../../context/user-pipeline-context';
import { LoadingButton } from '@orascom/common-components';
import { COUNTRY_SELECTED_CONTEXT } from '../../context/country-selected-context';
import { toast } from 'react-toastify';

export function SecondStage() {
  const { t } = useTranslation();
  const [refetchActivityLog, setRefetchActivityLog] = useState(true);
  const refetchLogs = () => setRefetchActivityLog(!refetchActivityLog);

  const { selectedPipeline, setPipelines, refetchPipelines } =
    useUserPipelineContext();
  const activeCountry = useContext(COUNTRY_SELECTED_CONTEXT);
  const [pendingVisitCreate, setPendingVisitCreate] = useState(false);

  const initialValues: SecondStageInitialValues = {
    // TODO uncomment when needed
    // visitCheckbox: false,
    visitDate: null,
    visitType: null,
  };

  const schema = yup.object({
    // TODO uncomment when needed
    // visitCheckbox: yup.boolean().oneOf([true], t('required')),
    visitDate: yup.string().nullable().required(t('pleaseChooseADate')),
    visitType: yup
      .number()
      .nullable()
      .oneOf(
        [VisitTypeValues.PHYSICAL, VisitTypeValues.VIRTUAL],
        t('pleaseChooseAType')
      ),
  });

  if (!selectedPipeline) return;

  const handleSubmit = (values: SecondStageInitialValues) => {
    // visit Submit is similar to new meeting submit
    const data = {
      pipelineSourceId: selectedPipeline?.pipeline_source_id,
      destinationSlug: selectedPipeline?.destination_slug,
      timestampMilliseconds: values.visitDate
        ? new Date(values.visitDate).getTime()
        : null,
      meetingNotes: '',
      meetingType: values.visitType ?? null,
    };

    if (!data.timestampMilliseconds || !data.meetingType) return;

    setPendingVisitCreate(true);

    DealUtils.createMeeting(
      data.pipelineSourceId,
      Math.floor(data.timestampMilliseconds / 1000),
      data.destinationSlug,
      activeCountry.activeCountry?.value.toString() ?? '',
      data.meetingNotes,
      data.meetingType
    )
      .then(() => {
        setPendingVisitCreate(false);
        return refetchPipelines();
      })
      .then((res) => {
        if (res) setPipelines(res);
        toast.success('Visit added successfully!');
      })
      .catch(() => {
        toast.error('Error adding meeting');
      })
      .finally(() => {
        setPendingVisitCreate(false);
      });
  };

  return (
    <div className={styles['discovery-sections']}>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form>
            <VisitComponent />
            <LoadingButton
              className={`btn btn--navy ${styles['save-btn']}`}
              loading={pendingVisitCreate}
              loadingText={'Saving...'}
              spinnerSize="sm"
              onClick={() => handleSubmit(values)}
            >
              {t('saveInput')}
            </LoadingButton>
          </Form>
        )}
      </Formik>
      <SubmitFeedback
        refetchLogs={refetchLogs}
        dealStatus={MontenegroPipelineStageEnum.SECOND_STAGE}
      />
      <LeadActivityLog
        pipelineSourceId={selectedPipeline.pipeline_source_id}
        refetchActivityLog={refetchActivityLog}
      />
    </div>
  );
}
