import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TriggerFileDownloadOptions,
  triggerFileDownload,
} from '../lib/triggerFileDownload';
import { toast } from 'react-toastify';

interface Options extends TriggerFileDownloadOptions {}

export const useDownload = (options: Options) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const { t } = useTranslation();
  const triggerDownload = async () => {
    try {
      setIsDownloading(true);
      await triggerFileDownload({
        ...options,
      });
      toast.success(t('downloadSuccessfull') as string);
    } catch (error) {
      console.error(t`somethingWentWrong`);
      toast.error(t('downloadFailed') as string);
    } finally {
      setIsDownloading(false);
    }
  };

  return { triggerDownload, isDownloading };
};

export default useDownload;
