import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { format } from 'date-fns';
import styles from './meetings.module.scss';
import { useContext, useEffect, useState } from 'react';
import { User as UserUtils } from '../../utils/user';
import { Meeting } from '../../definitions/interfaces/user';
import { COUNTRY_SELECTED_CONTEXT } from '../../context/country-selected-context';
import { Loader } from '@orascom/common-components';

export function NewMeetings() {
  const { t } = useTranslation();
  const [meetings, setMeetings] = useState<Meeting[]>();
  const [loading, setLoading] = useState(false);

  const activeCountry = useContext(COUNTRY_SELECTED_CONTEXT);

  useEffect(() => {
    setLoading(true);
    UserUtils.listManagerMeetings(
      activeCountry.activeCountry?.value.toString() ?? ''
    )
      .then((res) => {
        setMeetings(res);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const upComingMeetings = meetings
    ?.filter((meeting) => new Date(meeting.meeting_date) > new Date())
    ?.sort(
      (a: Meeting, b: Meeting) =>
        Number(new Date(a.meeting_date)) - Number(new Date(b.meeting_date))
    );

  return (
    <div className={styles['wrapper']}>
      <h3 className={styles['title']}>{t('upcomingMeetings')}</h3>
      {loading ? (
        <Loader />
      ) : (
        <div className={styles['meetings']}>
          {upComingMeetings?.length ? (
            upComingMeetings?.slice(0, 3).map((meeting) => {
              return (
                <div key={uuidv4()} className={styles['meeting-wrapper']}>
                  <div className={styles['meeting']}>
                    <h5>{`Meeting with ${meeting.lead_name ?? 'no name'}`}</h5>
                    <p>
                      {format(
                        new Date(meeting.meeting_date),
                        'EEE. MMM d, yyyy'
                      )}
                    </p>
                  </div>
                  <hr />
                </div>
              );
            })
          ) : (
            <div className={styles['meeting-wrapper']}>
              <p className={styles['empty-placholder']}>
                {t('noUpcomingMeetings')}
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default NewMeetings;
