import { PaginationInterface, UnitInterface } from '@orascom/api-interfaces';
import styles from './units-listing-pagination.module.scss';
import { useTranslation } from 'react-i18next';

export interface UnitsListingPaginationProps {
  units: UnitInterface[];
  loading: boolean;
  paginationData: PaginationInterface | undefined;
  loadUnitsHandler: (loadMore: boolean, page?: number) => void;
  loadingMore: boolean;
}

export function UnitsListingPagination(
  props: Readonly<UnitsListingPaginationProps>
) {
  const { t } = useTranslation();
  return (
    <div>
      {Boolean(props.units?.length) && !props.loading && (
        <div className={styles['pagination']}>
          <div className={styles['info']}>
            <hr className={styles['line']} />
            <div className={styles['count']}>
              {t('showing')} {props.units.length} {t('unitsOutOf')}{' '}
              {props.paginationData?.total}
            </div>
          </div>
          {props.paginationData?.last_page &&
            Boolean(props.paginationData.current_page) &&
            props.paginationData?.last_page >
              props.paginationData?.current_page && (
              <div className={styles['button']}>
                <button
                  className="btn btn--navy"
                  onClick={() => props.loadUnitsHandler(true)}
                  disabled={props.loadingMore}
                >
                  {t('loadMore')}
                </button>
              </div>
            )}
        </div>
      )}
    </div>
  );
}

export default UnitsListingPagination;
