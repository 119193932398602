import { AnalyticsAPI } from '../api/analytics';

import {
  QuarterBreakdownChartInterface,
  TotalUnitsSoldInterface,
} from '../definitions/interfaces/analytics';

export class AnalyticsUtils {
  public static totalUnitsSold(
    from: number,
    to: number,
    countryId: string
  ): Promise<TotalUnitsSoldInterface> {
    return AnalyticsAPI.getTotalUnitsSold(from, to, countryId)
      .then((result) => {
        return Promise.resolve(result.data);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static QuarterBreakdownChart(
    from: number,
    to: number,
    countryId: string,
    destination_ids?: number
  ): Promise<QuarterBreakdownChartInterface[]> {
    return AnalyticsAPI.getQuarterBreakdownChart(
      from,
      to,
      countryId,
      destination_ids
    )
      .then((result) => {
        return Promise.resolve(result.data);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }
}
