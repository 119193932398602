import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../api/routes';
import arrowIcon from '../../assets/icons/left-arrow.svg';
import styles from './lost-lead.module.scss';
import * as yup from 'yup';
import {
  LoadingButton,
  Modal,
  SelectDropdown,
} from '@orascom/common-components';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { COUNTRY_SELECTED_CONTEXT } from '../../context/country-selected-context';
import { useSetActiveCountryFromParams } from '../../hooks/use-active-country-disabling';
import {
  lostLeadOptions,
  LostLeadOptionsValues,
  Deal as DealUtils,
  conditionalFieldsLostLead,
} from '../../utils/deal.utils';
import { PipleineLost } from '../../definitions/interfaces/deals.interface';
import { LostPipelineInput } from '../../definitions/interfaces/lead-stages.interface';
import ConditionalFields from '../../components/lead-feedback/conditional-fields';

export function LostPipeline() {
  const { t } = useTranslation();
  const { leadId, countryId, customerId } = useParams<{
    leadId: string;
    customerId: string;
    countryId: string;
  }>();

  const location = useLocation();
  const pipelineId = location.state?.pipelineId;

  const navigate = useNavigate();

  const activeCountry = useContext(COUNTRY_SELECTED_CONTEXT);
  useSetActiveCountryFromParams(activeCountry);

  const [pendingSubmission, setPendingSubmission] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const initialValues: LostPipelineInput = {
    reason: 0,
    description: '',
    issue: null,
    moreDetails: '',
    lostCompetitorSourceId: '',
    furtherDetails: '',
    competitorAdvantage: '',
    expectedBudget: null,
    currency: '',
    futureBudgetAvailability: '',
    futureOpportunity: null,
    defineIssue: null as number | null,
    clarifyReasons: '',
    expectedDate: '',
    desiredProduct: null as number | null,
    concern: null,
    productDetails: null,
  };

  const handleSubmit = async (values: LostPipelineInput) => {
    if (!leadId || !pipelineId) return;
    setPendingSubmission(true);
    const mappedData: PipleineLost = {
      lead_source_id: leadId ?? '',
      customer_source_id: customerId ?? '',
      justification_id: values.reason.toString(),
      issue: values.issue?.toString(),
      more_details: values.moreDetails,
      lost_competitor_source_id: values.lostCompetitorSourceId,
      further_details: values.furtherDetails,
      expected_budget: values.expectedBudget?.toString(),
      currency: values.currency,
      further_budget_availability: values.futureBudgetAvailability,
      expected_date: values.expectedDate,
      future_opportunity: values.futureOpportunity?.toString(),
      define_issue: values.defineIssue?.toString(),
      concern: values.concern?.toString(),
      desired_product: values.desiredProduct?.toString(),
      product_details: values.productDetails?.toString(),
      clarify_reasons: values.clarifyReasons,
      competitor_advantage: values.competitorAdvantage?.toString(),
    };

    DealUtils.markPipelineLost(
      pipelineId,
      mappedData,
      activeCountry.activeCountry?.value.toString() ?? ''
    )
      .then(() => {
        toast.success('Pipeline marked as lost successfully');
        navigate(ROUTES['MyDeals'].path);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Error marking pipeline as lost');
      })
      .finally(() => setPendingSubmission(false));
  };

  const handleCloseModal = () => {
    setShowModal(false);
    document.body.style.overflow = '';
  };
  return (
    <div className={styles['wrapper']}>
      <Link
        to={ROUTES['LeadPipelines'].getPath?.(leadId, countryId)}
        className={styles['back-anchor']}
      >
        <img src={arrowIcon} alt="" role="presentation" />
        <span className="anchor">{t('back')}</span>
      </Link>
      <h1 className={styles['title']}>{t('markPipelineAsLost')}</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object({
          reason: yup
            .number()
            .required(t('required'))
            .moreThan(0, t('pleaseChooseAnOption')),
          description: yup.string().nullable(),
          ...conditionalFieldsLostLead,
        })}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, setFieldValue, isValid, setValues }) => {
          return (
            <Form className={styles['lost-pipeline-input']}>
              <div className="form__input">
                <label>{t('reason')}</label>
                <SelectDropdown
                  name="reason"
                  className={`${styles['select']} ${
                    errors.reason && touched.reason ? 'error' : ''
                  }`}
                  options={lostLeadOptions}
                  placeholder={t('selectPlaceholder')}
                  onChange={(val) => {
                    setValues({
                      ...initialValues, // Reset to initial values
                      reason: Number(val?.value),
                    });
                  }}
                  selectedOption={lostLeadOptions.find((option) => {
                    return option.value === values.reason;
                  })}
                  isClearable
                />

                <ErrorMessage
                  name="reason"
                  component="div"
                  className="form__error"
                />
              </div>
              <div className="form__input">
                <label htmlFor="description">{t('description')}</label>
                <Field
                  id="description"
                  name="description"
                  as="textarea"
                  className={styles['textarea']}
                  placeholder={t('lostLeadDesc')}
                />

                <ErrorMessage
                  name="description"
                  component="div"
                  className="form__error"
                />
              </div>
              <ConditionalFields
                setFieldValue={setFieldValue}
                paymentTerms={
                  values.reason === LostLeadOptionsValues.PAYMENT_TERMS
                }
                competition={
                  values.reason === LostLeadOptionsValues.COMPETITION
                }
                priceConcern={
                  values.reason === LostLeadOptionsValues.PRICE_CONCERN
                }
                productConcern={
                  values.reason === LostLeadOptionsValues.PRODUCT_CONCERN
                }
                others={values.reason === LostLeadOptionsValues.OTHERS}
                values={values}
                errors={errors}
                touched={touched}
              />
              <div className={styles['btns-wrapper']}>
                <button
                  type="button"
                  onClick={() => setShowModal(true)}
                  className={styles['disqualify-btn']}
                  disabled={pendingSubmission || !isValid || !values.reason}
                >
                  {t('markPipelineAsLost')}
                </button>
                <button
                  type="button"
                  className={styles['cancel-btn']}
                  onClick={() =>
                    navigate(
                      ROUTES['LeadPipelines'].getPath?.(leadId, countryId)
                    )
                  }
                >
                  {t('cancel')}
                </button>
              </div>
              <Modal
                broker
                textOnlyHeader
                show={showModal}
                onClose={handleCloseModal}
                title={t('markPipelineAsLostConfirmation')}
                customMaxWidth={500}
              >
                <p className={styles['confirmation-text']}>
                  {t('markPipelineLostDesc')}
                </p>
                <div className={styles['modal-btns']}>
                  <LoadingButton
                    type="submit"
                    onClick={() => handleSubmit(values)}
                    loading={pendingSubmission}
                    className={styles['disqualify-btn']}
                    disabled={pendingSubmission || !isValid}
                  >
                    {t('markPipelineAsLost')}
                  </LoadingButton>
                  <button
                    type="button"
                    className={styles['cancel-btn']}
                    onClick={() => handleCloseModal()}
                  >
                    {t('keepPipeline')}
                  </button>
                </div>
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default LostPipeline;
