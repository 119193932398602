import { Link, useNavigate } from 'react-router-dom';
import { useUserPipelineContext } from '../../context/user-pipeline-context';
import styles from './close-deal-montenegro.module.scss';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../api/routes';
import { LoadingButton } from '@orascom/common-components';
import { useContext, useState } from 'react';
import { ErrorMessage, Form, Formik } from 'formik';
import * as yup from 'yup';
import { Deal as DealUtils } from '../../utils/deal.utils';
import { COUNTRY_SELECTED_CONTEXT } from '../../context/country-selected-context';
import { isValid } from 'date-fns';
import { MontenegroPipelineStageEnum } from '../../definitions/interfaces/deals.interface';

export function CloseDealMontenegro() {
  const { t } = useTranslation();
  const activeCountry = useContext(COUNTRY_SELECTED_CONTEXT);

  const navigate = useNavigate();

  const {
    selectedPipeline,
    customerId,
    leadId,
    refetchPipelines,
    setPipelines,
  } = useUserPipelineContext();
  const [isUpdating, setIsUpdating] = useState(false);

  const submitHandler = (values: { paymentTerms: boolean }) => {
    setIsUpdating(true);
    DealUtils.updateDealPipeline(
      selectedPipeline?.pipeline_source_id ?? '',
      {
        payments_agreement: values.paymentTerms,
        lead_source_id: leadId ?? '',
        feedback_id: selectedPipeline?.feedback_id,
        customer_source_id: customerId ?? '',
      },
      activeCountry.activeCountry?.value.toString() ?? ''
    )
      .then(() => {
        return refetchPipelines();
      })
      .then((res) => {
        if (res) setPipelines(res);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsUpdating(false));
  };

  return (
    <div className={styles['close-deal']}>
      <p>{t('closeDeal')}</p>
      <Formik
        initialValues={{
          paymentTerms: selectedPipeline?.payments_agreement ?? false,
        }}
        validationSchema={yup.object({
          paymentTerms: yup
            .bool()
            .oneOf([true], t('paymentTermsMustBeChecked'))
            .label(t('paymentTerms')),
        })}
        onSubmit={submitHandler}
      >
        {({ values, handleBlur, handleChange, resetForm }) => {
          return (
            <Form className={styles['form']}>
              <div className={styles['payment-terms']}>
                {selectedPipeline?.current_phase ===
                  MontenegroPipelineStageEnum.FOURTH_STAGE && (
                  <div className={styles['checkbox-wrapper']}>
                    <input
                      id="paymentTerms"
                      name="paymentTerms"
                      type="checkbox"
                      onChange={(e) => {
                        handleChange(e);
                        const isChecked = e.target.checked;
                        if (!isChecked) {
                          resetForm();
                        }
                      }}
                      onBlur={handleBlur}
                      value={values.paymentTerms ? 'true' : 'false'}
                    />
                    <label htmlFor="paymentTerms">
                      {t('paymentTermsAgreedUpon')}
                    </label>
                  </div>
                )}
                <ErrorMessage
                  name="paymentTerms"
                  component="div"
                  className="form__error"
                />
              </div>

              {selectedPipeline?.current_phase ===
              MontenegroPipelineStageEnum.LAST_STAGE ? (
                customerId ? (
                  <LoadingButton
                    className="btn btn--navy"
                    style={{ marginBlockStart: '16px' }}
                    type="button"
                    onClick={() => {
                      navigate(
                        ROUTES['CreateSale'].getPath?.(
                          customerId,
                          selectedPipeline?.pipeline_source_id,
                          activeCountry.activeCountry?.value
                        )
                      );
                    }}
                  >
                    {t('createSale')}
                  </LoadingButton>
                ) : (
                  <Link
                    className={`btn btn--navy ${styles['cta-link']}`}
                    to={ROUTES['QualifyCustomer'].getPath?.(
                      activeCountry.activeCountry?.value,
                      leadId
                    )}
                    state={{
                      customerId,
                      pipelineSourceId: selectedPipeline.pipeline_source_id,
                      leadId,
                    }}
                  >
                    {t('qualifyCustomer')}
                  </Link>
                )
              ) : (
                <LoadingButton
                  className="btn btn--navy"
                  type="submit"
                  loading={isUpdating}
                  loadingText={t('updatingPipeline..')}
                  spinnerSize="sm"
                  disabled={!isValid}
                >
                  {t('updatePipeline')}
                </LoadingButton>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default CloseDealMontenegro;
