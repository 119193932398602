import * as React from 'react';
import { SalesManagerInterface } from '../definitions/interfaces/user';

/**
 * user context that holds under user creation.
 */

export const USER_CONTEXT: React.Context<{
  /**
   * created user from BE.
   */
  user?: SalesManagerInterface | null;

  /**
   *for refetching user
   *
   * @type {(user: SalesManagerInterface | null) => void}
   */
  setUser?: (user: SalesManagerInterface | null) => void;

  /**
   * a boolean which is true if the user is loading.
   */
  isLoading?: boolean;
}> = React.createContext({});
