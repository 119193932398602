import { UnitInterface } from '@orascom/api-interfaces';
import {
  CompareButton,
  UnitsListing,
} from '@orascom/broker-sales-man-common-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Unit as UnitUtils } from '../../utils/unit';
import { Loader } from '@orascom/common-components';
import styles from './new-properties.module.scss';
import { ROUTES } from '../../api/routes';
export function NewProperties() {
  const [newProperties, setNewProperties] = useState<UnitInterface[]>([]);
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();

  const params = new URLSearchParams({
    sort_by: 'created_at',
    direction: 'desc',
  });

  useEffect(() => {
    UnitUtils.getSalesManUnits(1, 20, params)
      .then((res) => {
        setNewProperties(res.data);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className={styles['wrapper']}>
      <h1 className={styles['title']}>{t('newProperties')}</h1>

      {loading && <Loader />}
      {Boolean(newProperties?.length) && !loading && (
        <>
          <div className={styles['total-count']}>
            {newProperties?.length} {t('newProperty')}
          </div>

          <UnitsListing
            id="units-listing"
            units={newProperties}
            unitDetailsPath={ROUTES['UnitDetails'].getPath}
            addSavedUnit={UnitUtils.addSavedUnit}
            deleteSavedUnit={UnitUtils.deleteSavedUnit}
            portal="broker"
          />
        </>
      )}
      {!newProperties?.length && !loading && <h1>{t('noUnitsToDisplay')}</h1>}
      <CompareButton comparePropertiesPath={ROUTES['CompareProperties'].path} />
    </div>
  );
}

export default NewProperties;
