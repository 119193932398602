import { PriceValue, UnitInterface } from '@orascom/api-interfaces';
import { UnitsListing } from '@orascom/broker-sales-man-common-components';
import { Loader, UnitsFilters } from '@orascom/common-components';
import {
  useCurrencyContext,
  useCurrencyConverter,
  usePriceSlider,
  useUnitFilters,
  useUnits,
} from '@orascom/utils';
import { ROUTES } from '../../api/routes';
import { Unit as UnitUtils } from '../../utils/unit';
import { Filter as FilterUtils } from '../../utils/filter';
import styles from './select-sale-unit.module.scss';
import { useTranslation } from 'react-i18next';
import { useCreateSaleContext } from '../../pages/create-sale/create-sale-context';
import { Dispatch, SetStateAction } from 'react';
import { useParams } from 'react-router-dom';

export function SelectSaleUnit() {
  const params = useParams();
  const countrySlug = params?.['countryId'];

  const { goNext, setSelectedUnit } = useCreateSaleContext();
  const { t } = useTranslation();

  const { filters, isLoading: isLoadingFilters } = useUnitFilters({
    getUnitFilters: () => {
      const searchParams = new URLSearchParams();

      if (countrySlug) {
        searchParams.set('countries[0]', countrySlug);
      }

      return FilterUtils.getSalesManUnitFilters(searchParams);
    },
  });
  const { priceSlider, setPriceSlider } = usePriceSlider(filters);

  function setPriceValue(priceValue: PriceValue) {
    setPriceSlider({
      ...priceSlider,
      price: priceValue,
    });
  }
  const { selectedCurrency, convertPrice } = useCurrencyConverter();

  const { isLoading: isLoadingCurrency } = useCurrencyContext();

  const unitsData = useUnits({
    getUnits: (page, perPage, searchParams) =>
      UnitUtils.getSalesManUnits(page, perPage, searchParams, countrySlug),
    selectedCurrency,
    convertPrice,
  });

  const { units, loadMoreUnits, paginationData, hasMoreUnits } = unitsData;
  const { isLoadingUnits, isLoadingMoreUnits } = unitsData;

  const unitSelected: Dispatch<SetStateAction<UnitInterface | undefined>> = (
    unit
  ) => {
    setSelectedUnit(unit);
    const url = new URL(window.location.href);
    url.search = '';
    window.history.replaceState({}, '', url.toString());
    goNext();
  };

  const isLoading = isLoadingUnits || isLoadingMoreUnits;
  return (
    <>
      <UnitsFilters
        portal="sales-man"
        showResale={false}
        unitFilters={filters}
        isLoadingFilters={isLoadingFilters}
        priceSlider={priceSlider}
        setPriceValue={setPriceValue}
        isLoadingCurrency={isLoadingCurrency}
        activeCountry={countrySlug}
        isCreateSale
      />
      {isLoading ? (
        <div className={styles['loader-wrapper']}>
          <Loader />
        </div>
      ) : (
        <UnitsListing
          portal={'sales-man'}
          listingView={'grid'}
          id="units-listing"
          units={units}
          unitDetailsPath={ROUTES['UnitDetails'].getPath}
          addSavedUnit={UnitUtils.addSavedUnit}
          deleteSavedUnit={UnitUtils.deleteSavedUnit}
          hideCompare
          selectUnit
          setSelectedUnit={unitSelected}
        />
      )}

      {Boolean(units.length) && !isLoadingUnits && (
        <div className={styles['pagination']}>
          <div className={styles['info']}>
            <hr className={styles['line']} />
            <div className={styles['count']}>
              {t('showing')} {units.length} {t('unitsOutOf')}{' '}
              {paginationData?.total}
            </div>
          </div>
          {hasMoreUnits && (
            <div className={styles['button']}>
              <button
                className="btn btn--navy"
                onClick={loadMoreUnits}
                disabled={isLoading}
              >
                {t('loadMore')}
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
}
export default SelectSaleUnit;
