import { Loader } from '@orascom/common-components';
// eslint-disable-next-line @nx/enforce-module-boundaries
import styles from 'libs/broker-sales-man-common-components/src/styles/new-deal.module.scss';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/style.css';
import { Link, useParams } from 'react-router-dom';
import { ROUTES } from '../../api/routes';

import arrowIcon from '../../assets/icons/left-arrow.svg';
import newDealImg from '../../assets/images/new-deal.webp';
import { useContext, useEffect, useState } from 'react';
import NewDealEgypt from './new-deal-egypt';
import { COUNTRY_SELECTED_CONTEXT } from '../../context/country-selected-context';
import NewDealMontenegro from './new-deal-montenegro';
import { SalesManagerCountries } from '../../utils/country-selection';
import { UnitDetails } from '@orascom/api-interfaces';
import { Unit as UnitUtils } from '../../utils/unit';

export function NewDeal() {
  const { t } = useTranslation();
  const activeCountry = useContext(COUNTRY_SELECTED_CONTEXT);

  const { unitId } = useParams();
  const [unit, setUnit] = useState<UnitDetails>();
  const [isLoadingUnit, setIsLoadingUnit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (Number(unitId)) {
      setIsLoadingUnit(true);
      UnitUtils.getSalesManUnitDetails(Number(unitId))
        .then((res) => setUnit(res))
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setIsLoadingUnit(false));
    }
  }, [unitId]);

  if (isSubmitting || isLoadingUnit) {
    return (
      <div className={styles['loader-wrapper']}>
        <Loader />
      </div>
    );
  }
  return (
    <div className={styles['wrapper']}>
      <Link to={ROUTES['MyDeals'].path} className={styles['back-anchor']}>
        <img src={arrowIcon} alt="" role="presentation" />
        <span className="anchor">{t('back')}</span>
      </Link>
      <h1 className={styles['title']}>{t('newLead')}</h1>
      <div className={styles['grid']}>
        {activeCountry.activeCountry?.value === SalesManagerCountries.EGYPT ? (
          <NewDealEgypt
            setIsSubmitting={setIsSubmitting}
            unit={unit}
            unitId={unitId}
          />
        ) : (
          <NewDealMontenegro
            setIsSubmitting={setIsSubmitting}
            unit={unit}
            unitId={unitId}
          />
        )}
        <img
          src={newDealImg}
          alt="new-deal"
          className={styles['image']}
          loading="lazy"
        />
      </div>
    </div>
  );
}

export default NewDeal;
