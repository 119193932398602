import { Destination as IDestination } from '@orascom/api-interfaces';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Destination } from '../utils/destination.utils';

const useDestinations = (activeCountry?: string) => {
  const [destinations, setDestinations] = useState<IDestination[]>([]);
  const [pendingDestinations, setPendingDestinations] = useState(true);
  const walkthroughLocalstorageCheck =
    localStorage.getItem('walkthrough_status') === 'running';

  const destinationsOptions = useMemo(
    () =>
      destinations.map((destination) => ({
        label: destination.name,
        value: destination.slug,
      })),
    [destinations]
  );

  useEffect(() => {
    Destination.listSalesManDestinations(activeCountry?.toString())
      .then((res) => setDestinations(res))
      .catch(() => {
        if (!walkthroughLocalstorageCheck) {
          toast.error('Failed to fetch destinations');
        }
      })
      .finally(() => setPendingDestinations(false));
  }, []);

  return {
    destinations,
    destinationsOptions,
    pendingDestinations,
  };
};

export default useDestinations;
