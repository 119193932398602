import { Loader } from '@orascom/common-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../api/routes';
import arrowIcon from '../../assets/icons/left-arrow.svg';
import PipelineDetails from '../../components/pipeline-details/pipeline-details';
import styles from './customer-details.module.scss';
import {
  UserPipelineProvider,
  useUserPipelineContext,
} from '../../context/user-pipeline-context';
import { useContext } from 'react';
import { COUNTRY_SELECTED_CONTEXT } from '../../context/country-selected-context';
import { useSetActiveCountryFromParams } from '../../hooks/use-active-country-disabling';
import PipelineDetailsMontenegro from '../../components/pipeline-details/pipeline-details-montenegro';
import { SalesManagerCountries } from '../../utils/country-selection';

export function CustomerDetails() {
  const { t } = useTranslation();

  const { pendingCustomerData } = useUserPipelineContext();

  const activeCountry = useContext(COUNTRY_SELECTED_CONTEXT);
  useSetActiveCountryFromParams(activeCountry);

  if (pendingCustomerData) {
    return (
      <div className={styles['loader-wrapper']}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles['wrapper']}>
      <Link to={ROUTES['MyCustomers'].path} className={styles['back-anchor']}>
        <img src={arrowIcon} alt="" role="presentation" />
        <span className="anchor">{t('back')}</span>
      </Link>
      <h1 className={styles['title']}>{t('customerDetails')}</h1>

      {activeCountry.activeCountry?.value === SalesManagerCountries.EGYPT ? (
        <PipelineDetails page="Customer" />
      ) : (
        <PipelineDetailsMontenegro page="Customer" />
      )}
    </div>
  );
}

const CustomerDetailsPage = () => {
  return (
    <UserPipelineProvider>
      <CustomerDetails />
    </UserPipelineProvider>
  );
};

export default CustomerDetailsPage;
