import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './my-customers.module.scss';
import arrowIcon from '../../assets/icons/left-arrow.svg';
import { ROUTES } from '../../api/routes';
import { format } from 'date-fns';
import { PaginationStyled } from '@orascom/broker-sales-man-common-components';
import { Customer } from '../../utils/customer';
import { Customers as CustomersInterface } from '../../definitions/interfaces/customers.interface';
import { Loader, debounce } from '@orascom/common-components';
import searchIcon from '../../assets/icons/search.svg';
import { COUNTRY_SELECTED_CONTEXT } from '../../context/country-selected-context';
import TableComponent, { TableColumn } from '../../components/table/table';

export function MyCustomers() {
  const { t } = useTranslation();

  const activeCountry = useContext(COUNTRY_SELECTED_CONTEXT);

  const [customersData, setCustomersData] = useState<CustomersInterface[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = customersData.slice(indexOfFirstItem, indexOfLastItem);
  const [loading, setLoading] = useState(true);
  const [numberSearched, setNumberSearched] = useState('');

  const searchDeals = (value: string) => {
    if (!value.length) {
      setLoading(true);
      setNumberSearched('');
    }
    setLoading(true);
    setNumberSearched(value);
  };
  const debouncedOnChange = debounce(searchDeals, 1000);
  const viewNumber = 1; // 1 = customer leads to be fetched from the backend

  const columns: TableColumn<CustomersInterface>[] = [
    {
      label: 'fullName',
      renderColumn: (label) => (
        <div className={styles['header-filter']}>{t(label)}</div>
      ),
      renderRow: (row) => <b>{row.name ? row.name : '-'}</b>,
    },
    {
      label: 'phoneNumber',
      renderColumn: (label) => (
        <div className={styles['header-filter']}>{t(label)}</div>
      ),
      renderRow: (row) => (row.phone ? row.phone : '-'),
    },
    {
      label: 'email',
      renderColumn: (label) => (
        <div className={styles['header-filter']}>{t(label)}</div>
      ),
      renderRow: (row) => (row.email ? row.email : '-'),
    },
    {
      label: 'modifiedOn',
      renderColumn: (label) => (
        <div className={styles['header-filter']}>{t(label)}</div>
      ),
      renderRow: (row) => {
        let formattedDate = '-';
        if (row.source_updated_at) {
          const date = new Date(row.source_updated_at);
          formattedDate = format(date, 'dd MMMM yyyy');
        }
        return formattedDate;
      },
    },
    {
      label: 'ownerName',
      renderColumn: (label) => (
        <div className={styles['header-filter']}>{t(label)}</div>
      ),
      renderRow: (row) => (row.owner_name ? row.owner_name : '-'),
    },
    {
      label: 'actions',
      renderColumn: (label) => (
        <div className={styles['header-filter']}>{t(label)}</div>
      ),
      renderRow: (row) => (
        <div className={styles['last-column']}>
          <Link
            to={ROUTES['CustomerCreatePipelines'].getPath?.(
              row.customer_source_id,
              activeCountry.activeCountry?.value
            )}
            state={{
              newPipeline: true,
            }}
          >
            + {t('createSale')}
          </Link>
          <Link
            to={ROUTES['CustomerPipelines'].getPath?.(
              row.customer_source_id,
              activeCountry.activeCountry?.value
            )}
          >
            <span className="anchor">{t('customerDetails')}</span>{' '}
            <img src={arrowIcon} alt="arrow" />
          </Link>
        </div>
      ),
    },
  ];

  useEffect(() => {
    activeCountry.setDisableCountrySelection?.(true);
    setLoading(true);
    Customer.getCustomers(
      viewNumber,
      numberSearched,
      activeCountry.activeCountry?.value.toString() ?? ''
    )
      .then((res) => {
        setCustomersData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        activeCountry.setDisableCountrySelection?.(false);
      });
    return () => activeCountry.setDisableCountrySelection?.(false);
  }, [numberSearched, activeCountry.activeCountry?.value]);

  return (
    <div className={styles['wrapper']}>
      <h1 className={styles['title']}>{t('myCustomers')}</h1>
      <div className={styles['search-input']}>
        <img src={searchIcon} alt="" role="presentation" />
        <input
          type="text"
          placeholder={t('searchLead')}
          onChange={(e) => debouncedOnChange(e.target.value)}
        />
      </div>
      <div className={loading ? '' : styles['table']}>
        {loading ? (
          <div className={styles['loader-wrapper']}>
            <Loader />
          </div>
        ) : currentItems.length ? (
          <TableComponent columns={columns} data={currentItems} />
        ) : (
          <p>{t('noCustomersToDisplay')}</p>
        )}
      </div>
      {!loading && currentItems.length ? (
        <div className={styles['pagination-info']}>
          <p className={styles['count']}>
            {t('showing')}{' '}
            <span>
              {' '}
              {indexOfFirstItem + 1} -{' '}
              {indexOfLastItem > customersData.length
                ? customersData.length
                : indexOfLastItem}{' '}
            </span>{' '}
            {t('outOf')} <span>{customersData.length} </span>
            {t('customers')}
          </p>

          <PaginationStyled
            currentPage={currentPage}
            totalCount={customersData.length}
            pageSize={itemsPerPage}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        </div>
      ) : null}
    </div>
  );
}

export default MyCustomers;
