import { useState } from 'react';
import {
  DateFilter,
  DateFilters,
  DealInterface,
} from '../definitions/interfaces/deals.interface';

export const useDealFiltering = (deals: DealInterface[]) => {
  const [filters, setFilters] = useState<Record<string, string[]>>({});
  const [dateFilters, setDateFilters] = useState<DateFilters>({});
  const [appliedSort, setAppliedSort] = useState<null | 'desc' | 'asc'>(null);

  const handleFilterChange = (column: string, selectedValues: string[]) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [column]: selectedValues,
    }));
  };

  const resetDateFilters = () => {
    setDateFilters({
      engagementDate: { startDate: null, endDate: null },
      leadExpirationDate: { startDate: null, endDate: null },
      createdOn: { startDate: null, endDate: null },
      followupDate: { startDate: null, endDate: null },
    });
  };

  const isDateInRange = (date: Date | null, range?: DateFilter): boolean => {
    if (!range) {
      return true;
    }
    if (!date || date < range.startDate! || date > range.endDate!) {
      return false;
    } else {
      return true;
    }
  };

  const filteredDeals = deals
    ?.filter((deal) =>
      Object.keys(filters).every((key) =>
        filters[key].length === 0
          ? true
          : filters[key].some(
              (filterValue) => String(deal[key]) === filterValue
            )
      )
    )
    .filter((deal) => {
      if (
        dateFilters.engagementDate?.startDate &&
        dateFilters.engagementDate?.endDate
      ) {
        const engagementDate = deal.last_engagement_date_timestamp
          ? new Date(deal.last_engagement_date_timestamp * 1000)
          : null;

        return isDateInRange(engagementDate, dateFilters.engagementDate);
      }

      if (
        dateFilters.leadExpirationDate?.startDate &&
        dateFilters.leadExpirationDate?.endDate
      ) {
        const leadExpirationDate =
          deal.lead_expiration_date && deal.lead_expiration_date_timestamp
            ? new Date(deal.lead_expiration_date_timestamp * 1000)
            : null;
        return isDateInRange(
          leadExpirationDate,
          dateFilters.leadExpirationDate
        );
      }

      if (
        dateFilters.followupDate?.startDate &&
        dateFilters.followupDate?.endDate
      ) {
        const followupDate = deal.followup_date_timestamp
          ? new Date(deal.followup_date_timestamp * 1000)
          : null;

        return isDateInRange(followupDate, dateFilters.followupDate);
      }

      return true;
    })
    .sort((a, b) => {
      if (!appliedSort) return 0;
      const key = 'last_engagement_date_timestamp';
      const valueA = a[key] ?? 0;
      const valueB = b[key] ?? 0;

      if (appliedSort === 'asc') {
        return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
      } else {
        return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
      }
    });

  return {
    filters,
    setFilters,
    dateFilters,
    appliedSort,
    handleFilterChange,
    resetDateFilters,
    filteredDeals,
    setAppliedSort,
    setDateFilters,
  };
};
