import Joyride, {
  CallBackProps,
  Step,
  TooltipRenderProps,
} from 'react-joyride';

interface Props {
  steps: Step[];
  startWalkthrough: boolean;
  callBackFunction?: (data: CallBackProps) => void;
  disableScrolling?: boolean;
  spotlightClicks?: boolean;
  lastBtnMsg?: string;
  showProgress?: boolean;
  nextLabelWithProgress?: string;
  stepIndex?: number;
  customTooltip?: ({
    continuous,
    index,
    step,
    backProps,
    primaryProps,
    closeProps,
    skipProps,
  }: TooltipRenderProps) => JSX.Element;
}

export function WalkthroughComponent({
  steps,
  stepIndex,
  startWalkthrough,
  callBackFunction,
  disableScrolling,
  spotlightClicks,
  showProgress,
  nextLabelWithProgress,
  customTooltip,
  lastBtnMsg,
}: Props) {
  return (
    <Joyride
      callback={callBackFunction}
      run={startWalkthrough}
      steps={steps}
      stepIndex={stepIndex ?? undefined}
      showSkipButton
      spotlightClicks={spotlightClicks ?? false}
      disableScrolling={disableScrolling ?? true}
      disableOverlayClose
      continuous
      showProgress={showProgress ?? false}
      tooltipComponent={customTooltip}
      locale={{
        last: lastBtnMsg ?? 'end walkthrough',
        nextLabelWithProgress: nextLabelWithProgress ?? 'next ({step}/{steps})',
      }}
      styles={{
        options: {
          backgroundColor: 'var(--white-500)',
          arrowColor: 'var(--white-500)',
          textColor: 'var(--blue-500)',
          primaryColor: 'var(--blue-500)',
        },
        tooltip: {
          borderRadius: '30px',
          padding: '30px',
          minWidth: '555px',
        },
        tooltipTitle: {
          textAlign: 'start',
          fontSize: '16px',
          fontWeight: 600,
        },
        tooltipContent: {
          textAlign: 'start',
          fontSize: '14px',
          fontWeight: 500,
        },
        buttonNext: {
          minWidth: '140px',
          borderRadius: '15px',
          paddingBlock: '10px',
          paddingInline: '24px',
          fontSize: '11px',
          fontWeight: 600,
          letterSpacing: '2px',
          lineHeight: '23px',
          textTransform: 'uppercase',
        },
        buttonBack: {
          minWidth: '140px',
          marginInlineEnd: '16px',
          backgroundColor: 'white',
          borderRadius: '15px',
          paddingBlock: '10px',
          paddingInline: '24px',
          fontSize: '11px',
          fontWeight: 600,
          letterSpacing: '2px',
          lineHeight: '23px',
          textTransform: 'uppercase',
        },
        buttonSkip: {
          fontSize: '11px',
          textDecoration: 'underline',
          textUnderlineOffset: '3px',
          letterSpacing: '2px',
          textTransform: 'uppercase',
        },
        buttonClose: {
          insetInlineEnd: '30px',
          insetBlockStart: '35px',
          width: '24px',
          height: '24px',
          padding: 0,
        },
        spotlight: { borderRadius: '15px' },
      }}
    />
  );
}
