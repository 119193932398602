import { UnitInterface } from '@orascom/api-interfaces';
import { LoadingButton } from '@orascom/common-components';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { UpdateMontenegroPipeline } from '../../definitions/interfaces/deals.interface';
import styles from './choose-units.module.scss';
import { Unit } from '../../utils/unit';
import { PropertyCard } from '@orascom/broker-sales-man-common-components';
import { ROUTES } from '../../api/routes';
import { ChooseUnitsInterface } from '../../definitions/interfaces/montenegro-lead-stages.interface';
import PropertyListDropdown from './property-list-dropdown';
import { useUserPipelineContext } from '../../context/user-pipeline-context';
import { Deal } from '../../utils/deal.utils';
import { COUNTRY_SELECTED_CONTEXT } from '../../context/country-selected-context';
import { toast } from 'react-toastify';

export function ChooseUnits() {
  const { t } = useTranslation();
  const {
    selectedPipeline,
    setPipelines,
    refetchPipelines,
    customerId,
    leadId,
  } = useUserPipelineContext();
  const [calledUnits, setCalledUnits] = useState<UnitInterface[]>();
  const [addUnits, setAddUnits] = useState(true);

  const desiredUnits = calledUnits?.map((unit: UnitInterface) => ({
    label: unit.name,
    value: unit.id,
  }));

  const [selectedUnits, setSelectedUnits] = useState<UnitInterface[]>([]);

  const initialValues: ChooseUnitsInterface = {
    desiredUnit: null,
    expressionOfInterestedPaid: 'yes',
    isDesiredUnit: 'yes',
  };

  const handleAddSelectedUnits = (selectedId: number) => {
    const filteredUnit = calledUnits?.find((unit) => unit.id === selectedId);
    if (!filteredUnit) return;
    setSelectedUnits((prev) => [...prev, filteredUnit]);
  };

  // TODO uncomment when remove btn is integrated by CRM
  // const handleRemoveSelectedUnit = (selectedId: number) => {
  //   setSelectedUnits((prev) => prev.filter((unit) => unit.id !== selectedId));
  // };

  // const { dealId, customerId } = useParams();

  const activeCountry = useContext(COUNTRY_SELECTED_CONTEXT);

  const [loadingUnits, setLoadingUnits] = useState(true);
  const [choosingUnit, setChoosingUnit] = useState(false);
  const [fetchingPipelines, setFetchingPipelines] = useState(false);

  const [searchedInput, setSearchedInput] = useState('');

  const hasUnits = selectedUnits.length > 0;

  useEffect(() => {
    const params = new URLSearchParams();
    if (searchedInput.length === 0) {
      Unit.getSalesManUnits(
        1,
        100,
        params,
        String(activeCountry.activeCountry?.label).toLowerCase()
      )
        .then((res) => {
          setCalledUnits(res.data);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoadingUnits(false));
    }
  }, [searchedInput]);

  useEffect(() => {
    const params = new URLSearchParams();
    if (searchedInput.length >= 2) {
      if (params.size > 0) {
        Unit.getSalesManUnits(1, 100, params)
          .then((res) => {
            setCalledUnits(res.data);
          })
          .catch((err) => console.log(err))
          .finally(() => setLoadingUnits(false));
      }
    }
  }, [searchedInput]);

  const schema = yup.object({
    desiredUnit: yup.object().nullable().required(t('required')),
  });

  const chooseUnitsHandler = (selectedId: number) => {
    if (!selectedPipeline) return;
    setChoosingUnit(true);

    const data: UpdateMontenegroPipeline = {
      customer_source_id: customerId ?? '',
      lead_source_id: leadId ?? '',
      unit_id: selectedId ?? '',
      feedback_id: selectedPipeline?.feedback_id,
    };

    Deal.updateDealPipeline(
      selectedPipeline.pipeline_source_id,
      data,
      activeCountry.activeCountry?.value.toString() ?? ''
    )
      .then(() => {
        setChoosingUnit(false);
        setFetchingPipelines(true);
        return refetchPipelines();
      })
      .then((res) => {
        if (res) setPipelines(res);
        setFetchingPipelines(false);
        toast.success('Pipeline updated successfully');
      })
      .catch(() => {
        toast.error('Error closing pipeline');
      })
      .finally(() => {
        setChoosingUnit(false);
        setFetchingPipelines(false);
      });
  };

  const submitHandler = (values: ChooseUnitsInterface) => {
    if (!selectedPipeline) return;
    setChoosingUnit(true);

    const data: UpdateMontenegroPipeline = {
      customer_source_id: customerId ?? '',
      lead_source_id: leadId ?? '',
      feedback_id: selectedPipeline?.feedback_id,
      is_desired_unit_selected:
        selectedPipeline.pipeline_type === 'Single Unit'
          ? true
          : values.isDesiredUnit === 'yes',
      is_experssion_of_interest: values.expressionOfInterestedPaid === 'yes',
    };

    Deal.updateDealPipeline(
      selectedPipeline.pipeline_source_id,
      data,
      activeCountry.activeCountry?.value.toString() ?? ''
    )
      .then(() => {
        setChoosingUnit(false);
        setFetchingPipelines(true);
        return refetchPipelines();
      })
      .then((res) => {
        if (res) setPipelines(res);
        setFetchingPipelines(false);
        toast.success('Pipeline updated successfully');
      })
      .catch(() => {
        toast.error('Error closing pipeline');
      })
      .finally(() => {
        setChoosingUnit(false);
        setFetchingPipelines(false);
      });
  };

  return (
    <div className={styles['choose-units']}>
      <p className={styles['title']}>{t('montenegroChooseUnitTitle')}</p>
      <p className={styles['description']}>
        {t('montenegroChooseUnitDescription')}
      </p>

      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={submitHandler}
      >
        {({ values }) => {
          return (
            <Form className={styles['form']}>
              {hasUnits ? (
                selectedUnits.map((unit) => (
                  <PropertyCard
                    key={unit.id}
                    unit={unit}
                    // TODO add remove function when remove btn is integrated by CRM
                    handleRemove={() => {}}
                    listingView="list"
                    unitDetailsPath={ROUTES['UnitDetails'].getPath}
                    isInCreateSale
                    pipelineImg
                    // TODO uncomment when remove btn is integrated by CRM
                    // showRemoveBtn
                    hideCompare
                    hideSaveUnit
                    hideViewBtnOverlay
                  />
                ))
              ) : (
                <PropertyListDropdown
                  desiredUnits={desiredUnits}
                  handleAddSelectedUnits={handleAddSelectedUnits}
                  loadingUnits={loadingUnits}
                  setSearchedInput={setSearchedInput}
                  chooseUnitsHandler={chooseUnitsHandler}
                />
              )}

              <div className={styles['unit-quantity']}>
                <label>{t('numberOfUnitsAdded')}</label>
                <p>{selectedUnits.length}</p>
              </div>

              <div
                role="group"
                className={`input-wrapper ${styles['radio-buttons--small']}`}
              >
                <label htmlFor="expressionOfInterestedPaid">
                  {t('expressionOfInterestedPaid')}
                </label>
                <div>
                  <label>
                    <div>
                      <p>{t('yes')}</p>
                      <Field
                        type="radio"
                        name="expressionOfInterestedPaid"
                        value={'yes'}
                      />
                    </div>
                  </label>
                  <label>
                    <div>
                      <p>{t('no')}</p>
                      <Field
                        type="radio"
                        name="expressionOfInterestedPaid"
                        value={'no'}
                      />
                    </div>
                  </label>
                </div>
                <ErrorMessage
                  name="expressionOfInterestedPaid"
                  component="div"
                  className="form__error"
                />
              </div>

              {selectedPipeline?.pipeline_type === 'Multi Units' && (
                <div
                  role="group"
                  className={`input-wrapper ${styles['radio-buttons--small']}`}
                >
                  <label htmlFor="isDesiredUnit">{t('isDesiredUnit')}</label>
                  <div>
                    <label>
                      <div>
                        <p>{t('yes')}</p>
                        <Field
                          type="radio"
                          name="isDesiredUnit"
                          value={'yes'}
                        />
                      </div>
                    </label>
                    <label>
                      <div>
                        <p>{t('no')}</p>
                        <Field type="radio" name="isDesiredUnit" value={'no'} />
                      </div>
                    </label>
                  </div>
                  <ErrorMessage
                    name="isDesiredUnit"
                    component="div"
                    className="form__error"
                  />
                </div>
              )}

              {hasUnits &&
                selectedPipeline?.pipeline_type === 'Multi Units' &&
                (addUnits ? (
                  <button
                    onClick={() => setAddUnits(false)}
                    disabled={choosingUnit}
                    className={styles['add-another-unit']}
                    type="button"
                  >
                    + {t('addAnotherUnit')}
                  </button>
                ) : (
                  <PropertyListDropdown
                    desiredUnits={desiredUnits}
                    handleAddSelectedUnits={handleAddSelectedUnits}
                    loadingUnits={loadingUnits}
                    setSearchedInput={setSearchedInput}
                    setAddUnits={setAddUnits}
                    chooseUnitsHandler={chooseUnitsHandler}
                  />
                ))}

              <LoadingButton
                onClick={() => submitHandler(values)}
                loading={choosingUnit || fetchingPipelines}
                disabled={hasUnits ? false : true}
                type="submit"
                loadingText={
                  choosingUnit ? t('choosingUnit..') : t('updatingPipeline..')
                }
                spinnerSize="sm"
                className={`btn btn--navy ${styles['submit-btn']}`}
              >
                {t('continueToFourthStage')}
              </LoadingButton>
              <hr className={styles['line']} />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default ChooseUnits;
