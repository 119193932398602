import { SelectDropdown } from '@orascom/common-components';
import { ErrorMessage, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { LeadDiscoveryInput } from '../../definitions/interfaces/lead-stages.interface';
import styles from './unit-category.module.scss';

export function UnitCategory() {
  const { errors, touched, setFieldValue, values } =
    useFormikContext<LeadDiscoveryInput>();
  const { t } = useTranslation();

  const unitTypesOptions = [
    { label: t('singleFamily'), value: 1 },
    { label: t('multiFamily'), value: 2 },
    // uncomment if requested again by the client
    // { label: t('commercial'), value: 3 },
    // { label: t('fera'), value: 4 },
    // { label: t('land'), value: 5 },
  ];

  const selectedOption = unitTypesOptions.find(
    (option) => option.value === values.unitCategoryId
  );

  return (
    <div className={styles['unit-category']}>
      <p className={styles['title']}>{t('unitCategory')}</p>
      <div className={styles['select-category']}>
        <label htmlFor="unitCategoryId">{t('selectUnitCategory')}</label>
        <SelectDropdown
          name="unitCategoryId"
          options={unitTypesOptions}
          className={`${styles['select']} ${
            errors?.unitCategoryId && touched?.unitCategoryId ? 'error' : ''
          }`}
          placeholder={t('selectUnitCategory')}
          onChange={(value) => setFieldValue('unitCategoryId', value?.value)}
          selectedOption={selectedOption}
        />
        <ErrorMessage
          name="unitCategoryId"
          component="div"
          className="form__error"
        />
      </div>
    </div>
  );
}

export default UnitCategory;
