import { useState, useEffect, useRef, useContext } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  CountriesEnum,
  CURRENCIES_ENUM,
  DESTINATION_SLUG,
  PaginationPayload,
  Payload,
  UnitDetails as UnitDetailsInterface,
  UnitInterface,
  UnitPaymentTerms,
} from '@orascom/api-interfaces';
import {
  CustomTooltip,
  Loader,
  MontenegroUnitPaymentPlan,
  WalkthroughComponent,
  BrokerWalkthroughIds,
} from '@orascom/common-components';
import arrowIcon from '../../assets/icons/left-arrow.svg';
import styles from './unit-details.module.scss';
import { USER_CONTEXT } from '@orascom/broker-sales-man-common-components';
import ImageGallery from '../image-gallery/image-gallery';
import UnitInfo from '../unit-info/unit-info';
import UnitPlan from '../unit-plan/unit-plan';
import UnitPaymentPlan from '../unit-payment-plan/unit-payment-plan';
import UnitSpecs from '../unit-specs/unit-specs';
import SimilarUnits from '../similar-units/similar-units';
import { CommonEventParameters } from '@orascom/utils';
import { CurrencyContext } from '@orascom/utils';
import { CallBackProps, Step } from 'react-joyride';

export interface UnitDetailsProps {
  getUnitDetails: (unitId: number) => Promise<UnitDetailsInterface>;
  getUnitPaymentTerms: (unitId: number) => Promise<UnitPaymentTerms[]>;
  primaryUnitsPath: string;
  analyticsBackButtonCustomEvent?: (params: CommonEventParameters) => void;
  portal: 'Broker' | 'Sales Man';
  getSimilarUnits?: (
    unitId: string,
    price: number,
    designType: string
  ) => Promise<PaginationPayload<UnitInterface[]>>;
  similarUnitsGetPath?: Function;
  unitDetailsGetPath?: Function;
  addSavedUnit: (unitId: number) => Promise<Payload<void>>;
  deleteSavedUnit: (unitId: number) => Promise<Payload<void>>;
  downloadOmanSalesOffer?: (unitId: number, unitName: string) => Promise<any>;
  analyticsUnitSalesOfferDownloadCustomEvent?: (
    params: CommonEventParameters
  ) => void;
  analyticsUnitSubmitInterestCustomEvent?: (
    params: CommonEventParameters
  ) => void;
  analyticsUnitBrochureCustomEvent?: (params: CommonEventParameters) => void;
  analyticsUnitSpecsCustomEvent?: (params: CommonEventParameters) => void;
  comparePropertiesPath: string;
  unitDealGetPath?: Function;
}

export function UnitDetails(props: Readonly<UnitDetailsProps>) {
  const staticUnit: UnitDetailsInterface = {
    access_card: { id: 2, image: '' },
    construction_updates: [{ id: 1, title: '', image: '', date: 0 }],
    sale: {
      down_payment: 0,
      after_delivery: 0,
      before_delivery: 0,
      club_fees: 0,
      discount: 0,
      id: 0,
      maintenance_fees: 0,
      period: 0,
    },
    unit_images_pages_on_sales_offer: '',
    is_wishlisted: false,
    video: '',
    video_thumbnail: '',
    id: 141751,
    unit_number: 'N/A',
    name: 'OW/CYA2-21',
    project: {
      delivery_date: 0,
      floor_number: 1,
      max_bedrooms: 5,
      min_bedrooms: 2,
      short_description: 'test',
      unit_types: [{ name: 'single' }, { name: 'duplex' }, { name: 'duplex' }],
      id: 150,
      slug: 'club-yard-zone-2-o-west',
      name: 'CLUB YARD - ZONE 2',
      description: '',
      image: '',
      sales_offer: '',
      brochure: '',
      floorplan_page_on_sales_offer: 0,
      paymentplan_page_on_sales_offer: 0,
      unit_images_pages_on_sales_offer: '',
      masterplan_image_page_on_sales_offer: 0,
      destination: {
        id: 3,
        name: 'Owest',
        slug: 'o-west',
        account_number: '1234567',
        welcome_description:
          'Welcome to O West, Orascom Development\u2019s latest addition to its towns portfolio nestled in 6th of October. O West is set to rise as a beacon of integrated living.',
        masterplan_description:
          'There are various residential units with different sizes to suit all tastes and needs, including standalone villas and O West Orascom apartments.',
        country: {
          id: 1,
          slug: CountriesEnum.EGYPT,
          name: 'Egypt',
        },
        cover_image:
          'https://api.orascom.robustastudio.com/uploads/units/images/141751/f9ca3cbe-088f-452b-be50-3feb8d96e910.jpg',
        brochure:
          'https://api.orascom.robustastudio.com/uploads/destinations/brochures/3/47b173a2-20fe-4aad-bcf3-b66edfd9e72e.png',
      },
    },
    project_phase: {
      id: 33,
      name: 'owset/phase 1',
    },
    unit_type: {
      id: 6,
      name: 'Mansion',
      image: '',
      slug: '',
    },
    cover_image:
      'https://api.orascom.robustastudio.com/uploads/units/images/141751/f9ca3cbe-088f-452b-be50-3feb8d96e910.jpg',
    hover_image:
      'https://api.orascom.robustastudio.com/uploads/units/images/141751/ac19e48b-f5f5-47c5-bbe2-a36655671572.jpg',
    price: 10000000,
    dollar_price: 1000000,
    currency: 'USD',
    bedrooms: 5,
    bathrooms: 10,
    unit_size: 15,
    built_up_area: '20',
    floor_number: 1,
    is_resale: false,
    is_available: true,
    delivery_date: undefined,
    finishing_status: 'finished',
    tags: [],
    facilities: [],
    is_user_saved: true,
    masterplan_image: '',
    design_type: '',
    masterplan_description: '',
    is_commercial: false,
    total_area: '150',
    garden_area: '',
    roof_area: '',
    terrace_area: '',
    net_area: '',
    view: '',
    direction: '',
    construction_status: 'built',
    brochure:
      'https://api.orascom.robustastudio.com/uploads/units/files/141751/e6860b9c-043b-4daf-b20d-0b17ff42edab.pdf',
    account_statement:
      'https://api.orascom.robustastudio.com/uploads/units/files/141751/565ac1de-f6f6-4166-9cc7-b65df982c6f4.pdf',
    live_url: '',
    area_image: '',
    gallery: [
      'https://api.orascom.robustastudio.com/uploads/units/images/141751/d9431dcc-c8e2-4a16-add5-b21404be6e87.png',
      'https://api.orascom.robustastudio.com/uploads/units/images/141751/00153d5e-60db-4de1-9785-f02d989bad49.png',
      'https://api.orascom.robustastudio.com/uploads/units/images/141751/5eefbb9c-8545-409f-9bb7-83e0956f2e53.png',
    ],
    masterplans: [
      {
        total_area: '500',
        id: 222,
        title: 'Ground Floor plan',
        description: '',
        image:
          'https://api.orascom.robustastudio.com/uploads/masterplans/images/222/b181b0f1-72d6-4a1c-8cd2-634d27aa51f3.webp',
        metadata: {
          general: [
            {
              name: '',
              value: '',
              type: '',
              unit: '',
              slug: '',
              is_predefined: false,
            },
          ],
        },
      },
    ],
    addons: [],
  };
  const WalkthroughLocalstoragCheck =
    localStorage.getItem('walkthrough_status');
  const [unit, setUnit] = useState<UnitDetailsInterface>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [paymentTermsError, setPaymentTermsError] = useState(false);
  const [paymentTerms, setPaymentTerms] = useState<UnitPaymentTerms[]>([]);
  const unitSpecsRef = useRef<null | HTMLDivElement>(null);

  const userContext = useContext(USER_CONTEXT);
  const location = useLocation();
  const [selectedPaymentTerms, setSelectedPaymentTerms] =
    useState<UnitPaymentTerms>();

  //    walkthrough logic starts here
  const [runWalkthrough, setRunWalkthrough] = useState(false);

  const joyrideStep: Step[] = [
    {
      data: {
        id: BrokerWalkthroughIds.STEP_4,
        // TODO check why ROUTES doesn't import
        route: '/my-deals',
        nextStep: BrokerWalkthroughIds.STEP_6, //we skipped 5 to avoid the conflict that makes the following step initiating with the current step
      },
      target: `#${BrokerWalkthroughIds.STEP_4}`,
      title: `Explore Details`,
      content: `Explore everything about this unit in one place. View key details, see its exact location on the masterplan, check the floorplan, browse gallery images, and review available payment plan options to find the best fit for your client.`,
      disableBeacon: true,
      placement: `top`,
    },
  ];

  const handleJoyrideCallBack = (data: CallBackProps) => {
    if (data.action === 'skip' || data.action === 'close') {
      localStorage.removeItem('walkthrough_status');
      localStorage.removeItem('walkthrough_step');
      setRunWalkthrough(false);
      navigate('/', { replace: true });
      return;
    }
    if (data.action === 'prev') {
      localStorage.setItem('walkthrough_step', BrokerWalkthroughIds.STEP_3);
      window.dispatchEvent(new Event('walkthrough_update'));
      navigate('/primary-units', { replace: true });
    }
    if (data.action === 'next') {
      localStorage.setItem('walkthrough_step', data.step.data.nextStep);
      window.dispatchEvent(new Event('walkthrough_update'));
      navigate('/my-deals', { replace: true });
    }
  };

  useEffect(() => {
    const storage = localStorage.getItem('walkthrough_step');
    const targetId = BrokerWalkthroughIds.STEP_4;
    let checkInterval: NodeJS.Timeout;

    if (window.innerWidth < 768) {
      setRunWalkthrough(false);
      return;
    }

    if (storage === BrokerWalkthroughIds.STEP_5) {
      const startWalkthrough = () => setRunWalkthrough(true);

      const waitForElementAndStart = () => {
        const target = document.getElementById(targetId);
        const isVisible =
          target && target.offsetHeight > 0 && target.offsetWidth > 0;

        if (isVisible) {
          clearInterval(checkInterval);
          startWalkthrough();
        }
      };

      const runAfterLoad = () => {
        checkInterval = setInterval(waitForElementAndStart, 200);
      };

      if (document.readyState === 'complete') {
        runAfterLoad();
      } else {
        window.addEventListener('load', runAfterLoad);
      }

      return () => {
        clearInterval(checkInterval);
        window.removeEventListener('load', runAfterLoad);
      };
    }
  }, []);

  // walkthrough logic ends here

  const handleSelectPaymentTerms = (paymentTerm: UnitPaymentTerms) => {
    setSelectedPaymentTerms(paymentTerm);
  };
  const {
    isLoading: isLoadingCurrency,
    setDisableCurrencySetting,
    setActiveCurrency,
  } = useContext(CurrencyContext);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { unitId } = useParams();

  useEffect(() => {
    if (WalkthroughLocalstoragCheck === 'running') {
      setUnit(staticUnit);
      setLoading(false);
      return;
    }
    if (isNaN(Number(unitId))) {
      return;
    }
    props
      .getUnitDetails(Number(unitId))
      .then((res) => {
        setUnit(res);
        if (res.project.destination.slug === DESTINATION_SLUG.GOUNA) {
          setActiveCurrency(CURRENCIES_ENUM.USD);
          setDisableCurrencySetting({
            disabled: true,
            info: t(`elGounaCurrencyDisclaimer`),
          });
        }
      })
      .catch(() => setError(true))
      .finally();

    props
      .getUnitPaymentTerms(Number(unitId))
      .then((terms) => {
        setPaymentTerms(terms);
      })
      .catch(() => {
        setPaymentTermsError(true);
      })
      .finally(() => setLoading(false));

    return () =>
      setDisableCurrencySetting({
        disabled: false,
        info: '',
      });
  }, [unitId]);

  const navigateToUnitSpecs = () => {
    unitSpecsRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const unitCountry = unit?.project.destination.country.slug;
  if (error) {
    return <h1 className="empty-unit__placholder">{t('noUnitFound')}</h1>;
  }
  if (loading || isLoadingCurrency) {
    return <Loader />;
  }
  const unitGallery: string[] =
    unit?.gallery && unit?.gallery?.length > 0
      ? unit?.gallery
      : ([
          unit?.cover_image,
          unit?.hover_image,
          unit?.area_image,
          unit?.cover_image,
          unit?.hover_image,
        ] as string[]);

  const montenegroPaymentPlan = paymentTermsError ? (
    <div id="payment-plan" className={`${styles['wrapper']} card`}>
      <h3>No payment plan found for this unit</h3>
    </div>
  ) : (
    <MontenegroUnitPaymentPlan
      unitDetails={unit}
      paymentTerms={paymentTerms}
      handleSelectPaymentTerms={handleSelectPaymentTerms}
      selectPaymentTerms={selectedPaymentTerms}
      portal={props.portal}
    />
  );

  return (
    <>
      <WalkthroughComponent
        steps={joyrideStep}
        startWalkthrough={runWalkthrough}
        callBackFunction={handleJoyrideCallBack}
        disableScrolling={false}
        showProgress
        customTooltip={(TooltipRenderProps) => (
          <CustomTooltip {...TooltipRenderProps} lastBtnMsg="NEXT (5/9)" />
        )}
      />
      <div className={styles['wrapper']}>
        <Link
          to={props.primaryUnitsPath}
          className={styles['back-anchor']}
          onClick={() =>
            props.analyticsBackButtonCustomEvent?.({
              userId: userContext.user?.id.toString(),
              timestamp: Date.now().toString(),
              portal: props.portal,
              pageName: location.pathname,
            })
          }
        >
          <img src={arrowIcon} alt="" role="presentation" />
          <span className="anchor">{t('backToAllUnits')}</span>
        </Link>
        {unit && (
          <>
            <div className={styles['details']}>
              <ImageGallery images={unitGallery} />
              <UnitInfo
                unit={unit}
                navigateToUnitSpecs={navigateToUnitSpecs}
                addSavedUnit={props.addSavedUnit}
                deleteSavedUnit={props.deleteSavedUnit}
                analyticsUnitSubmitInterestCustomEvent={
                  props.analyticsUnitSubmitInterestCustomEvent
                }
                analyticsUnitBrochureCustomEvent={
                  props.analyticsUnitBrochureCustomEvent
                }
                analyticsUnitSpecsCustomEvent={
                  props.analyticsUnitSpecsCustomEvent
                }
                comparePropertiesPath={props.comparePropertiesPath}
                unitDealGetPath={props.unitDealGetPath}
                portal={props.portal}
              />
            </div>

            {unit.masterplans?.length > 0 && (
              <UnitPlan
                id={BrokerWalkthroughIds.STEP_4}
                title={t('floorPlan')}
                plans={unit.masterplans}
              />
            )}

            {unitCountry === CountriesEnum.MONTENEGRO ? (
              montenegroPaymentPlan
            ) : (
              <UnitPaymentPlan
                getUnitDetails={props.getUnitDetails}
                getUnitPaymentTerms={props.getUnitPaymentTerms}
                analyticsUnitSalesOfferDownloadCustomEvent={
                  props.analyticsUnitSalesOfferDownloadCustomEvent
                }
                downloadOmanSalesOffer={props.downloadOmanSalesOffer}
                portal={props.portal}
              />
            )}
            <div className={`card ${styles['masterplan']}`}>
              <h3>{t('masterPlan')}</h3>
              <div className={styles['image']}>
                <img
                  src={unit.masterplan_image}
                  alt="masterplan"
                  loading="lazy"
                />
              </div>
            </div>

            <UnitSpecs unit={unit} ref={unitSpecsRef} />
            {props.getSimilarUnits && (
              <SimilarUnits
                price={unit.dollar_price}
                unitId={unit.id}
                designType={unit.design_type}
                getSimilarUnits={props.getSimilarUnits}
                similarUnitsGetPath={props.similarUnitsGetPath}
                unitDetailsGetPath={props.unitDetailsGetPath}
                addSavedUnit={props.addSavedUnit}
                deleteSavedUnit={props.deleteSavedUnit}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}

export default UnitDetails;
