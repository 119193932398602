import { Payload } from '@orascom/api-interfaces';
import { ENDPOINTS } from './endpoints';
import { Network } from './network';
import {
  CustomerDetails,
  Customers as CustomerInterface,
} from '../definitions/interfaces/customers.interface';
import { PipelineDetails } from '../definitions/interfaces/lead-stages.interface';

export class Customer {
  public static getCustomers(
    query: string,
    countryId: string
  ): Promise<Payload<Payload<CustomerInterface[]>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.getCustomers.url(
        query
      )}`,
      {
        method: ENDPOINTS.getCustomers.method,
      },
      undefined,
      countryId
    );
  }

  public static getCustomerDetails(
    customerId: string,
    countryId: string
  ): Promise<Payload<Payload<CustomerDetails>>> {
    return Network.fetch(
      `${
        process.env['NX__SALESMAN_API_ENDPOINT']
      }${ENDPOINTS.customerDetails.url(customerId)}`,
      {
        method: ENDPOINTS.customerDetails.method,
      },
      undefined,
      countryId
    );
  }

  public static getCustomerPipeline(
    customerId: string,
    countryId?: string
  ): Promise<Payload<PipelineDetails[]>> {
    return Network.fetch(
      `${
        process.env['NX__SALESMAN_API_ENDPOINT']
      }${ENDPOINTS.getCustomerPipeline.url(customerId)}`,
      {
        method: ENDPOINTS.getCustomerPipeline.method,
      },
      undefined,
      countryId
    );
  }
}
