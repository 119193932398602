import { LoadingButton } from '@orascom/common-components';
import { Form, Formik } from 'formik';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import {
  MontenegroPipelineStageEnum,
  PipelineStageEnum,
} from '../../definitions/interfaces/deals.interface';
import {
  FeedbackSubmission,
  FeedbackSubmissionInput,
} from '../../definitions/interfaces/lead-stages.interface';
import { Deal as DealUtils, conditionalFields } from '../../utils/deal.utils';
import LeadFeedback from '../lead-feedback/lead-feedback';
import styles from './submit-feedback.module.scss';
import { useUserPipelineContext } from '../../context/user-pipeline-context';
import { toast } from 'react-toastify';
import { COUNTRY_SELECTED_CONTEXT } from '../../context/country-selected-context';
import { SalesManagerCountries } from '../../utils/country-selection';
export interface SubmitFeedbackProps {
  dealStatus: PipelineStageEnum | MontenegroPipelineStageEnum;
  refetchLogs: () => void;
}
export function SubmitFeedback({
  dealStatus,
  refetchLogs,
}: Readonly<SubmitFeedbackProps>) {
  const { selectedPipeline, setPipelines, refetchPipelines } =
    useUserPipelineContext();
  const { dealId, customerId } = useParams();
  const [submitting, setSubmitting] = useState(false);
  const [addFeedback, setAddFeedback] = useState(false);
  const [fetchingPipelines, setFetchingPipelines] = useState(false);
  const { t } = useTranslation();

  const activeCountry = useContext(COUNTRY_SELECTED_CONTEXT);

  const initialValues: FeedbackSubmissionInput = {
    feedbackId: 0,
    salesComment: '',
    followUpdate: '',
    issue: null,
    moreDetails: '',
    lostCompetitorSourceId: '',
    furtherDetails: '',
    competitorAdvantage: '',
    expectedBudget: null,
    currency: '',
    futureBudgetAvailability: '',
    futureOpportunity: null,
    expectedDate: '',
    defineIssue: null,
    clarifyReasons: '',
    desiredProduct: null,
    concern: null,
    productDetails: null,
  };

  const includeConditionalFields =
    activeCountry.activeCountry?.value === SalesManagerCountries.EGYPT
      ? conditionalFields
      : {};

  const schema = yup.object({
    feedbackId: yup
      .number()
      .required(t('required'))
      .moreThan(0, t('pleaseChooseAnOption')),
    salesComment: yup.string(),
    ...includeConditionalFields,
  });

  const pipelineSourceId = selectedPipeline?.pipeline_source_id;
  const pipelineFeedback = selectedPipeline?.feedback_value;
  const destinationSlug = selectedPipeline?.destination_slug;

  const submitHandler = (values: FeedbackSubmissionInput) => {
    if (!pipelineSourceId) return;
    setSubmitting(true);
    const mappedData: FeedbackSubmission = {
      lead_source_id: dealId ?? '',
      customer_source_id: customerId ?? '',
      destination_slug: destinationSlug ?? '',
      feedback_id: values.feedbackId,
      sales_comment: values.salesComment,
      more_details: values.moreDetails,
      issue: values.issue,
      lost_competitor_source_id: values.lostCompetitorSourceId,
      further_details: values.furtherDetails,
      competitor_advantage: values.competitorAdvantage,
      expected_budget: values.expectedBudget,
      currency_source_id: values.currency,
      future_budget_availability: values.futureBudgetAvailability,
      future_opportunity: values.futureOpportunity,
      clarify_reasons: values.clarifyReasons,
      expected_date: Math.floor(new Date(values.expectedDate).getTime() / 1000),
      define_issue: values.defineIssue,
      followup_date: Math.floor(new Date(values.followUpdate).getTime() / 1000),
      desired_product: values.desiredProduct ?? null,
      concern: values.concern,
      product_details: values.productDetails,
    };
    DealUtils.updateDealPipeline(
      pipelineSourceId,
      mappedData,
      activeCountry.activeCountry?.value.toString() ?? ''
    )
      .then(() => {
        setSubmitting(false);
        setFetchingPipelines(true);
        return refetchPipelines();
      })
      .then((res) => {
        if (res) setPipelines(res);
        setFetchingPipelines(false);
        setAddFeedback(false);
        refetchLogs();
        toast.success('Feedback added successfully!');
      })
      .catch(() => {
        toast.error('Error adding Feedback');
      })
      .finally(() => {
        setSubmitting(false);
        setFetchingPipelines(false);
      });
  };

  const submitLoadingText = fetchingPipelines
    ? t('configuringPipeline..')
    : t('addingFeedback..');

  return (
    <div className={styles['editable-feedback']}>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={submitHandler}
      >
        {() => {
          return (
            <Form>
              <LeadFeedback
                pipelineFeedback={pipelineFeedback}
                addFeedback={addFeedback}
                setAddFeedback={setAddFeedback}
                dealStatus={dealStatus}
              />
              {addFeedback && (
                <div className={styles['submit']}>
                  <LoadingButton
                    className="btn btn--navy"
                    type="submit"
                    loading={submitting || fetchingPipelines}
                    loadingText={submitLoadingText}
                    spinnerSize="sm"
                  >
                    {t('addFeedback')}
                  </LoadingButton>
                  <button
                    onClick={() => setAddFeedback(false)}
                    className={styles['cancel-btn']}
                  >
                    {t('cancel')}
                  </button>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default SubmitFeedback;
