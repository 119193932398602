import { Link, To } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import bellIcon from '../../assets/icons/notifications.svg';
import styles from './header.module.scss';
import { useContext, useEffect, useState } from 'react';
import { USER_CONTEXT } from '@orascom/broker-sales-man-common-components';
import {
  LanguageDropdown,
  SelectDropdown,
  Tooltip,
} from '@orascom/common-components';
import { CURRENCIES, CurrencyContext } from '@orascom/utils';
import { CURRENCIES_ENUM } from '@orascom/api-interfaces';
import InfoIcon from '../../assets/icons//info.svg?react';
export interface HeaderProps {
  notificationPath: To;
  portal: 'Broker' | 'Sales Man';
  children?: React.ReactNode;
}
export function Header(props: Readonly<HeaderProps>) {
  const { t } = useTranslation();

  const { user } = useContext(USER_CONTEXT);
  const [showCurrencyTooltip, setShowCurrencyTooltip] = useState(false);

  const { setActiveCurrency, disableCurrencySetting } =
    useContext(CurrencyContext);

  const [selectedCurrency, setSelectedCurrency] = useState(
    JSON.parse(localStorage.getItem('currency') as string) || {
      label: CURRENCIES_ENUM.USD,
      value: 1,
    }
  );
  useEffect(() => {
    if (disableCurrencySetting.disabled) {
      setSelectedCurrency({
        label: CURRENCIES_ENUM.USD,
        value: 1,
      });
    }
  }, [disableCurrencySetting.disabled]);

  useEffect(() => {
    localStorage.setItem('currency', JSON.stringify(selectedCurrency));
  }, [selectedCurrency]);

  const currencyOptions = CURRENCIES.map((cur, ind) => ({
    label: cur,
    value: ind,
  }));

  return (
    <div className={styles['wrapper']}>
      <div>
        <h1 className={styles['user']}>
          {t('welcomeBack')} {user?.name},
        </h1>
      </div>
      <div className={styles['options']}>
        <div className={styles['country-selection']}>{props.children}</div>
        {process.env['NX_SHOW_LANGUAGES_OPTIONS'] === 'true' && (
          <LanguageDropdown />
        )}
        <div className={styles['currency']}>
          <SelectDropdown
            options={currencyOptions}
            placeholder={selectedCurrency.label}
            defaultValue={selectedCurrency}
            onChange={(val) => {
              if (val) {
                setActiveCurrency(val.label);
                setSelectedCurrency(val);
              }
            }}
            isSearchable={false}
            disabled={disableCurrencySetting.disabled}
            selectedOption={selectedCurrency}
          />
          {disableCurrencySetting.info && (
            <div className={styles['tooltip__container']}>
              <button onClick={() => setShowCurrencyTooltip(true)}>
                <InfoIcon />
              </button>
              {showCurrencyTooltip && (
                <Tooltip
                  portal="broker"
                  position="bottom"
                  onClose={() => setShowCurrencyTooltip(false)}
                >
                  <span>{disableCurrencySetting.info}</span>
                </Tooltip>
              )}
            </div>
          )}
        </div>

        <Link to={props.notificationPath} className={styles['notifications']}>
          <img src={bellIcon} alt="" role="presentation" />
        </Link>
      </div>
    </div>
  );
}

export default Header;
