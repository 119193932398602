import React from 'react';

export interface TableColumn<T> {
  label: string;
  renderColumn: (label: string) => React.ReactNode;
  renderRow: (row: T, colKey: keyof T) => React.ReactNode;
  condition?: boolean;
  columnClassName?: string;
  rowClassName?: string;
  rowInlineStyles?: React.CSSProperties;
}

interface TableComponentProps<T> {
  columns: TableColumn<T>[];
  data: T[];
}

const TableComponent = <T,>({ columns, data }: TableComponentProps<T>) => {
  return (
    <table>
      <thead>
        <tr>
          {columns
            .filter((col) => col.condition !== false)
            .map((col) => (
              <th className={col.columnClassName} key={col.label}>
                {col.renderColumn(col.label)}
              </th>
            ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {columns
              .filter((col) => col.condition !== false)
              .map((col) => (
                <td
                  className={col.rowClassName}
                  style={col.rowInlineStyles}
                  key={col.label}
                >
                  {col.renderRow(row, col.label as keyof T)}
                </td>
              ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableComponent;
