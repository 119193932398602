import { User as UserApi } from '../api/user';
import { Payload } from '@orascom/api-interfaces';
import { handleError } from '@orascom/utils';
import {
  Meeting,
  SalesManagerLoginResponse,
} from '../definitions/interfaces/user';

export class User {
  public static getUserData(): Promise<Payload<SalesManagerLoginResponse>> {
    return UserApi.getUserData()
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static listManagerMeetings(countryId: string): Promise<Meeting[]> {
    return UserApi.listManagerMeetings(countryId)
      .then((result) => {
        return result.data.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }
}
