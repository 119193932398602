import { OptionValue } from '@orascom/api-interfaces';
import { LoadingButton, SelectDropdown } from '@orascom/common-components';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useUserPipelineContext } from '../../context/user-pipeline-context';
import { UpdatePipelineInputInterface } from '../../definitions/interfaces/deals.interface';
import {
  Deal,
  ObjectionOptions,
  ObjectionValues,
} from '../../utils/deal.utils';
import styles from './deal-objection.module.scss';
import {
  buildUpdatePipelinePayload,
  useObjectionSchema,
} from './use-objection-schema';
import PenIcon from '../../assets/icons/edit.svg?react';
import { COUNTRY_SELECTED_CONTEXT } from '../../context/country-selected-context';

const DealObjection = () => {
  const {
    leadId,
    customerId,
    selectedPipeline,
    refetchPipelines,
    setPipelines,
  } = useUserPipelineContext();
  const { t } = useTranslation();
  const [pendingObjectionUpdate, setPendingObjectionUpdate] = useState(false);
  const objectionSchema = useObjectionSchema();
  const [pendingCompetitors, setPendingCompetitors] = useState(false);
  const [fetchingPipelines, setFetchingPipelines] = useState(false);
  const [showObjectionForm, setShowObjectionForm] = useState(false);
  const [competitions, setCompetitors] = useState<OptionValue[]>();

  const activeCountry = useContext(COUNTRY_SELECTED_CONTEXT);

  const fetchAndSetCompetitors = () => {
    setPendingCompetitors(true);
    Deal.getCompetitionList(activeCountry.activeCountry?.value.toString() ?? '')
      .then((res) => {
        setCompetitors(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setPendingCompetitors(false));
  };

  const competitorOptions =
    competitions?.map((item) => {
      return {
        label: item.label,
        value: item.value,
      };
    }) ?? [];
  const hasNoObjection = !selectedPipeline?.objection_name;

  const submitHandler = (values: UpdatePipelineInputInterface) => {
    if (!selectedPipeline) return;
    setPendingObjectionUpdate(true);

    const data = buildUpdatePipelinePayload({
      values,
      pipeline: selectedPipeline,
      leadId,
      customerId,
    });

    Deal.updateDealPipeline(
      selectedPipeline?.pipeline_source_id,
      data,
      activeCountry.activeCountry?.value.toString() ?? ''
    )
      .then(() => {
        setPendingObjectionUpdate(false);
        setFetchingPipelines(true);
        return refetchPipelines();
      })
      .then((res) => {
        if (res) setPipelines(res);

        setFetchingPipelines(false);
        setShowObjectionForm(false);

        const successText = hasNoObjection
          ? 'Objection created successfully'
          : 'Objection updated successfully';

        toast.success(successText);
      })
      .catch((error: Error) => {
        toast.error('Error updating objection');
        return Promise.reject(error);
      })
      .finally(() => {
        setPendingObjectionUpdate(false);
        setFetchingPipelines(false);
      });
  };

  const inititalObjeReason = ObjectionOptions.find(
    (option) => option.label === selectedPipeline?.objection_name
  );

  const isUpdating = pendingObjectionUpdate || fetchingPipelines;
  const submitLoadingText = fetchingPipelines
    ? t('configuringPipeline..')
    : pendingObjectionUpdate
    ? hasNoObjection
      ? t('creatingObjection..')
      : t('updatingObjection..')
    : '';

  const submitText = hasNoObjection
    ? t('createObjection')
    : t('updateObjection');

  const showEditButton = !showObjectionForm && !hasNoObjection;

  return (
    <div className={styles['objection-wrapper']}>
      <div className={styles['header']}>
        <p className={styles['title']}>{t('objections')}</p>
        {showEditButton && (
          <button
            type="button"
            onClick={() => {
              setShowObjectionForm(true);
            }}
            className={styles['edit-btn']}
          >
            <span>{t('edit')}</span>
            <PenIcon />
          </button>
        )}
      </div>
      <Formik
        initialValues={{
          objection: !!selectedPipeline?.objection_name,
          objectionReason: inititalObjeReason as OptionValue | null,
          competitor: null as OptionValue | null,
          objectionNotes: selectedPipeline?.objection_notes,
        }}
        validationSchema={objectionSchema}
        onSubmit={submitHandler}
      >
        {({
          errors,
          setFieldValue,
          values,
          touched,
          handleBlur,
          handleChange,
          resetForm,
        }) => {
          const isCompetitorsReasonSelected =
            values.objectionReason?.value === ObjectionValues.COMPETITION;
          return (
            <Form className={styles['form']}>
              {hasNoObjection && (
                <div className={styles['checkbox-wrapper']}>
                  <input
                    id="objection"
                    name="objection"
                    type="checkbox"
                    onChange={(e) => {
                      handleChange(e);
                      const isChecked = e.target.checked;
                      setShowObjectionForm(isChecked);

                      if (!isChecked) {
                        resetForm();
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.objection ? 'true' : 'false'}
                  />
                  <label htmlFor="objection">{t('thereIsObjection')}</label>
                </div>
              )}

              {showObjectionForm && (
                <>
                  <div className={`form__input ${styles['objection-reason']}`}>
                    <label>{t('selectObjectionReason')}</label>
                    <SelectDropdown
                      name="objectionReason"
                      className={`${styles['select']}`}
                      invalid={
                        touched.objectionReason && !!errors.objectionReason
                      }
                      options={ObjectionOptions}
                      placeholder={t('selectPlaceholder')}
                      onChange={(val) => {
                        setFieldValue('objectionReason', val);
                        const shouldFetchCompetitors =
                          values.objection &&
                          !competitions &&
                          val?.value === ObjectionValues.COMPETITION;

                        if (shouldFetchCompetitors) {
                          fetchAndSetCompetitors();
                        }
                      }}
                      selectedOption={values.objectionReason || null}
                      isClearable
                    />

                    <ErrorMessage
                      name="objectionReason"
                      component="div"
                      className="form__error"
                    />
                  </div>
                  <div className="form__input">
                    <label htmlFor="objectionNotes">
                      {t('objectionNotes')}
                    </label>
                    <Field
                      id="objectionNotes"
                      name="objectionNotes"
                      type="text"
                      placeholder={t('objectionNotes')}
                    />

                    <ErrorMessage
                      name="objectionNotes"
                      component="div"
                      className="form__error"
                    />
                  </div>

                  {isCompetitorsReasonSelected && (
                    <div className={`form__input ${styles['competitor']}`}>
                      <label htmlFor="competitor">{t('competitor')}</label>
                      <SelectDropdown
                        name="competitor"
                        className={`${styles['select']}`}
                        invalid={touched.competitor && !!errors.competitor}
                        placeholder={t('selectPlaceholder')}
                        onChange={(option) =>
                          setFieldValue('competitor', option)
                        }
                        options={competitorOptions}
                        isLoading={pendingCompetitors}
                        selectedOption={values.competitor || null}
                        isClearable
                      />
                      <ErrorMessage
                        name="competitor"
                        component="div"
                        className="form__error"
                      />
                    </div>
                  )}

                  <div className={styles['submit-btn']}>
                    <LoadingButton
                      className="btn btn--navy"
                      type="submit"
                      loading={isUpdating}
                      loadingText={submitLoadingText}
                      spinnerSize="sm"
                    >
                      {submitText}
                    </LoadingButton>
                    <button
                      className={styles['cancel-btn']}
                      onClick={() => {
                        setShowObjectionForm(false);
                        resetForm();
                      }}
                    >
                      {t('cancel')}
                    </button>
                  </div>
                </>
              )}
            </Form>
          );
        }}
      </Formik>

      {showEditButton && (
        <div className={styles['objection-grid']}>
          <div>{t('objectionType')}</div>
          <div>{selectedPipeline?.objection_name}</div>

          <div>{t('objectionNotesColon')}</div>
          <div>{selectedPipeline?.objection_notes}</div>

          <div></div>
        </div>
      )}
    </div>
  );
};

export default DealObjection;
