// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
  Loader,
  ScrollToTop,
  SelectDropdown,
} from '@orascom/common-components';
import { ROUTES } from '../api/routes';
import styles from './app.module.scss';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import Sidebar from '../components/sidebar/sidebar';
import {
  CompareUnitsProvider,
  Header,
} from '@orascom/broker-sales-man-common-components';
import { CurrencyContextProvider } from '@orascom/utils';
import { useEffect, useMemo, useState } from 'react';
import { User as UserUtils } from '../utils/user';
import { storeTokenDetails } from '../utils/authentication';
import { ENDPOINTS } from '../api/endpoints';
import { USER_CONTEXT } from '../context/user-context';
import { SalesManagerInterface } from '../definitions/interfaces/user';
import { COUNTRY_SELECTED_CONTEXT } from '../context/country-selected-context';
import { mapToOption } from '../utils/options-maping';
import { OptionValue } from '@orascom/api-interfaces';
import { useTranslation } from 'react-i18next';

export function App() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [user, setUser] = useState<SalesManagerInterface | null>(null);
  const [loadingUser, setLoadingUser] = useState<boolean>(true);

  const [disableCountrySelection, setDisableCountrySelection] =
    useState<boolean>(false);
  const [activeCountry, setActiveCountry] = useState<OptionValue>();
  const [countryOptions, setCountryOptions] = useState<OptionValue[]>();

  const isNotFoundRoute = !Object.values(ROUTES).some(
    ({ path }) =>
      path === location.pathname ||
      (path.includes(':') &&
        new RegExp(`^${path.replace(/:\w+/g, '[^/]+')}$`).test(
          location.pathname
        ))
  );
  const showSidebar = !isNotFoundRoute && user;

  const availableRoutes = Object.values(ROUTES)
    .map((route) => {
      if (!route.public && !user) {
        return;
      }

      const Component = route.component;

      return (
        <Route key={route.path} path={route.path} element={<Component />} />
      );
    })
    .filter((route) => route !== null);

  useEffect(() => {
    if (token) {
      storeTokenDetails(token);
      searchParams.delete('token');
      navigate({ search: searchParams.toString() }, { replace: true });
    }
    if (!user) {
      if (
        localStorage.getItem(
          process.env['NX_SALESMAN_ACCESS_TOKEN_KEY'] as string
        )
      ) {
        UserUtils.getUserData()
          .then((res) => {
            setUser(res.data.user);
            const firstCountryOption = mapToOption(
              res.data.user?.access_countries ?? [],
              {
                label: 'name',
                value: 'slug',
              }
            )[0];
            setActiveCountry(firstCountryOption);
            setCountryOptions(
              mapToOption(res.data.user?.access_countries ?? [], {
                label: 'name',
                value: 'slug',
              })
            );
          })
          .catch(() => {
            navigate(ROUTES['Login'].path);
          })
          .finally(() => setLoadingUser(false));
      } else {
        setLoadingUser(false);
        navigate(ROUTES['Login'].path);
      }
    } else {
      setLoadingUser(false);
    }
  }, [user]);

  const userContextValue = useMemo(
    () => ({
      user,
      setUser,
      isLoading: loadingUser,
    }),
    [user, loadingUser]
  );

  if (loadingUser) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Loader />
      </div>
    );
  }

  return (
    <USER_CONTEXT.Provider value={userContextValue}>
      <COUNTRY_SELECTED_CONTEXT.Provider
        value={{
          activeCountry,
          setActiveCountry,
          disableCountrySelection,
          setDisableCountrySelection,
          countryOptions,
          setCountryOptions,
        }}
      >
        <CompareUnitsProvider>
          <CurrencyContextProvider
            defaultDollar
            getCurrenciesUrl={ENDPOINTS.getCurrencies.url}
          >
            <main
              className={`${user ? 'container container--flex' : 'container'}`}
            >
              {showSidebar && <Sidebar />}
              <ScrollToTop />
              <section className={styles['side-section']}>
                {showSidebar && (
                  <Header
                    portal="Sales Man"
                    notificationPath={ROUTES['Notifications'].path}
                  >
                    <div className={styles['countries']}>
                      <p>{t('currentlySellingIn')}</p>
                      {user?.access_countries && (
                        <SelectDropdown
                          disabled={disableCountrySelection}
                          options={mapToOption(user?.access_countries, {
                            label: 'name',
                            value: 'slug',
                          })}
                          placeholder={activeCountry?.label.toString() ?? ''}
                          defaultValue={activeCountry}
                          onChange={(val) => {
                            if (val) {
                              setActiveCountry(val);
                            }
                          }}
                          selectedOption={activeCountry}
                        />
                      )}
                    </div>
                  </Header>
                )}
                <Routes>{availableRoutes}</Routes>
              </section>
            </main>
          </CurrencyContextProvider>
        </CompareUnitsProvider>
      </COUNTRY_SELECTED_CONTEXT.Provider>
    </USER_CONTEXT.Provider>
  );
}

export default App;
