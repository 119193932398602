import { TooltipRenderProps } from 'react-joyride';
import closeIcon from '../../../assets/icons/close.svg';
import styles from './walkthrough-tooltip.module.scss';

// this is optional to use for the walkthrough in case we have 1 step and we want all buttons to appear

interface Props extends TooltipRenderProps {
  lastBtnMsg?: string;
}

export const CustomTooltip = ({
  step,
  backProps,
  primaryProps,
  closeProps,
  skipProps,
  lastBtnMsg,
}: Props) => {
  return (
    <div className={styles['tooltip-wrapper']}>
      <button {...closeProps} className={styles['tooltip-close']}>
        <img src={closeIcon} />
      </button>
      <h3 className={styles['tooltip-title']}>{step.title}</h3>
      <p className={styles['tooltip-content']}>{step.content}</p>

      <div className={styles['tooltip-footer']}>
        <button className={styles['tooltip-skip']} {...skipProps}>
          Skip
        </button>

        <div className={styles['tooltip-btn-container']}>
          <button className={styles['tooltip-back']} {...backProps}>
            Back
          </button>

          <button
            className={styles['tooltip-next']}
            {...primaryProps}
            style={{}}
          >
            {lastBtnMsg}
          </button>
        </div>
      </div>
    </div>
  );
};
