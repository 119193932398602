import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styles from './my-deals.module.scss';
import { debounce, Loader } from '@orascom/common-components';
import { DealInterface } from '../../definitions/interfaces/deals.interface';
import plusIcon from '../../assets/icons/plus--white.svg';
import arrowIcon from '../../assets/icons/left-arrow.svg';
import { CommonEventParameters } from '@orascom/utils';
import { analyticsCreateLeadCustomEvent } from '../../utils/analytics-events';
import { ROUTES } from '../../api/routes';
import {
  DealTabs,
  Deal as DealUtils,
  handleDateFormat,
} from '../../utils/deal.utils';
import { PaginationStyled } from '@orascom/broker-sales-man-common-components';
import searchIcon from '../../assets/icons/search.svg';
import FilterPopup from '../../components/filter-popup/filter-popup';
import DateFilterPopup from '../../components/date-filter-popup/date-filter-popup';
import ApplySortIcon from '../../assets/icons/apply-sort.svg';
import SortUpIcon from '../../assets/icons/sort-up.svg';
import SortDownIcon from '../../assets/icons/sort-down.svg';
import { COUNTRY_SELECTED_CONTEXT } from '../../context/country-selected-context';
import ActiveTabs from '../../components/leads-active-tabs/leads-active-tabs';
import { useDealFiltering } from '../../hooks/use-deals-filtering';
import DealsAppliedFilters from '../../components/deals-applied-filters/deals-applied-filters';
import TableComponent, { TableColumn } from '../../components/table/table';
import { SalesManagerCountries } from '../../utils/country-selection';

export function MyDeals() {
  const { t } = useTranslation();
  const activeCountry = useContext(COUNTRY_SELECTED_CONTEXT);

  const [activeTab, setActiveTab] = useState(5);
  const tlsCommentReplacmentHeader: Record<number, string> = {
    1: 'tlsComment',
    2: 'ambassadorName',
    3: 'createdOn',
    4: 'followUpDate',
    5: 'leadSource',
  };
  const [deals, setDeals] = useState<DealInterface[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const {
    filters,
    setFilters,
    dateFilters,
    appliedSort,
    handleFilterChange,
    resetDateFilters,
    filteredDeals,
    setAppliedSort,
    setDateFilters,
  } = useDealFiltering(deals);

  const [resetEngDate, setResetEngDate] = useState(true);
  const [resetExpDate, setResetExpDate] = useState(true);
  const [resetCreatedDate, setResetCreatedDate] = useState(true);
  const [resetFollowupDate, setResetFollowupDate] = useState(true);

  const currentItems = filteredDeals?.slice(indexOfFirstItem, indexOfLastItem);
  const [tableLoading, setTableLoading] = useState(true);
  const location = useLocation();
  const [numberSearched, setNumberSearched] = useState('');
  const searchDeals = (value: string) => {
    if (!value.length) {
      setTableLoading(true);
      setNumberSearched('');
    }
    setTableLoading(true);
    setNumberSearched(value);
  };
  const debouncedOnChange = debounce(searchDeals, 1000);

  const handleTabBtn = (tabId: number) => {
    if (activeTab !== tabId) {
      setActiveTab(tabId);
      setCurrentPage(1);
      setTableLoading(true);
    }
  };

  // Todo: all this will be dynamic after integration
  const analyticsEventParams: CommonEventParameters = {
    userId: '1',
    timestamp: Date.now().toString(),
    portal: 'Sales Man',
    pageName: location.pathname,
  };

  useEffect(() => {
    activeCountry.setDisableCountrySelection?.(true);
    setTableLoading(true);
    const filterparamObject = '';
    const searchQuery = new URLSearchParams(filterparamObject);
    searchQuery.append('view_number', String(activeTab));
    searchQuery.append('search', String(numberSearched));

    const getLeadParam = '';
    const leadQuery = new URLSearchParams(String(getLeadParam));
    leadQuery.append('view_number', String(activeTab));

    DealUtils.getLeads(
      numberSearched.length
        ? `/search?${searchQuery.toString()}`
        : `?${leadQuery.toString()}`,
      activeCountry.activeCountry?.value.toString() ?? ''
    )
      .then((res) => {
        setDeals(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setTableLoading(false);
        activeCountry.setDisableCountrySelection?.(false);
      });

    return () => activeCountry.setDisableCountrySelection?.(false);
  }, [activeTab, numberSearched, activeCountry.activeCountry?.value]);

  useEffect(() => {
    setFilters({});
    resetDateFilters();
  }, [activeTab]);

  useEffect(() => {
    setCurrentPage(1);
  }, [dateFilters, filters]);

  const uniqueOptions = (key: string) => {
    return Array.from(new Set(deals.map((deal) => deal[key]))).map((value) => ({
      label: value,
      value: value,
    }));
  };

  const activeDealsTabData = [
    { id: DealTabs.ALL, label: t('allLeads') },
    { id: DealTabs.DIGITAL, label: t('digitalLeads') },
    { id: DealTabs.INDIRECT, label: t('indirectLeads') },
    { id: DealTabs.DIRECT, label: t('directLeads') },
    { id: DealTabs.NO_ANSWER, label: t('noAnswerLeads') },
  ];

  const columns: TableColumn<DealInterface>[] = [
    {
      label: 'name',
      renderColumn: (label) => (
        <div className={styles['header-filter']}>{t(label)}</div>
      ),
      renderRow: (row) => <b>{row.name ? row.name : '-'}</b>,
    },
    {
      label: 'mobileNumber',
      renderColumn: (label) => (
        <div className={styles['header-filter']}>{t(label)}</div>
      ),
      renderRow: (row) => (row.phone ? row.phone : '-'),
    },
    {
      label: 'owner',
      renderColumn: (label) => t(label),
      renderRow: (row) => (row.owner ? row.owner : '-'),
    },
    {
      label: 'leadExpDate',
      renderColumn: (label) => (
        <div className={styles['header-filter']}>
          {t(label)}
          <DateFilterPopup
            reset={resetExpDate}
            onFilterChange={(startDate, endDate) =>
              setDateFilters((prev) => ({
                ...prev,
                leadExpirationDate: { startDate, endDate },
              }))
            }
          />
        </div>
      ),
      renderRow: (row) =>
        row.lead_expiration_date ? row.lead_expiration_date : '-',
      condition:
        activeCountry.activeCountry?.value === SalesManagerCountries.EGYPT,
    },
    {
      label: 'leadExpStatus',
      renderColumn: (label) => (
        <div className={styles['header-filter']}>
          {t(label)}
          <FilterPopup
            column="lead_expiration_status"
            selectedValues={filters['lead_expiration_status'] || []}
            onFilterChange={handleFilterChange}
            options={uniqueOptions('lead_expiration_status')}
          />
        </div>
      ),
      renderRow: (row) =>
        row.lead_expiration_status ? row.lead_expiration_status : '-',
      condition:
        activeCountry.activeCountry?.value === SalesManagerCountries.EGYPT,
    },
    {
      label: 'channel',
      renderColumn: (label) => (
        <div className={styles['header-filter']}>
          {t(label)}
          <FilterPopup
            column="channel"
            selectedValues={filters['channel'] || []}
            onFilterChange={handleFilterChange}
            options={uniqueOptions('channel')}
          />
        </div>
      ),
      renderRow: (row) => (row.channel ? row.channel : '-'),
      condition:
        activeCountry.activeCountry?.value === SalesManagerCountries.EGYPT,
    },
    {
      label: 'lastEngDate',
      renderColumn: (label) => (
        <div className={styles['header-filter']}>
          <button
            onClick={() =>
              setAppliedSort(
                appliedSort === 'desc'
                  ? 'asc'
                  : appliedSort === 'asc'
                  ? null
                  : 'desc'
              )
            }
          >
            <img
              src={
                appliedSort === 'desc'
                  ? SortDownIcon
                  : appliedSort === 'asc'
                  ? SortUpIcon
                  : ApplySortIcon
              }
              alt="apply-sort"
            />
          </button>
          {t(label)}
          <DateFilterPopup
            reset={resetEngDate}
            onFilterChange={(startDate, endDate) =>
              setDateFilters((prev) => ({
                ...prev,
                engagementDate: { startDate, endDate },
              }))
            }
          />
        </div>
      ),
      renderRow: (row) =>
        row.last_engagement_date ? row.last_engagement_date : '-',
      condition:
        activeCountry.activeCountry?.value === SalesManagerCountries.EGYPT,
    },
    {
      label: 'broker',
      renderColumn: (label) => t(label),
      renderRow: (row) => (row.broker_name ? row.broker_name : '-'),
      condition:
        activeTab === DealTabs.INDIRECT &&
        activeCountry.activeCountry?.value === SalesManagerCountries.EGYPT,
    },
    {
      label: 'comment',
      renderColumn: () => (
        <div className={styles['header-filter']}>
          {t(tlsCommentReplacmentHeader[activeTab])}
          {activeTab === 3 && (
            <DateFilterPopup
              reset={resetCreatedDate}
              onFilterChange={(startDate, endDate) =>
                setDateFilters((prev) => ({
                  ...prev,
                  createdOn: { startDate, endDate },
                }))
              }
            />
          )}
          {activeTab === 4 && (
            <DateFilterPopup
              reset={resetFollowupDate}
              onFilterChange={(startDate, endDate) =>
                setDateFilters((prev) => ({
                  ...prev,
                  followupDate: { startDate, endDate },
                }))
              }
            />
          )}
          {activeTab === 5 && (
            <FilterPopup
              column="lead_source"
              onFilterChange={handleFilterChange}
              options={uniqueOptions('lead_source')}
              selectedValues={filters['lead_source'] || []}
            />
          )}
        </div>
      ),
      renderRow: (row) => {
        const tlsCommentReplacmentBody: Record<number, string | null> = {
          1: row.tlscsr_comment ?? '-',
          2: row.ambassador ?? '-',
          3: row.source_created_at
            ? handleDateFormat(row.source_created_at)
            : '-',
          4: row.followup_date ? handleDateFormat(row.followup_date) : '-',
          5: row.lead_source ? row.lead_source : '-',
        };
        return tlsCommentReplacmentBody[activeTab];
      },
      condition:
        activeCountry.activeCountry?.value === SalesManagerCountries.EGYPT,
    },
    {
      label: 'feedback',
      renderColumn: (label) => (
        <div className={styles['header-filter']}>
          {t(label)}
          <FilterPopup
            column="sales_feedback"
            onFilterChange={handleFilterChange}
            options={uniqueOptions('sales_feedback')}
            selectedValues={filters['sales_feedback'] || []}
          />
        </div>
      ),
      renderRow: (row) => (row.sales_feedback ? row.sales_feedback : '-'),
    },
    {
      label: 'lastContactedDate',
      renderColumn: (label) => (
        <div className={styles['header-filter']}>{t(label)}</div>
      ),
      renderRow: (row) =>
        row.last_contacted_date
          ? handleDateFormat(row.last_contacted_date)
          : '-',
    },
    {
      label: 'leadSource',
      renderColumn: (label) => (
        <div className={styles['header-filter']}>
          {t(label)}
          <FilterPopup
            column="lead_source"
            onFilterChange={handleFilterChange}
            options={uniqueOptions('lead_source')}
            selectedValues={filters['lead_source'] || []}
          />
        </div>
      ),
      renderRow: (row) => (row.lead_source ? row.lead_source : '-'),
      condition:
        activeCountry.activeCountry?.value === SalesManagerCountries.MONTENEGRO,
    },
    {
      label: 'createdOn',
      renderColumn: (label) => (
        <div className={styles['header-filter']}>
          {t(label)}
          <FilterPopup
            column="source_created_at"
            onFilterChange={handleFilterChange}
            options={uniqueOptions('source_created_at')}
            selectedValues={filters['source_created_at'] || []}
          />
        </div>
      ),
      renderRow: (row) => (row.source_created_at ? row.source_created_at : '-'),
      condition:
        activeCountry.activeCountry?.value === SalesManagerCountries.MONTENEGRO,
    },
    {
      label: 'followUpDate',
      renderColumn: (label) => (
        <div className={styles['header-filter']}>
          {t(label)}
          <FilterPopup
            column="followup_date"
            onFilterChange={handleFilterChange}
            options={uniqueOptions('followup_date')}
            selectedValues={filters['followup_date'] || []}
          />
        </div>
      ),
      renderRow: (row) => (row.followup_date ? row.followup_date : '-'),
      condition:
        activeCountry.activeCountry?.value === SalesManagerCountries.MONTENEGRO,
    },
    {
      label: 'action',
      renderColumn: (label) => t(label),
      renderRow: (row) => (
        <div className={styles['last-column']}>
          <Link
            to={ROUTES['LeadCreatePipelines'].getPath?.(
              row.lead_source_id,
              activeCountry.activeCountry?.value
            )}
            state={{
              newPipeline: true,
            }}
          >
            {t('addPipeline')}
          </Link>
          <Link
            className={styles['deal-details']}
            to={ROUTES['LeadPipelines'].getPath?.(
              row.lead_source_id,
              activeCountry.activeCountry?.value
            )}
          >
            <span className="anchor">{t('leadDetails')}</span>
            <img src={arrowIcon} alt="" role="presentation" />
          </Link>
        </div>
      ),
    },
  ];

  return (
    <div className={styles['wrapper']}>
      <h1 className={styles['title']}>{t('myLeads')}</h1>
      <ActiveTabs
        tableLoading={tableLoading}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        handleTabBtn={handleTabBtn}
        tabData={activeDealsTabData}
      />
      <div className={styles['sub-header']}>
        <div className={styles['search-input']}>
          <img src={searchIcon} alt="" role="presentation" />
          <input
            type="text"
            placeholder={t('searchLead')}
            onChange={(e) => debouncedOnChange(e.target.value)}
          />
        </div>
        <Link
          to={ROUTES['NewDeal'].path}
          className={styles['deal-anchor']}
          onClick={() => analyticsCreateLeadCustomEvent(analyticsEventParams)}
        >
          <img src={plusIcon} alt="plus-icon" />
          {t('newLead')}
        </Link>
      </div>
      <DealsAppliedFilters
        filters={filters}
        resetDateFilters={resetDateFilters}
        setFilters={setFilters}
        resetEngDate={resetEngDate}
        resetExpDate={resetExpDate}
        resetCreatedDate={resetCreatedDate}
        resetFollowupDate={resetFollowupDate}
        setResetCreatedDate={setResetCreatedDate}
        setResetFollowupDate={setResetFollowupDate}
        setResetExpDate={setResetExpDate}
        setResetEngDate={setResetEngDate}
        handleFilterChange={handleFilterChange}
        dateFilters={dateFilters}
        setDateFilters={setDateFilters}
      />

      <div className={tableLoading ? '' : styles['table']}>
        {tableLoading ? (
          <div className={styles['loader-wrapper']}>
            <Loader />
          </div>
        ) : currentItems.length ? (
          <TableComponent columns={columns} data={currentItems} />
        ) : (
          <p>{t('noLeadsToDisplay')}</p>
        )}
      </div>
      {!tableLoading && currentItems.length ? (
        <div className={styles['pagination-info']}>
          <p className={styles['count']}>
            {t('showing')}{' '}
            <span>
              {' '}
              {indexOfFirstItem + 1} -{' '}
              {indexOfLastItem > filteredDeals.length
                ? filteredDeals.length
                : indexOfLastItem}{' '}
            </span>{' '}
            {t('outOf')} <span>{filteredDeals.length}</span>
            {''}
            {t('leads')}
          </p>

          <PaginationStyled
            currentPage={currentPage}
            totalCount={filteredDeals.length}
            pageSize={itemsPerPage}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        </div>
      ) : null}
    </div>
  );
}

export default MyDeals;
