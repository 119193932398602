import { useTranslation } from 'react-i18next';
import { PipelineStageEnum } from '../../definitions/interfaces/deals.interface';
import DealDetailsBreadcrumbs from '../deal-details-breadcrumbs/deal-details-breadcrumbs';
import DealInfoCard from '../deal-info-card/deal-info-card';
import { DicoveryStage } from '../pipeline-stages/discovery-stage';
import styles from './pipeline-details.module.scss';
import {
  LeadDetails,
  PipelineDetails,
} from '../../definitions/interfaces/lead-stages.interface';
import { SalesManWalkthroughIds } from '@orascom/common-components';

export interface PipelineDetailsProps {
  page: 'Customer' | 'Lead';
  selectedPipeline: PipelineDetails;
}
export function PipelineDetailsStatic({
  page,
  selectedPipeline,
}: Readonly<PipelineDetailsProps>) {
  const { t } = useTranslation();
  const user: LeadDetails = {
    first_name: 'Abdelwahab',
    last_name: 'Ahmed',
    email: 'test@test.com',
    phone: '01001234567',
    channel_id: 1,
    ambassador_source_id: ' 1',
    broker_source_id: '1',
    event_source_id: '1',
    expiration_date: '12-7-2024',
    last_engagement_date: '12-7-2024',
    source_created_at: '12-7-2024',
    destinations: [
      {
        account_number: '21',
        brochure: 'test',
        id: 1,
        masterplan_description: 'test',
        name: 'test',
        slug: 'test',
        time_range: 'test',
        units_sold: 'test',
        video_playlist: null,
        welcome_description: 'test',
      },
    ],
    channel_details: 'test',
    customer_source_id: 'test',
    destination_gouna: true,
    destination_makadi_byoum: false,
    destination_owest: false,
  };

  return (
    <div className="card">
      <div className={styles['select-pipeline']}>
        <div className={styles['selected-pipeline']}>
          <span>{t('pipeline')}: </span>
          <span className={styles['pipeline-name']}>
            {selectedPipeline?.pipeline_name} -{' '}
            {selectedPipeline?.current_phase}
          </span>
        </div>
      </div>

      <DealDetailsBreadcrumbs
        id={SalesManWalkthroughIds.STEP_9}
        dealStatus={PipelineStageEnum.LEAD_DISCOVERY}
        page={page}
      />

      <div className={styles['deal-content']}>
        <DicoveryStage />

        <DealInfoCard details={user} page={page} />
      </div>
    </div>
  );
}

export default PipelineDetailsStatic;
