import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Numeral from 'numeral';
import {
  CountriesEnum,
  InstallmentFrequencyMapper,
  OptionValue,
  UnitCompareInterface,
  UnitPaymentTerms,
} from '@orascom/api-interfaces';
import styles from './compare-properties.module.scss';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import closeIcon from '../../assets/icons/close.svg';
import { SelectDropdown } from '@orascom/common-components';
import { v4 as uuidv4 } from 'uuid';
import {
  useCompareUnitsContext,
  USER_CONTEXT,
} from '@orascom/broker-sales-man-common-components';
import {
  CommonEventParameters,
  downloadSalesOffer,
  handleSharePdf,
  useCurrencyConverter,
  usePaymentPlanCalculator,
} from '@orascom/utils';
import { toast } from 'react-toastify';

export interface CompareSingleUnitProps {
  unit: UnitCompareInterface;
  index: number;
  columnPosition: string;
  setCompareUnits: Dispatch<SetStateAction<UnitCompareInterface[]>>;
  getUnitPaymentTerms(unitId: number): Promise<UnitPaymentTerms[]>;
  unitDetailsGetPath?: Function;
  unitDealGetPath?: Function;
  analyticsNewtDealCustomEvent?(params: CommonEventParameters): void;
  downloadOmanSalesOffer?(unitId: number, unitName: string): Promise<any>;
  salesOffer?: boolean;
}

export const CompareSingleUnit = (props: Readonly<CompareSingleUnitProps>) => {
  useCompareUnitsContext();
  const userContext = useContext(USER_CONTEXT);
  const location = useLocation();
  const { t } = useTranslation();
  const { removeUnitFromCompare } = useCompareUnitsContext();
  const { convertToSelectedCurrency } = useCurrencyConverter();
  const [unitPaymentData, setUnitPaymentData] = useState<UnitPaymentTerms[]>(
    []
  );
  const [unitDataLoading, setUnitDataLoading] = useState(true);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [downloadedPdf, setDownloadedPdf] = useState<File | null>(null);

  const {
    onSelectDownPayment,
    setSelectedPaymentTerms,
    selectedPaymentTerms,
    onSelectDuration,
  } = usePaymentPlanCalculator();

  const getSelectedCurrency = (unitPrice: number, unitCurrency: string) => {
    const { price, currency } = convertToSelectedCurrency(
      unitPrice,
      unitCurrency
    );
    return { price, currency };
  };

  useEffect(() => {
    props
      .getUnitPaymentTerms(props.unit.id)
      .then((result) => {
        setUnitPaymentData(result);
        setSelectedPaymentTerms(result[0]);
      })
      .catch((err) => {
        console.error('Error fetching payment terms:', err);
      })
      .finally(() => setUnitDataLoading(false));
  }, [props.unit]);

  function onRemoveUnitFromCompare(id: number) {
    removeUnitFromCompare(id);
    props.setCompareUnits((units) => units?.filter((unit) => unit.id !== id));
  }

  const analyticsEventParams: CommonEventParameters = {
    userId: userContext.user?.id.toString(),
    timestamp: Date.now().toString(),
    portal: 'Broker',
    pageName: location.pathname,
  };

  const downpaymentOptions = unitPaymentData.reduce(
    (options: OptionValue[], term) => {
      const { price, currency } = convertToSelectedCurrency(
        term.downpayment_amount,
        term.currency
      );
      if (!options.find((opt) => opt.value === term.downpayment_percent)) {
        options.push({
          label: `${
            term.downpayment_percent
          }% ${price.toLocaleString()} ${currency}`,
          value: term.downpayment_percent,
        });
      }
      return options;
    },
    []
  );

  const installmentDurationOptions = unitPaymentData.reduce(
    (options: OptionValue[], term) => {
      if (
        !options.find((opt) => opt.value === term.installment_durationin_years)
      ) {
        options.push({
          label: `${term.installment_durationin_years} ${
            term.installment_durationin_years === 1 ? t('year') : t('years')
          }`,
          value: term.installment_durationin_years,
        });
      }
      return options;
    },
    []
  );

  async function handleDownloadSales() {
    setLoadingPDF(true);
    try {
      if (props.unit && selectedPaymentTerms) {
        const pdfData = await downloadSalesOffer(
          props.unit,
          selectedPaymentTerms
        );
        if (!pdfData) {
          toast.error('Failed to download PDF.');
          throw new Error('Failed to download PDF.');
        }

        const file = new File(
          [pdfData],
          `Unit_${props.unit.name}_sales_offer.pdf`,
          {
            type: 'application/pdf',
          }
        );
        setDownloadedPdf(file);
        toast.success('File Downloaded');
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingPDF(false);
    }
  }

  const handleDownloadOmanSalesOffer = async () => {
    setLoadingPDF(true);
    if (props.unit) {
      try {
        const pdfBlob = await props.downloadOmanSalesOffer?.(
          props.unit.id,
          props.unit.name
        );

        if (!pdfBlob) {
          toast.error('Failed to download PDF.');
          throw new Error('Failed to download PDF.');
        }

        const file = new File(
          [pdfBlob],
          `Unit_${props.unit.name}_sales_offer.pdf`,
          {
            type: 'application/pdf',
          }
        );
        setDownloadedPdf(file);
        toast.success('File Downloaded');
      } catch (err) {
        console.error(err);
      } finally {
        setLoadingPDF(false);
      }
    }
  };

  const UnitPaymentTermsComponent = ({
    unitPaymentData,
    loading,
    componentCell,
  }: {
    unitPaymentData: UnitPaymentTerms[];
    loading: boolean;
    componentCell: ReactNode;
  }) => {
    return loading ? (
      <p key={props.unit.id}>Loading...</p>
    ) : unitPaymentData.length > 0 ? (
      componentCell
    ) : (
      <p>-</p>
    );
  };

  const rowLabelVisibility = useMemo(
    () => (props.index > 0 ? 'opacity-0' : ''),
    [props.index]
  );

  return (
    <div className={`${styles['unit-table']}`}>
      <div className={styles['row-img']}>
        <Link
          key={props.unit.id}
          to={props.unitDetailsGetPath?.(props.unit.id)}
          className={styles['unit-card']}
        >
          <button
            className={styles['unit-card__remove-btn']}
            type="button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onRemoveUnitFromCompare(props.unit.id);
            }}
          >
            <img
              className={styles['unit-card__remove-btn__close-icon']}
              src={closeIcon}
              alt=""
              role="presentation"
            />
          </button>
          <img
            className={styles['unit-card__image']}
            src={props.unit.cover_image}
            alt={props.unit.unit_type.name}
            loading="lazy"
          />

          <div className={styles['unit-card__info']}>
            <p>{`${props.unit.project.destination.name} - ${props.unit.project.name}`}</p>
            <p>{props.unit.unit_type.name}</p>
          </div>
        </Link>
      </div>
      <p className={`${styles['row-title']} ${rowLabelVisibility}`}>
        {t(`destination`)}
      </p>
      <div
        className={`${styles['row']} ${
          styles[`row-${props.columnPosition}`]
        } bg-white-300 `}
      >
        <div key={props.unit.id} className={styles['column']}>
          {props.unit.project.destination.name}
        </div>
      </div>

      <p className={`${styles['row-title']} ${rowLabelVisibility}`}>
        {t('unitPrice')}
      </p>

      <div
        className={`${styles['row']} ${
          styles[`row-${props.columnPosition}`]
        } bg-shadow`}
      >
        <td key={props.unit.id} className={styles['column']}>
          {Numeral(
            getSelectedCurrency(props.unit.price, props.unit.currency).price
          ).format('0,0')}{' '}
          {getSelectedCurrency(props.unit.price, props.unit.currency).currency}
        </td>
      </div>

      <p className={`${styles['row-title']} ${rowLabelVisibility}`}>
        {t(`builtUpArea`)}
      </p>

      <div
        className={`${styles['row']} ${
          styles[`row-${props.columnPosition}`]
        } bg-white-300`}
      >
        <div key={props.unit.id} className={styles['column']}>
          {props.unit.built_up_area} M²
        </div>
      </div>

      <p className={`${styles['row-title']} ${rowLabelVisibility}`}>
        {t(`bedrooms`)}
      </p>

      <div
        className={`${styles['row']} ${
          styles[`row-${props.columnPosition}`]
        } bg-shadow`}
      >
        <div key={props.unit.id} className={styles['column']}>
          {props.unit.bedrooms > 0 ? props.unit.bedrooms : '-'}
        </div>
      </div>

      <p className={`${styles['row-title']} ${rowLabelVisibility}`}>
        {t(`bathrooms`)}
      </p>

      <div
        className={`${styles['row']} ${
          styles[`row-${props.columnPosition}`]
        } bg-white-300`}
      >
        <div key={props.unit.id} className={styles['column']}>
          {props.unit.bathrooms > 0 ? props.unit.bathrooms : '-'}
        </div>
      </div>

      <p className={`${styles['row-title']} ${rowLabelVisibility}`}>
        {t(`facilitiesAmenities`)}
      </p>

      <div
        className={`${styles['row']} ${
          styles[`row-${props.columnPosition}`]
        } bg-shadow`}
      >
        <div key={props.unit.id} className={styles['column']}>
          {props.unit.facilities.length ? (
            props.unit.facilities.map((facility) => (
              <p key={uuidv4()}>
                <img
                  className={styles['facility-icon']}
                  src={facility.icon}
                  alt=""
                  role="presentation"
                />{' '}
                {facility.title}
              </p>
            ))
          ) : (
            <p>-</p>
          )}
        </div>
      </div>

      <p className={`${styles['row-title']} ${rowLabelVisibility}`}>
        {t('downpayment')} <small>{t('downpaymentDisclaimer')}</small>
      </p>

      <div
        className={`${styles['row']} ${
          styles[`row-${props.columnPosition}`]
        } bg-white-300`}
      >
        <UnitPaymentTermsComponent
          unitPaymentData={unitPaymentData}
          loading={unitDataLoading}
          componentCell={
            <div className={styles['column']}>
              <div className={styles['select-wrapper']}>
                <SelectDropdown
                  options={downpaymentOptions}
                  placeholder={t('downPayment')}
                  onChange={(val) => {
                    onSelectDownPayment(val?.value as number, unitPaymentData);
                  }}
                  defaultValue={downpaymentOptions[0]}
                  selectedOption={
                    selectedPaymentTerms
                      ? {
                          label: `${
                            selectedPaymentTerms.downpayment_percent
                          }% ${getSelectedCurrency(
                            selectedPaymentTerms.downpayment_amount,
                            selectedPaymentTerms.currency
                          ).price.toLocaleString()} ${
                            getSelectedCurrency(
                              selectedPaymentTerms.downpayment_amount,
                              selectedPaymentTerms.currency
                            ).currency
                          }`,
                          value: selectedPaymentTerms.downpayment_percent,
                        }
                      : downpaymentOptions[0]
                  }
                />
              </div>
            </div>
          }
        />
      </div>

      <p className={`${styles['row-title']} ${rowLabelVisibility}`}>
        {t('installmentDuration')}
      </p>

      <div
        className={`${styles['row']} ${
          styles[`row-${props.columnPosition}`]
        } bg-shadow`}
      >
        <UnitPaymentTermsComponent
          unitPaymentData={unitPaymentData}
          loading={unitDataLoading}
          componentCell={
            <div className={styles['column']}>
              <div className={styles['select-wrapper']}>
                <SelectDropdown
                  options={installmentDurationOptions}
                  placeholder={t('installmentDuration')}
                  onChange={(val) => {
                    onSelectDuration(val?.value as number, unitPaymentData);
                  }}
                  defaultValue={installmentDurationOptions[0]}
                  selectedOption={
                    selectedPaymentTerms
                      ? {
                          label: `${
                            selectedPaymentTerms.installment_durationin_years
                          } ${
                            selectedPaymentTerms.installment_durationin_years ===
                            1
                              ? t('year')
                              : t('years')
                          }`,
                          value:
                            selectedPaymentTerms.installment_durationin_years,
                        }
                      : installmentDurationOptions[0]
                  }
                />
              </div>
            </div>
          }
        />
      </div>

      <p className={`${styles['row-title']} ${rowLabelVisibility}`}>
        {t('installmentBeforeDelivery')}
      </p>

      <div
        className={`${styles['row']} ${
          styles[`row-${props.columnPosition}`]
        } bg-white-300`}
      >
        <UnitPaymentTermsComponent
          unitPaymentData={unitPaymentData}
          loading={unitDataLoading}
          componentCell={
            <div className={styles['column']}>
              <p>
                {`${getSelectedCurrency(
                  selectedPaymentTerms?.before_delivery_installment_amount ?? 0,
                  selectedPaymentTerms?.currency ?? ''
                ).price.toLocaleString()} ${
                  getSelectedCurrency(
                    selectedPaymentTerms?.before_delivery_installment_amount ??
                      0,
                    selectedPaymentTerms?.currency ?? ''
                  ).currency
                } `}
                <small>
                  /{' '}
                  {
                    InstallmentFrequencyMapper[
                      selectedPaymentTerms?.installment_frequency as number
                    ]
                  }
                </small>
              </p>
            </div>
          }
        />
      </div>

      <p className={`${styles['row-title']} ${rowLabelVisibility}`}>
        {t('installmentAfterDelivery')}
      </p>

      <div
        className={`${styles['row']} ${
          styles[`row-${props.columnPosition}`]
        } bg-shadow `}
      >
        <UnitPaymentTermsComponent
          unitPaymentData={unitPaymentData}
          loading={unitDataLoading}
          componentCell={
            <div className={styles['column']}>
              <p>
                {`${getSelectedCurrency(
                  selectedPaymentTerms?.after_delivery_installment_amount ?? 0,
                  selectedPaymentTerms?.currency ?? ''
                ).price.toLocaleString()} ${
                  getSelectedCurrency(
                    selectedPaymentTerms?.after_delivery_installment_amount ??
                      0,
                    selectedPaymentTerms?.currency ?? ''
                  ).currency
                } `}
                <small>
                  /{' '}
                  {
                    InstallmentFrequencyMapper[
                      selectedPaymentTerms?.installment_frequency as number
                    ]
                  }
                </small>
              </p>
            </div>
          }
        />
      </div>

      {unitPaymentData.length > 0 && (
        <div className={`${styles['pdf-buttons']}`}>
          {' '}
          <button
            id="download-btn"
            className={`${styles['download-btn']}`}
            onClick={() => {
              if (
                props.unit?.project.destination.country.slug ===
                CountriesEnum.OMAN
              ) {
                handleDownloadOmanSalesOffer();
              } else {
                handleDownloadSales();
              }
            }}
          >
            {loadingPDF ? <p>Loading...</p> : t('downloadSalesOffer')}
          </button>
          <div
            className={`${
              !downloadedPdf && styles['navigation-link--disabled']
            } `}
          >
            <button
              className={`${styles['download-btn']} ${
                !downloadedPdf ? styles['disabled'] : ''
              }`}
              onClick={() => handleSharePdf(props.unit, downloadedPdf)}
              disabled={!downloadedPdf}
            >
              {t('shareSalesOffer')}
            </button>

            {!downloadedPdf ? (
              <span className={styles['info-msg']}>
                <p>{t('generationErrorMessage')}</p>
              </span>
            ) : null}
          </div>
        </div>
      )}

      <div className={styles['row']}>
        <div key={props.unit.id} className={styles['deal-container']}>
          <Link
            to={props.unitDealGetPath?.(props.unit?.id)}
            className={styles['deal-anchor']}
            onClick={() =>
              props.analyticsNewtDealCustomEvent?.(analyticsEventParams)
            }
          >
            {t('submitInterest')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CompareSingleUnit;
