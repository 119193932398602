import { useTranslation } from 'react-i18next';
import styles from './overview.module.scss';
import { ROUTES } from '../../api/routes';
import { UnitInterface } from '@orascom/api-interfaces';
import { useContext, useEffect, useState } from 'react';
import { Loader } from '@orascom/common-components';
import { Unit as UnitUtils } from '../../utils/unit';
import { DashboardUnits } from '@orascom/broker-sales-man-common-components';
import NewMeetings from '../../components/meetings/meetings';
import AnalyticsOverview from '../../components/analytics-overview/analytics-overview';
import { USER_CONTEXT } from '../../context/user-context';
import RecentUpdates from '../../components/recent-updates/recent-updates';

export function Overview() {
  const { t } = useTranslation();
  const [newProperties, setNewProperties] = useState<UnitInterface[]>([]);
  const [savedUnits, setSavedUnits] = useState<UnitInterface[]>([]);
  const [loading, setLoading] = useState(false);
  const userContext = useContext(USER_CONTEXT);

  const staticDataEmailsAllowed =
    userContext.user?.email === 'marian.wessam@orascomhd.com' ||
    userContext.user?.email === 'testcrm1@orascomhd.com';

  const params = new URLSearchParams({
    sort_by: 'created_at',
    direction: 'desc',
  });

  useEffect(() => {
    setLoading(true);
    Promise.all([
      UnitUtils.getSavedUnits(1, 3),
      UnitUtils.getSalesManUnits(1, 3, params),
    ])
      .then((res) => {
        setSavedUnits(res[0].data);
        setNewProperties(res[1].data);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <div className={styles['wrapper']}>
      <h1 className={styles['title']}>{t('overview')}</h1>
      <div className={styles['updates-grid']}>
        {staticDataEmailsAllowed && <RecentUpdates />}
        <NewMeetings />
      </div>
      {savedUnits.length > 0 ? (
        <DashboardUnits
          title={t('savedUnits')}
          anchorTitle={t('viewAllSavedUnits')}
          anchorLink={ROUTES['SavedUnits'].path}
          units={savedUnits}
          getUnitDetailsPath={ROUTES['UnitDetails'].getPath}
        />
      ) : null}
      <DashboardUnits
        title={t('newProperties')}
        anchorTitle={t('viewAllNewProperties')}
        anchorLink={ROUTES['NewProperties'].path}
        units={newProperties}
        getUnitDetailsPath={ROUTES['UnitDetails'].getPath}
      />
      {staticDataEmailsAllowed && <AnalyticsOverview />}
    </div>
  );
}

export default Overview;
