import { Payload } from '@orascom/api-interfaces';
import { ENDPOINTS } from './endpoints';
import { Network } from './network';
import {
  Meeting,
  SalesManagerLoginResponse,
} from '../definitions/interfaces/user';

export class User {
  public static getUserData(): Promise<Payload<SalesManagerLoginResponse>> {
    const url = `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.getUserData.url}`;
    return Network.fetch(url, {
      method: ENDPOINTS.getUserData.method,
    });
  }

  public static listManagerMeetings(
    countryId: string
  ): Promise<Payload<Payload<Meeting[]>>> {
    const url = `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.listManagerMeetings.url}`;
    return Network.fetch(
      url,
      {
        method: ENDPOINTS.listManagerMeetings.method,
      },
      undefined,
      countryId
    );
  }

  public static updateWalkthroughStatus() {
    const url = `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.updateUserWalkthroughStatus.url}`;
    return Network.fetch(url, {
      method: ENDPOINTS.updateUserWalkthroughStatus.method,
    });
  }
}
