import { OptionValue } from '@orascom/api-interfaces';
import { SelectDropdown } from '@orascom/common-components';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { SingleValue } from 'react-select';
import { useUserPipelineContext } from '../../context/user-pipeline-context';
import { PipelineStageEnum } from '../../definitions/interfaces/deals.interface';
import DealDetailsBreadcrumbs from '../deal-details-breadcrumbs/deal-details-breadcrumbs';
import DealInfoCard from '../deal-info-card/deal-info-card';
import ClosingStage from '../pipeline-stages/closing-stage';
import { DicoveryStage } from '../pipeline-stages/discovery-stage';
import SalesPitchStage from '../pipeline-stages/sales-pitch-stage';
import styles from './pipeline-details.module.scss';
import { useEffect } from 'react';
import DisqualifyLink from '../pipeline-stages/disqualifyLink';

export interface PipelineDetailsProps {
  page: 'Customer' | 'Lead';
}
export function PipelineDetails({ page }: Readonly<PipelineDetailsProps>) {
  const { t } = useTranslation();
  const location = useLocation();

  const { countryId } = useParams();
  const {
    user,
    pipelines,
    selectedPipeline,
    setSelectedPipelineId,
    refetchingPipelines,
    leadId,
    lead,
  } = useUserPipelineContext();

  const piplinesOptions = pipelines
    ?.filter(
      (pipeline) =>
        (pipeline.current_phase === PipelineStageEnum.LEAD_DISCOVERY ||
          pipeline.current_phase === PipelineStageEnum.SALES_PITCH ||
          pipeline.current_phase === PipelineStageEnum.DEAL_CLOSING) &&
        !pipeline.is_lost &&
        pipeline.pipeline_expiration_status === 'Valid'
    )
    .map((pipeline) => ({
      label: pipeline.pipeline_name + ' - ' + pipeline.current_phase,
      value: pipeline.pipeline_source_id,
    }));

  useEffect(() => {
    const isCreateRoute = location.pathname.endsWith('/create');
    const defaultPipeline = isCreateRoute
      ? null
      : selectedPipeline?.pipeline_source_id ?? piplinesOptions?.[0]?.value;
    if (defaultPipeline) {
      setSelectedPipelineId(defaultPipeline);
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const selectedPipelineOption = selectedPipeline && {
    label:
      selectedPipeline.pipeline_name + ' - ' + selectedPipeline.current_phase,
    value: selectedPipeline.pipeline_source_id,
  };

  const onChangePipeline = (
    pipelineOption?: SingleValue<OptionValue<string | number>>
  ) => {
    if (!pipelineOption) {
      setSelectedPipelineId(undefined);
    }

    const selected = pipelines?.find(
      (pipeline) => pipeline.pipeline_source_id === pipelineOption?.value
    );
    if (selected) {
      setSelectedPipelineId(selected.pipeline_source_id);
    }
  };

  const dealStatus =
    selectedPipeline?.current_phase ?? PipelineStageEnum.LEAD_DISCOVERY;

  const isDiscoveryStage = dealStatus === PipelineStageEnum.LEAD_DISCOVERY;
  const isClosingStage = dealStatus === PipelineStageEnum.DEAL_CLOSING;
  const isSalesPitchStage = dealStatus === PipelineStageEnum.SALES_PITCH;

  const isLostStage = dealStatus === PipelineStageEnum.LOST;

  const qualifiedLead = lead?.customer_source_id;

  // We may have a lead with a customer ID, which is why we ensure customerId is null.
  const showDisqualifyLink = !qualifiedLead && !!leadId && !isLostStage;

  const hasOnePipeline = piplinesOptions?.length === 1;
  const hasMoreThanOnePipeline = piplinesOptions?.length > 1;

  return (
    <div className="card">
      <div className={styles['select-pipeline']}>
        {hasOnePipeline && selectedPipeline && (
          <div className={styles['selected-pipeline']}>
            <span>{t('pipeline')}: </span>
            <span className={styles['pipeline-name']}>
              {selectedPipeline?.pipeline_name} -{' '}
              {selectedPipeline?.current_phase}
            </span>
          </div>
        )}

        {hasMoreThanOnePipeline && (
          <>
            <p>{t('selectPipeline')}</p>
            <SelectDropdown
              name="pipeline"
              className={styles['select']}
              options={piplinesOptions}
              selectedOption={selectedPipelineOption}
              placeholder={t('selectPlaceholder')}
              onChange={onChangePipeline}
              isLoading={refetchingPipelines}
              isClearable
              noOptionsMessage={() => t('noPipelineFound')}
            />
          </>
        )}
      </div>

      <DealDetailsBreadcrumbs
        dealStatus={dealStatus as PipelineStageEnum}
        page={page}
      />

      <div className={styles['deal-content']}>
        {isDiscoveryStage && <DicoveryStage />}
        {isSalesPitchStage && <SalesPitchStage />}
        {isClosingStage && <ClosingStage />}
        {isLostStage && <p>{t('thisPipelineIsLost')}</p>}
        {user && <DealInfoCard details={user} page={page} />}
      </div>
      {showDisqualifyLink && (
        <>
          <hr className={styles['line']} />
          <DisqualifyLink leadId={leadId} countryId={countryId} />
        </>
      )}
    </div>
  );
}

export default PipelineDetails;
