import { UpdatePipeline } from '../../definitions/interfaces/deals.interface';
import {
  LeadDiscoveryInput,
  LeadDiscoveryInterface,
  PipelineDetails,
} from '../../definitions/interfaces/lead-stages.interface';

interface TransformDiscoveryFormToData {
  formValues: LeadDiscoveryInput;
  dealId: string;
  customerId: string;
}
export const transformDiscoveryFormToData = ({
  formValues,
  dealId,
  customerId,
}: TransformDiscoveryFormToData): LeadDiscoveryInterface => {
  return {
    lead_source_id: dealId ?? '',
    customer_source_id: customerId ?? '',
    pipeline_type: formValues.pipelineType,
    destination_slug: formValues.destinationSlug ?? '',
    unit_category_id: formValues.unitCategoryId,
    feedback_id: formValues.feedbackId,
    sales_comment: formValues.salesComment,
    more_details: formValues.moreDetails,
    issue: formValues.issue,
    lost_competitor_source_id: formValues.lostCompetitorSourceId,
    further_details: formValues.furtherDetails,
    competitor_advantage: formValues.competitorAdvantage,
    expected_budget: formValues.expectedBudget,
    currency_source_id: formValues.currency,
    future_budget_availability: formValues.futureBudgetAvailability,
    future_opportunity: formValues.futureOpportunity,
    clarify_reasons: formValues.clarifyReasons,
    followup_date: Math.floor(
      new Date(formValues.followUpdate).getTime() / 1000
    ),
    expected_date: Math.floor(
      new Date(formValues.expectedDate).getTime() / 1000
    ),
    define_issue: formValues.defineIssue,
    concern: formValues.concern,
    desired_product: formValues.desiredProduct,
    initial_meeting_date: Math.floor(
      new Date(formValues.initalMeetingDate).getTime() / 1000
    ),
    product_details: formValues.productDetails,
  };
};

export const initialLeadFormValues: LeadDiscoveryInput = {
  pipelineType: 0,
  destinationSlug: '',
  initalMeetingDate: '',
  unitCategoryId: 0,
  feedbackId: 0,
  salesComment: '',
  followUpdate: '',
  moreDetails: '',
  issue: null,
  lostCompetitorSourceId: '',
  furtherDetails: '',
  competitorAdvantage: '',
  expectedBudget: null,
  currency: '',
  futureBudgetAvailability: '',
  futureOpportunity: null,
  clarifyReasons: '',
  expectedDate: '',
  defineIssue: null,
  concern: null,
  desiredProduct: null,
  productDetails: null,
};

const pipelineTypeMapper = {
  'Single Unit': 1,
  'Multiple Units': 2,
  'Multi Units': 2, // TODO check this response with the backend since this is what is returned
};
export const getInitialPipelineFormValues = (
  pipeline?: PipelineDetails
): LeadDiscoveryInput => {
  if (!pipeline) {
    return initialLeadFormValues;
  }

  return {
    pipelineType: pipelineTypeMapper[pipeline.pipeline_type],
    destinationSlug: pipeline.destination_slug,
    initalMeetingDate: pipeline.initial_meeting_date,
    unitCategoryId: 0,
    feedbackId: pipeline.feedback_id,
    salesComment: pipeline.sales_comment,
    followUpdate: '',
    moreDetails: '',
    issue: null,
    lostCompetitorSourceId: '',
    furtherDetails: '',
    competitorAdvantage: '',
    expectedBudget: null,
    currency: pipeline.currency ?? '',
    futureBudgetAvailability: pipeline.future_budget_availability,
    futureOpportunity: null,
    clarifyReasons: '',
    expectedDate: '',
    defineIssue: null,
    concern: null,
    desiredProduct: null,
    productDetails: null,
  };
};

export const buildUpdatePipelinePayload = ({
  dealId,
  customerId,
  values,
}: {
  dealId: string | undefined;
  customerId: string | undefined;
  values: LeadDiscoveryInput;
}): UpdatePipeline => ({
  lead_source_id: dealId ?? '',
  customer_source_id: customerId ?? '',
  destination_slug: values.destinationSlug ?? '',
  feedback_id: values.feedbackId ?? 0,
  sales_comment: values.salesComment,
  objections_handled: false,
  initial_meeting_date: Math.floor(
    new Date(values.initalMeetingDate).getTime() / 1000
  ),
  unit_category_id: values.unitCategoryId,
});
