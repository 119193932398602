import { Payload } from '@orascom/api-interfaces';
import { ENDPOINTS } from './endpoints';
import { Network } from './network';
import {
  QuarterBreakdownChartInterface,
  TotalUnitsSoldInterface,
} from '../definitions/interfaces/analytics';

export class AnalyticsAPI {
  public static getTotalUnitsSold(
    from: number,
    to: number,
    countryId: string
  ): Promise<Payload<TotalUnitsSoldInterface>> {
    const url = new URL(
      `${process.env['NX__API_ENDPOINT']}${ENDPOINTS.getTotalUnitsSold.path}`
    );

    url.searchParams.append('from', from.toString());
    url.searchParams.append('to', to.toString());
    const fetchURL = decodeURIComponent(url.href);

    return Network.fetch(
      fetchURL,
      {
        method: ENDPOINTS.getTotalUnitsSold.method,
      },
      undefined,
      countryId
    );
  }

  public static getQuarterBreakdownChart(
    from: number,
    to: number,
    countryId: string,
    destination_ids?: number
  ): Promise<Payload<QuarterBreakdownChartInterface[]>> {
    const url = new URL(
      `${process.env['NX__API_ENDPOINT']}${ENDPOINTS.getQuarterBreakdownChart.path}`
    );

    url.searchParams.append('from', from.toString());
    url.searchParams.append('to', to.toString());
    if (destination_ids) {
      url.searchParams.append('destination_ids', destination_ids.toString());
    }
    const fetchURL = decodeURIComponent(url.href);

    return Network.fetch(
      fetchURL,
      {
        method: ENDPOINTS.getQuarterBreakdownChart.method,
      },
      undefined,
      countryId
    );
  }
}
