import { OptionValue } from '@orascom/api-interfaces';

export const statusesOptions = [
  {
    label: 'lead discovery',
    value: 'lead discovery',
  },
  {
    label: 'sales pitch',
    value: 'sales pitch',
  },
  {
    label: 'deal closing',
    value: 'deal closing',
  },
];
export enum DealSourceEnum {
  DIRECT = 'Direct',
  DIGITAL = 'Digital',
  BROKER = 'Broker',
  AMBASSADOR = 'Ambassador',
}

export enum FeedbackEnum {
  INERESTED = 'Interested',
  FOLLOW_UP = 'Follow Up',
  NO_ANSWER = 'No Answer',
}

export interface DealCreationResponse {
  customer_source_id: string;
  customer_inquiry: number;
  destination_source_id: string;
  email: string;
  first_name: string;
  mobile: string;
  interestedin_source_id: string;
  last_name: string;
  lead_source_id: string;
  channel_id: number;
  feedback_id: number;
  portal_comments: string;
  preferred_communication_method: string;
  preferred_communication_time: string;
}
export interface DealInterface {
  logical_name: string;
  channel: string;
  owner: string;
  lead_source_id: string;
  phone: string;
  name: string;
  sales_feedback: FeedbackEnum;
  lead_source: DealSourceEnum | null;
  lead_expiration_status?: string;
  lead_expiration_date?: string;
  lead_expiration_date_timestamp?: number;
  last_engagement_date_timestamp?: number;
  source_created_at?: string;
  source_created_at_timestamp?: number;
  ambassador?: string;
  broker_name?: string;
  tlscsr_comment?: string;
  followup_date?: string;
  followup_date_timestamp?: number;
  last_contacted_date?: string;
  last_engagement_date?: string;
  [key: string]: string | number | null | undefined;
}

export enum PipelineStageEnum {
  LEAD_DISCOVERY = 'Discovery',
  SALES_PITCH = 'Sales Pitch',
  DEAL_CLOSING = 'Closing',
  PENDING_RESERVATION = 'Pending Reservation',
  RESERVED = 'Reserved',
  LOST = 'Lost',
}

export enum MontenegroPipelineStageEnum {
  FIRST_STAGE = '10%',
  SECOND_STAGE = '50%',
  THIRD_STAGE = '70%',
  FOURTH_STAGE = '90%',
  LAST_STAGE = '100%',
  LOST = 'Lost',
}

export enum LeadStatus {
  LEAD_DISCOVERY = 'Lead Discovery',
  SALES_PITCH = 'Sales Pitch',
  DEAL_CLOSING = 'Deal Closing',
}
export interface OptionalFields {
  logical_name: string;
  source_created_at: string;
}

export interface WalkIn extends OptionalFields {
  office_source_id: string;
  office_name: string;
}

export interface Competition extends OptionalFields {
  competitor_source_id: string;
  competitor_name: string;
}

export interface SystemUsers {
  logical_name: string;
  full_name: string;
  owner_source_id: string;
}

export interface DesiredUnit extends OptionalFields {
  unit_source_id: string;
  project_name: string;
  currency: string;
  unit_price: string;
  unit_type: string;
  unit_name: string;
  building_status: string;
  destination_name: string;
  unit_category: string;
}

export interface Ambassador extends OptionalFields {
  ambassador_source_id: string;
  ambassador_name: string;
}

export interface Broker extends OptionalFields {
  broker_source_id: string;
  state_code: string;
  phone: string;
  broker_name: string;
  account_manager_name: string;
  broker_type: string;
}

export interface Event extends OptionalFields {
  event_source_id: string;
  event_name: string;
}

export interface Exhibition extends OptionalFields {
  exhibition_source_id: string;
  exhibition_name: string;
}

export interface Source extends OptionalFields {
  source_source_id: string;
  source_name: string;
}

export interface SubSource extends OptionalFields {
  subsource_source_id: string;
  subsource_name: string;
  state_code: string;
}

export interface Country {
  ldv_country: string;
  country_source_id: string;
  country_name: string;
  country_code: string;
  country_key: string;
  region_id: string;
  state_code: string;
}
export interface Currency {
  id: number;
  country: {
    id: number;
    slug: string;
    name: string;
  };
  source_id: string;
  code: string;
  name: string;
  rate: string;
}

export interface NewDealFormInterface {
  first_name: string;
  last_name: string;
  phone: string;
  phone_2?: string;
  phone_3?: string;
  email?: string;
  feedback_id?: number;
  broker_source_id: string;
  preferred_communication_method: string;
  preferred_communication_time: string;
  customer_inquiry?: string;
  portal_comments: string;
  destination_slug: string;
  interested_in_unit_id?: string;
}
export interface NewDealEgyptFormInterface extends NewDealFormInterface {
  channel_id: string;
  event_source_id: string;
  ambassador_source_id: string;
  office_source_id: string;
  exhibition_source_id: string;
  budget?: null;
  customer: string;
  referral_customer_name: string;
  referral_unit_destination: string;
  unit_number: string;
}

export interface NewDealMontenegroFormInterface extends NewDealFormInterface {
  salesman_country_id: number;
  lead_source_source_id: string;
  lead_subsource_source_id: string;
  lead_country_source_id: string;
  lead_type_id: number;
  system_user_source_id?: string;
}

export interface CommonDealInputInterface {
  firstName: string;
  lastName: string;
  phoneNumbers: { countryCode: string; phone: string }[];
  feedback: OptionValue | null;
  contactType?: OptionValue | null;
  contactTime?: OptionValue | null;
  additionalInfo?: string;
  destination: OptionValue | null;
}
export interface NewDealMontenegroInputInterface
  extends CommonDealInputInterface {
  country: OptionValue | null;
  source: OptionValue | null;
  subSource: OptionValue | null;
  broker: OptionValue | null;
  leadType: OptionValue | null;
  systemUser: OptionValue | null;
}

export interface NewDealEgyptInputInterface extends CommonDealInputInterface {
  email: string;
  unitName: string;
  unitType?: string;
  clientBudget?: string;
  leadChannel: OptionValue | null;
  ambassadorName: OptionValue | null;
  walkInName: OptionValue | null;
  brokerName: OptionValue | null;
  eventsName: OptionValue | null;
  exhibitionName: OptionValue | null;
  customer: string;
  referralCustomerName: string;
  referralUnitDestination: string;
  unitNumber: string;
  customerInquiry?: OptionValue | null;
}
export interface Meetings {
  logical_name: string;
  meeting_source_id: string;
  meeting_date: string;
  meeting_location: null;
  pipeline_source_id: string;
  lead_name: string;
  meeting_name: string;
  meeting_comment: string;
}

export interface UpdatePipeline {
  lead_source_id: string;
  customer_source_id: string;
  destination_slug: string;
  feedback_id?: number;
  sales_comment: string;
  objections_handled: boolean;
  objection_id?: number | null;
  objection_notes?: string;
  unit_id?: string;
  closure_date?: number;
  competitor_source_id?: string;
  initial_meeting_date?: number;
  unit_category_id?: number | null;
}

export interface UpdateMontenegroPipeline {
  is_unit_type_selected?: boolean;
  is_interest_identified?: boolean;
  is_desired_unit_selected?: boolean;
  is_experssion_of_interest?: boolean;
  lead_source_id?: string;
  feedback_id?: number;
  visit_checkbox?: boolean;
  visit_date?: string;
  visit_type?: string;
  unit_id?: string | number;
  payments_agreement?: boolean;
  customer_source_id: string;
}

export interface UpdatePipelineInputInterface {
  objection?: boolean;
  objectionReason?: OptionValue | null;
  objectionNotes?: string;
  competitor?: OptionValue | null;
  desiredUnit?: OptionValue | null;
  selectedDate?: string;
  destinationSlug?: string;
}
export interface Feedback {
  logical_name: string;
  owner_id: string;
  feedback_source_id: string;
  feedback_title: string;
  feedback: string;
  pipeline_title: string;
  source_updated_at: string;
  status: string;
  feedback_comment: string;
  source_created_at: string;
}

export enum DateOptions {
  ALL = 'All',
  TODAY = 'Today',
  LAST_WEEK = 'Last Week',
  THIS_MONTH = 'This Month',
  THIS_YEAR = 'This Year',
}

export interface DateFilter {
  startDate: Date | null;
  endDate: Date | null;
}

type DateFilterKey =
  | 'engagementDate'
  | 'leadExpirationDate'
  | 'createdOn'
  | 'followupDate';
export type DateFilters = Partial<Record<DateFilterKey, DateFilter>>;

export interface AddUnitType {
  salesman_country_id: number;
  unit_type_id: number;
  count: number;
}

export interface PipleineLost {
  lead_source_id: string;
  customer_source_id: string;
  justification_id: string;
  issue?: string;
  more_details?: string;
  lost_competitor_source_id?: string;
  further_details?: string;
  expected_budget?: string;
  currency?: string;
  further_budget_availability?: string;
  expected_date?: string;
  future_opportunity?: string;
  define_issue?: string;
  concern?: string;
  desired_product?: string;
  product_details?: string;
  clarify_reasons?: string;
  competitor_advantage?: string;
}
