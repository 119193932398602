import { useState } from 'react';
import { useUserPipelineContext } from '../../context/user-pipeline-context';
import LeadActivityLog from '../lead-activity-log/lead-activity-log';
import styles from './montenegro-lead-stages.module.scss';
import CloseDealMontenegro from '../close-deal-montenegro/close-deal-montenegro';
import { MontenegroPipelineStageEnum } from '../../definitions/interfaces/deals.interface';
import SubmitFeedback from '../editable-feedback/submit-feedback';
import ScheduledMeetings from '../deal-scheduled-meetings/deal-scheduled-meetings';

export function FourthStage() {
  const { selectedPipeline } = useUserPipelineContext();

  const [refetchActivityLog, setRefetchActivityLog] = useState(true);
  const refetchLogs = () => setRefetchActivityLog(!refetchActivityLog);

  if (!selectedPipeline) return;
  return (
    <div className={styles['discovery-sections']}>
      <CloseDealMontenegro />
      <ScheduledMeetings
        selectedPipeline={selectedPipeline}
        dealStatus={MontenegroPipelineStageEnum.FOURTH_STAGE}
      />
      <SubmitFeedback
        refetchLogs={refetchLogs}
        dealStatus={MontenegroPipelineStageEnum.FOURTH_STAGE}
      />
      <LeadActivityLog
        pipelineSourceId={selectedPipeline.pipeline_source_id}
        refetchActivityLog={refetchActivityLog}
      />
    </div>
  );
}
