import { SelectDropdown } from '@orascom/common-components';
import styles from './choose-units.module.scss';
import { ErrorMessage, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { SetStateAction } from 'react';
import { ChooseUnitsInterface } from '../../definitions/interfaces/montenegro-lead-stages.interface';

interface PropertyListDropdownProps {
  loadingUnits: boolean;
  desiredUnits:
    | {
        label: string;
        value: number;
      }[]
    | undefined;
  handleAddSelectedUnits: (selectedId: number) => void;
  setSearchedInput: (value: SetStateAction<string>) => void;
  setAddUnits?: (value: SetStateAction<boolean>) => void;
  chooseUnitsHandler: (selectedId: number) => void;
}

export default function PropertyListDropdown(props: PropertyListDropdownProps) {
  const { t } = useTranslation();
  const { values, errors, touched, setFieldValue } =
    useFormikContext<ChooseUnitsInterface>();

  return (
    <div className={styles['select-section']}>
      <label htmlFor="desiredUnit">{t('chooseUnit')}</label>
      <SelectDropdown
        isLoading={props.loadingUnits}
        name="desiredUnit"
        className={`${styles['select']}`}
        invalid={!!errors.desiredUnit && touched.desiredUnit}
        options={props.desiredUnits}
        placeholder={t('selectPlaceholder')}
        onChange={(val) => {
          setFieldValue('desiredUnit', val?.value);
          props.handleAddSelectedUnits(Number(val?.value));
          props.chooseUnitsHandler(Number(val?.value));
          props.setAddUnits?.(true);
        }}
        selectedOption={values.desiredUnit || null}
        isClearable
        onInputChange={(val) => {
          props.setSearchedInput(val);
        }}
      />
      <ErrorMessage
        name="desiredUnit"
        component="div"
        className="form__error"
      />
    </div>
  );
}
