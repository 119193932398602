import { Route, UserRole } from '@orascom/api-interfaces';
import Login from '../pages/login/login';
import Destination from '../pages/destination/destination';
import MyDeals from '../pages/my-deals/my-deals';
import NewDeal from '../pages/new-deal/new-deal';
import DealDetailsPage from '../pages/deal-details/deal-details-page';
import Updates from '../pages/updates/updates';
import PrimaryUnits from '../pages/primary-units/primary-units';
import ResaleUnits from '../pages/resale-units/resale-units';
import { UnitDetails } from '../pages/unit-details/unit-details';
import ComparePropertiesPage from '../pages/compare-properties/compare-properties';
import DisqualifyLead from '../pages/disqualify-lead/disqualify-lead';
import MySales from '../pages/my-sales/my-sales';
import MyCustomers from '../pages/my-customers/my-customers';
import QualifyCustomer from '../pages/qualify-customer/qualify-customer';
import CustomerDetailsPage from '../pages/customer-details/customer-details-page';
import CreateSalePage from '../pages/create-sale/create-sale';
import SavedUnits from '../pages/saved-units/saved-units';
import Overview from '../pages/overview/overview';
import NewProperties from '../pages/new-properties/new-properties';
import LostLead from '../pages/lost-lead/lost-lead';

export const ROUTES: Record<string, Route> = {
  Login: {
    path: '/login',
    component: Login,
    public: true,
    role: UserRole.ALL,
  },
  Destination: {
    path: '/destination/:destinationSlug',
    getPath: (destinationSlug: string) => `/destination/${destinationSlug}`,
    component: Destination,
    public: false,
  },
  Overview: {
    path: '/',
    component: Overview,
    public: false,
  },
  MyDeals: {
    path: '/deals',
    component: MyDeals,
    public: false,
  },
  NewProperties: {
    path: '/new-properties',
    component: NewProperties,
    public: false,
  },
  NewDeal: {
    path: '/new-lead',
    component: NewDeal,
    public: false,
  },
  LeadPipelines: {
    path: '/leads/:dealId/pipelines/:countryId',
    getPath: (leadId: string, countryId: string) =>
      `/leads/${leadId}/pipelines/${countryId}`,
    component: DealDetailsPage,
    public: false,
  },
  LeadCreatePipelines: {
    path: '/leads/:dealId/:countryId/pipelines/create',
    getPath: (leadId: string, countryId: string) =>
      `/leads/${leadId}/${countryId}/pipelines/create`,
    component: DealDetailsPage,
    public: false,
  },
  DisqualifyLead: {
    path: '/leads/:leadId/disqualify-lead/:countryId',
    getPath: (leadId: string, countryId: string) =>
      `/leads/${leadId}/disqualify-lead/${countryId}`,
    component: DisqualifyLead,
    public: false,
  },
  LostLead: {
    path: '/leads/:leadId/lost-lead/:countryId',
    getPath: (leadId: string, countryId: string) =>
      `/leads/${leadId}/lost-lead/${countryId}`,
    component: LostLead,
    public: false,
  },
  LostCustomer: {
    path: '/leads/:customerId/lost-lead/:countryId',
    getPath: (customerId: string, countryId: string) =>
      `/leads/${customerId}/lost-lead/${countryId}`,
    component: LostLead,
    public: false,
  },
  Notifications: {
    path: '/notifications',
    component: Updates,
    public: false,
  },
  PrimaryUnits: {
    path: '/primary-units',
    component: PrimaryUnits,
    public: false,
  },
  CompareProperties: {
    path: '/compare-properties',
    component: ComparePropertiesPage,
    public: false,
  },
  ResaleUnits: {
    path: '/resale-units',
    component: ResaleUnits,
    public: false,
  },
  UnitDetails: {
    path: '/unit-details/:unitId',
    getPath: (unitId: string) => `/unit-details/${unitId}`,
    component: UnitDetails,
    public: false,
  },
  UnitDeal: {
    path: '/unit-details/:unitId/new-deal',
    getPath: (unitId: string) => `/unit-details/${unitId}/new-deal`,
    component: NewDeal,
    public: false,
  },
  MyCustomers: {
    path: '/customers',
    component: MyCustomers,
    public: false,
  },
  MySales: {
    path: '/sales',
    component: MySales,
    public: false,
  },
  QualifyCustomer: {
    path: '/qualify-customer/:countryId/:leadId',
    getPath: (countryId: string, leadId: string) =>
      `/qualify-customer/${countryId}/${leadId}`,
    component: QualifyCustomer,
    public: false,
  },
  CustomerPipelines: {
    path: '/customers/:customerId/pipelines/:countryId',
    getPath: (customerId: string, countryId: string) =>
      `/customers/${customerId}/pipelines/${countryId}`,
    component: CustomerDetailsPage,
    public: false,
  },
  CustomerCreatePipelines: {
    path: '/customers/:customerId/:countryId/pipelines/create',
    getPath: (customerId: string, countryId: string) =>
      `/customers/${customerId}/${countryId}/pipelines/create`,
    component: CustomerDetailsPage,
    public: false,
  },
  CreateSale: {
    path: '/customers/:customerId/pipelines/:pipelineId/:countryId/create-sale',
    getPath: (customerId: string, pipelineId: string, countryId: string) =>
      `/customers/${customerId}/pipelines/${pipelineId}/${countryId}/create-sale`,
    component: CreateSalePage,
    public: false,
  },
  SavedUnits: {
    path: '/saved-units',
    component: SavedUnits,
    public: false,
  },
};
