import styles from './unit-type.module.scss';
import { unitTypeOptions } from '../../utils/new-deal.utils';
import { NavyButton, SelectDropdown } from '@orascom/common-components';
import { useTranslation } from 'react-i18next';
import { Deal as DealUtils } from '../../utils/deal.utils';
import { AddUnitType } from '../../definitions/interfaces/deals.interface';
import { useContext, useState } from 'react';
import { COUNTRY_SELECTED_CONTEXT } from '../../context/country-selected-context';
import { useUserPipelineContext } from '../../context/user-pipeline-context';
import plusIcon from '../../assets/icons/plus.svg';
import { ErrorMessage, Form, Formik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';

export function UnitType({
  fetchAndSetPipelines,
}: {
  fetchAndSetPipelines: (
    userId: string,
    newPipelineId: string
  ) => Promise<{
    phaseChanged: boolean;
  }>;
}) {
  const { t } = useTranslation();
  const activeCountry = useContext(COUNTRY_SELECTED_CONTEXT);

  const [loading, setLoading] = useState(false);

  const { selectedPipeline, leadId, customerId } = useUserPipelineContext();

  const isDimmed =
    selectedPipeline?.pipeline_type === 'Single Unit' &&
    selectedPipeline?.unit_type_count_rollup === 1;

  const onAddingUnitSuccessfully = async () => {
    if (selectedPipeline) {
      const userId = customerId ?? leadId;

      if (!userId) return;
      const { phaseChanged } = await fetchAndSetPipelines(
        userId,
        selectedPipeline?.pipeline_source_id
      );

      if (phaseChanged) window.scrollTo(0, 0);
    }
  };

  const handleSubmit = async (
    values: { unitType: number | null; quantity: number | null },
    { resetForm }: { resetForm: () => void }
  ) => {
    if (
      selectedPipeline?.pipeline_type === 'Single Unit' &&
      (values.quantity ?? 0) > 1
    ) {
      toast.error('Unit type cannot exceed 1 in single unit pipeline');
      return;
    }
    setLoading(true);
    const mappedData: AddUnitType = {
      unit_type_id: values.unitType ?? 0,
      count: values.quantity ?? 0,
      salesman_country_id: Number(activeCountry.activeCountry?.value),
    };
    if (selectedPipeline) {
      DealUtils.addUnitTypeTpPipeline(
        selectedPipeline?.pipeline_source_id,
        mappedData,
        activeCountry.activeCountry?.value.toString() ?? ''
      )
        .then((res) => {
          toast.success(res.message);
          onAddingUnitSuccessfully();
          resetForm();
        })
        .catch((res) => toast.error(res.message.message))
        .finally(() => setLoading(false));
    }
  };

  return (
    <div className={isDimmed ? styles['dimmed'] : ''}>
      <Formik
        initialValues={{
          unitType: null,
          quantity: null,
        }}
        validationSchema={yup.object({
          unitType: yup.number().nullable().required('Required'),
          quantity: yup
            .number()
            .nullable()
            .required(t('Required'))
            .moreThan(0, 'Count should be a positive number'),
        })}
        onSubmit={handleSubmit}
      >
        {({ isValid, setFieldValue, values }) => {
          return (
            <Form>
              <div className={styles['unit-types']}>
                <div className="form__input">
                  <label htmlFor="unitType">{t('selectUnitType')}</label>
                  <SelectDropdown
                    name="unitType"
                    options={unitTypeOptions}
                    placeholder={t('selectPlaceholder')}
                    onChange={(value) => {
                      setFieldValue('unitType', Number(value?.value));
                    }}
                    className={styles['select']}
                    selectedOption={unitTypeOptions.find(
                      (option) => option.value === values.unitType
                    )}
                    disabled={isDimmed}
                  />
                  <ErrorMessage
                    name="unitType"
                    component="div"
                    className="form__error"
                  />
                </div>
                <div className="form__input">
                  <label htmlFor="quantity">{t('selectQuantity')}</label>
                  <input
                    type="number"
                    name="quantity"
                    id="quantity"
                    inputMode="numeric"
                    value={values.quantity || ''}
                    onChange={(e) => {
                      setFieldValue(
                        'quantity',
                        e.target.value ? Number(e.target.value) : null
                      );
                    }}
                  />
                  <ErrorMessage
                    name="quantity"
                    component="div"
                    className="form__error"
                  />
                </div>
              </div>
              <NavyButton
                type="submit"
                className={styles['add-btn']}
                disabled={!isValid || loading || isDimmed}
              >
                <img src={plusIcon} alt="add" />
                {t('addUnitType')}
              </NavyButton>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
