import { Dispatch, SetStateAction } from 'react';
import styles from './leads-active-tabs.module.scss';
import TabButton from './tab-button';

interface ActiveTabsProps {
  tableLoading: boolean;
  activeTab: number;
  setActiveTab: Dispatch<SetStateAction<number>>;
  handleTabBtn: (tabId: number) => void;
  tabData: {
    id: number;
    label: string;
  }[];
}
export function ActiveTabs(props: ActiveTabsProps) {
  return (
    <div className={styles['tabs']}>
      {props.tabData.map((tab) => (
        <TabButton
          key={tab.id}
          label={tab.label}
          tabId={tab.id}
          activeTab={props.activeTab}
          disabled={props.tableLoading}
          onClick={props.handleTabBtn}
        />
      ))}
    </div>
  );
}

export default ActiveTabs;
