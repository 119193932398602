import { Payload } from '@orascom/api-interfaces';
import { ENDPOINTS } from './endpoints';
import { Network } from './network';
import {
  Ambassador,
  Exhibition,
  WalkIn,
  Event as EventType,
  DealInterface,
  Broker,
  Meetings,
  UpdatePipeline,
  Feedback,
  DesiredUnit,
  DealCreationResponse,
  Competition,
  Currency,
  Source,
  SubSource,
  Country,
  NewDealMontenegroFormInterface,
  NewDealEgyptFormInterface,
  AddUnitType,
  UpdateMontenegroPipeline,
  PipleineLost,
  SystemUsers,
} from '../definitions/interfaces/deals.interface';
import {
  DisqalifyLeadPayload,
  FeedbackSubmission,
  LeadCreationResponse,
  LeadDetails,
  LeadDiscoveryInterface,
  PipelineDetails,
} from '../definitions/interfaces/lead-stages.interface';

export class Deal {
  public static createLead(
    leadData: NewDealEgyptFormInterface | NewDealMontenegroFormInterface,
    countryId: string
  ): Promise<Payload<Payload<DealCreationResponse>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.createLead.url}`,
      {
        method: ENDPOINTS.createLead.method,
        body: JSON.stringify(leadData),
      },
      undefined,
      countryId
    );
  }

  public static createLeadDiscoveryPipeline(
    leadData: LeadDiscoveryInterface,
    countryId: string
  ): Promise<Payload<Payload<LeadCreationResponse>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.createLeadDiscoveryPipeline.url}`,
      {
        method: ENDPOINTS.createLead.method,
        body: JSON.stringify(leadData),
      },
      undefined,
      countryId
    );
  }

  public static disqualifyLead(
    leadId: string,
    countryId: string,
    leadData: DisqalifyLeadPayload
  ) {
    return Network.fetch(
      `${
        process.env['NX__SALESMAN_API_ENDPOINT']
      }${ENDPOINTS.disqualifyLead.url(leadId)}`,
      {
        method: ENDPOINTS.createLead.method,
        body: JSON.stringify(leadData),
      },
      undefined,
      countryId
    );
  }

  public static getLeads(
    searchQuery: string,
    countryId: string
  ): Promise<Payload<Payload<DealInterface[]>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.getLeads.url(
        searchQuery
      )}`,
      {
        method: ENDPOINTS.getLeads.method,
      },
      undefined,
      countryId
    );
  }

  public static getLeadDetails(
    id: string,
    countryId?: string
  ): Promise<Payload<Payload<LeadDetails>>> {
    return Network.fetch(
      `${
        process.env['NX__SALESMAN_API_ENDPOINT']
      }${ENDPOINTS.getLeadDetails.url(id)}`,
      {
        method: ENDPOINTS.getLeads.method,
      },
      undefined,
      countryId
    );
  }

  public static getPipelineDetails(
    leadId: string,
    countryId: string
  ): Promise<Payload<PipelineDetails[]>> {
    return Network.fetch(
      `${
        process.env['NX__SALESMAN_API_ENDPOINT']
      }${ENDPOINTS.getPipelineDetails.url(leadId)}`,
      {
        method: ENDPOINTS.getPipelineDetails.method,
      },
      undefined,
      countryId
    );
  }

  public static getAmbassadorsList(
    countryId: string
  ): Promise<Payload<Payload<Ambassador[]>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.getAmbassadorsList.url}`,
      {
        method: ENDPOINTS.getAmbassadorsList.method,
      },
      undefined,
      countryId
    );
  }

  public static getEventsList(
    countryId: string
  ): Promise<Payload<Payload<EventType[]>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.getEventsList.url}`,
      {
        method: ENDPOINTS.getEventsList.method,
      },
      undefined,
      countryId
    );
  }

  public static getExhibitionsList(
    countryId: string
  ): Promise<Payload<Payload<Exhibition[]>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.getExhibitionsList.url}`,
      {
        method: ENDPOINTS.getExhibitionsList.method,
      },
      undefined,
      countryId
    );
  }

  public static getWalkInList(
    countryId: string
  ): Promise<Payload<Payload<WalkIn[]>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.getWalkInList.url}`,
      {
        method: ENDPOINTS.getWalkInList.method,
      },
      undefined,
      countryId
    );
  }

  public static getCompetitionList(
    countryId: string
  ): Promise<Payload<Payload<Competition[]>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.getCompetitionList.url}`,
      {
        method: ENDPOINTS.getCompetitionList.method,
      },
      undefined,
      countryId
    );
  }

  public static listDesiredUnit(): Promise<Payload<Payload<DesiredUnit[]>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.listDesiredUnit.url}`,
      {
        method: ENDPOINTS.listDesiredUnit.method,
      }
    );
  }

  public static getBrokerList(
    countryId: string
  ): Promise<Payload<Payload<Broker[]>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.getBrokerList.url}`,
      {
        method: ENDPOINTS.getBrokerList.method,
      },
      undefined,
      countryId
    );
  }

  public static getLeadSources(
    countryId: string
  ): Promise<Payload<Payload<Source[]>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.getLeadSources.url}`,
      {
        method: ENDPOINTS.getLeadSources.method,
      },
      undefined,
      countryId
    );
  }

  public static getLeadSubSources(
    sourceId: string,
    countryId: string
  ): Promise<Payload<Payload<SubSource[]>>> {
    return Network.fetch(
      `${
        process.env['NX__SALESMAN_API_ENDPOINT']
      }${ENDPOINTS.getLeadSubSources.url(sourceId)}`,
      {
        method: ENDPOINTS.getLeadSubSources.method,
      },
      undefined,
      countryId
    );
  }

  public static getLeadCountries(
    countryId: string
  ): Promise<Payload<Payload<Country[]>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.getLeadCountries.url}`,
      {
        method: ENDPOINTS.getLeadCountries.method,
      },
      undefined,
      countryId
    );
  }

  public static listFeedback(
    pipelineSourceId: string,
    countryId: string
  ): Promise<Payload<Payload<Feedback[]>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.listFeedback.url(
        pipelineSourceId
      )}`,
      {
        method: ENDPOINTS.listFeedback.method,
      },
      undefined,
      countryId
    );
  }

  public static listMontenegroFeedback(
    leadSourceId: string,
    countryId: string
  ): Promise<Payload<Payload<Feedback[]>>> {
    return Network.fetch(
      `${
        process.env['NX__SALESMAN_API_ENDPOINT']
      }${ENDPOINTS.listMontenegroFeedback.url(leadSourceId)}`,
      {
        method: ENDPOINTS.listMontenegroFeedback.method,
      },
      undefined,
      countryId
    );
  }

  public static listMeetings(
    pipelineSourceId: string,
    countryId: string
  ): Promise<Payload<Payload<Meetings[]>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.listMeetings.url(
        pipelineSourceId
      )}`,
      {
        method: ENDPOINTS.listMeetings.method,
      },
      undefined,
      countryId
    );
  }

  public static createMeeting(
    pipelineSourceId: string,
    meetingDate: number,
    destinationSlug: string,
    countryId: string,
    meetingNotes?: string,
    meetingType?: number
  ): Promise<Payload<Payload<Meetings[]>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.createMeeting.url(
        pipelineSourceId
      )}`,
      {
        method: ENDPOINTS.createMeeting.method,
        body: JSON.stringify({
          meeting_date: meetingDate,
          destination_slug: destinationSlug,
          meeting_comment: meetingNotes ?? null,
          meeting_type: meetingType ?? null,
        }),
      },
      undefined,
      countryId
    );
  }

  public static updateDealPipeline(
    pipelineSourceId: string,
    leadData: UpdatePipeline | FeedbackSubmission | UpdateMontenegroPipeline,
    countryId: string
  ): Promise<Payload<Payload<LeadCreationResponse>>> {
    return Network.fetch(
      `${
        process.env['NX__SALESMAN_API_ENDPOINT']
      }${ENDPOINTS.updateDealPipeline.url(pipelineSourceId)}`,
      {
        method: ENDPOINTS.updateDealPipeline.method,
        body: JSON.stringify(leadData),
      },
      undefined,
      countryId
    );
  }

  public static getCurrenciesList(
    country: string
  ): Promise<Payload<Currency[]>> {
    return Network.fetch(
      `${
        process.env['NX__SALESMAN_API_ENDPOINT']
      }${ENDPOINTS.getCurrenciesList.url(country)}`,
      {
        method: ENDPOINTS.getCurrenciesList.method,
      }
    );
  }

  public static addUnitTypeTpPipeline(
    pipelineSourceId: string,
    unitTypeData: AddUnitType,
    countryId: string
  ): Promise<Payload<Payload>> {
    return Network.fetch(
      `${
        process.env['NX__SALESMAN_API_ENDPOINT']
      }${ENDPOINTS.addUnitTypeTpPipeline.url(pipelineSourceId)}`,
      {
        method: ENDPOINTS.addUnitTypeTpPipeline.method,
        body: JSON.stringify(unitTypeData),
      },
      undefined,
      countryId
    );
  }

  public static markPipelineLost(
    pipelineSourceId: string,
    pipelineLostData: PipleineLost,
    countryId: string
  ): Promise<Payload<Payload>> {
    return Network.fetch(
      `${
        process.env['NX__SALESMAN_API_ENDPOINT']
      }${ENDPOINTS.markPipelineLost.url(pipelineSourceId)}`,
      {
        method: ENDPOINTS.markPipelineLost.method,
        body: JSON.stringify(pipelineLostData),
      },
      undefined,
      countryId
    );
  }

  public static listSystemUsers(
    countryId: string
  ): Promise<Payload<Payload<SystemUsers[]>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.listSystemUsers.url}`,
      {
        method: ENDPOINTS.listSystemUsers.method,
      },
      undefined,
      countryId
    );
  }
}
