import { ErrorMessage, Field, FormikErrors, FormikTouched } from 'formik';
import {
  LeadDiscoveryInput,
  LostPipelineInput,
} from '../../definitions/interfaces/lead-stages.interface';
import { SelectDropdown } from '@orascom/common-components';
import {
  booleanDropdownOptions,
  competitorAdvantageOptions,
  concernOptions,
  ConcernOptionsEnum,
  Deal as DealUtils,
  defineIssuesOptions,
  DefineIssueValues,
  desiredProductOptions,
  DesiredProductOptionsEnum,
  issuesOptions,
} from '../../utils/deal.utils';
import { useTranslation } from 'react-i18next';
import styles from './lead-feedback.module.scss';
import { OptionValue } from '@orascom/api-interfaces';
import { useContext, useEffect, useRef, useState } from 'react';
import { COUNTRY_SELECTED_CONTEXT } from '../../context/country-selected-context';
import Calendar from 'react-calendar';
import LeftArrowIcon from '../../assets/icons/calendar-left-arrow.svg';
import RightArrowIcon from '../../assets/icons/calendar-right-arrow.svg';
import arrowIcon from '../../assets/icons/left-arrow.svg';
import { format } from 'date-fns';

type Value = Date | null;

export interface ConditionalFieldsProps {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<LeadDiscoveryInput | LostPipelineInput>>;
  paymentTerms: boolean;
  competition: boolean;
  priceConcern: boolean;
  productConcern: boolean;
  others: boolean;
  values: LostPipelineInput | LeadDiscoveryInput;
  errors: FormikErrors<LostPipelineInput | LeadDiscoveryInput>;
  touched: FormikTouched<LostPipelineInput | LeadDiscoveryInput>;
}

export function ConditionalFields(props: ConditionalFieldsProps) {
  const { t } = useTranslation();
  const {
    setFieldValue,
    paymentTerms,
    competition,
    priceConcern,
    productConcern,
    others,
    values,
    errors,
    touched,
  } = props;
  const activeCountry = useContext(COUNTRY_SELECTED_CONTEXT);
  const [competitions, setCompetitions] = useState<OptionValue[]>([]);
  const [currencies, setCurrencies] = useState<OptionValue[]>([]);

  useEffect(() => {
    DealUtils.getCompetitionList(
      activeCountry.activeCountry?.value.toString() ?? ''
    )
      .then((res) => {
        setCompetitions(res);
      })
      .catch((err) => {
        console.log(err);
      });
    DealUtils.getCurrenciesList(
      activeCountry.activeCountry?.value.toString() ?? ''
    )
      .then((res) => {
        setCurrencies(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [selectedExpectedDate, setSelectedExpectedDate] = useState<Value>();
  const dialogRefExpectedDate = useRef<HTMLDialogElement | null>(null);

  const toggleExpectedDialog = () => {
    if (dialogRefExpectedDate.current) {
      if (dialogRefExpectedDate.current.open) {
        dialogRefExpectedDate.current.close();
      } else {
        dialogRefExpectedDate.current.show();
      }
    }
  };

  return (
    <>
      {paymentTerms ? (
        <>
          <label htmlFor="issue">{t('issue')}</label>
          <SelectDropdown
            name="issue"
            options={issuesOptions}
            placeholder={t('selectPlaceholder')}
            className={`${styles['select']}`}
            onChange={(value) => setFieldValue('issue', value?.value)}
            selectedOption={issuesOptions.find((option) => {
              return option.value === values.issue;
            })}
          />
          <ErrorMessage name="issue" component="div" className="form__error" />
          <div className="form__input">
            <label htmlFor="moreDetails">{t('moreDetails')}</label>
            <Field
              className={styles['textarea']}
              name="moreDetails"
              type="text"
              as="textarea"
              placeholder={t('enterDetails')}
            />
            <ErrorMessage
              name="moreDetails"
              component="div"
              className="form__error"
            />
          </div>
        </>
      ) : null}
      {competition ? (
        <>
          <div>
            <label htmlFor="lostCompetitorSourceId">
              {t('lostcompetitorid')}
            </label>
            <SelectDropdown
              name="lostCompetitorSourceId"
              options={competitions}
              placeholder={t('selectPlaceholder')}
              className={`${styles['select']}`}
              invalid={
                touched.lostCompetitorSourceId &&
                !!errors.lostCompetitorSourceId
              }
              onChange={(value) => {
                setFieldValue('lostCompetitorSourceId', value?.value);
              }}
              selectedOption={competitions.find((option) => {
                return option.value === values.lostCompetitorSourceId;
              })}
            />
            <ErrorMessage
              name="lostCompetitorSourceId"
              component="div"
              className="form__error"
            />
          </div>
          <div className="form__input">
            <label htmlFor="furtherDetails">{t('furtherDetails')}</label>
            <Field
              name="furtherDetails"
              className={styles['feedback-text']}
              type="text"
              as="textarea"
              placeholder={t('addFurtherDetails')}
            />
            <ErrorMessage
              name="furtherDetails"
              component="div"
              className="form__error"
            />
          </div>
          <div>
            <label htmlFor="competitorAdvantage">
              {t('competitorAdvantage')}
            </label>
            <SelectDropdown
              name="competitorAdvantage"
              options={competitorAdvantageOptions}
              placeholder={t('selectPlaceholder')}
              className={`${styles['select']} ${
                errors?.competitorAdvantage && touched?.competitorAdvantage
                  ? 'error'
                  : ''
              }`}
              invalid={
                touched.competitorAdvantage && !!errors.competitorAdvantage
              }
              onChange={(value) =>
                setFieldValue('competitorAdvantage', value?.value)
              }
              selectedOption={competitorAdvantageOptions.find((option) => {
                return option.value === Number(values.competitorAdvantage);
              })}
            />
            <ErrorMessage
              name="competitorAdvantage"
              component="div"
              className="form__error"
            />
          </div>
        </>
      ) : null}
      {priceConcern ? (
        <>
          <div className="form__input">
            <label htmlFor="expectedBudget">{t('expectedbudget')}</label>
            <Field
              name="expectedBudget"
              className={styles['feedback-text-small']}
              type="number"
              placeholder={t('addExcpectedBudget')}
            />
            <ErrorMessage
              name="expectedBudget"
              component="div"
              className="form__error"
            />
          </div>
          <div>
            <label htmlFor="currency">{t('currency')}</label>
            <SelectDropdown
              name="currency"
              options={currencies}
              placeholder={t('selectPlaceholder')}
              className={`${styles['select']}`}
              invalid={touched.currency && !!errors.currency}
              onChange={(value) => {
                setFieldValue('currency', value?.value);
              }}
              selectedOption={currencies.find((option) => {
                return option.value === values.currency;
              })}
            />
            <ErrorMessage
              name="currency"
              component="div"
              className="form__error"
            />
          </div>
          <div>
            <label htmlFor="futureBudgetAvailability">
              {t('futurebudgetavailability')}
            </label>
            <SelectDropdown
              name="futureBudgetAvailability"
              options={booleanDropdownOptions}
              placeholder={t('yes/no')}
              className={styles['select']}
              invalid={
                touched.futureBudgetAvailability &&
                !!errors.futureBudgetAvailability
              }
              onChange={(value) => {
                setFieldValue('futureBudgetAvailability', value?.value);
              }}
              selectedOption={booleanDropdownOptions.find((option) => {
                return (
                  option.value === Number(values.futureBudgetAvailability) &&
                  values.futureBudgetAvailability !== ''
                );
              })}
            />
            <ErrorMessage
              name="futureBudgetAvailability"
              component="div"
              className="form__error"
            />
          </div>
        </>
      ) : null}
      {priceConcern && values.futureBudgetAvailability ? (
        <div>
          <label htmlFor="expectedDate">{t('expectedDate')}</label>
          <div className={styles['feedback-modal-wrapper']}>
            <button
              type="button"
              onClick={toggleExpectedDialog}
              className={styles['btn-outline']}
            >
              <p>
                {selectedExpectedDate
                  ? format(new Date(selectedExpectedDate), 'yyyy-MM-dd')
                  : t('selectDate')}
              </p>
              <img
                style={{ height: 'auto', rotate: '-90deg' }}
                src={arrowIcon}
                alt=""
                role="presentation"
              />
            </button>
            <dialog
              ref={dialogRefExpectedDate}
              className={styles['feedback-modal']}
            >
              <Calendar
                value={selectedExpectedDate}
                minDate={new Date()}
                onChange={(date) => {
                  setSelectedExpectedDate(date as Value);
                  setFieldValue(
                    'expectedDate',
                    format(date as Date, 'yyyy-MM-dd')
                  );
                  toggleExpectedDialog();
                }}
                view={'month'}
                prevLabel={
                  <img
                    style={{ height: 'auto' }}
                    src={LeftArrowIcon}
                    alt=""
                    role="presentation"
                  />
                }
                nextLabel={
                  <img
                    style={{ height: 'auto' }}
                    src={RightArrowIcon}
                    alt=""
                    role="presentation"
                  />
                }
              />
            </dialog>
            {touched?.expectedDate && errors?.expectedDate && (
              <div className="form__error">{errors.expectedDate}</div>
            )}
          </div>
        </div>
      ) : null}
      {priceConcern || productConcern ? (
        <div>
          <label htmlFor="futureOpportunity">{t('futureopportunity')}</label>
          <SelectDropdown
            name="futureOpportunity"
            options={booleanDropdownOptions}
            placeholder={t('yes/no')}
            className={styles['select']}
            invalid={touched.futureOpportunity && !!errors.futureOpportunity}
            onChange={(value) => {
              setFieldValue('futureOpportunity', value?.value);
            }}
            selectedOption={booleanDropdownOptions.find((option) => {
              return option.value === values.futureOpportunity;
            })}
          />
          <ErrorMessage
            name="futureOpportunity"
            component="div"
            className="form__error"
          />
        </div>
      ) : null}
      {productConcern ? (
        <div>
          <label htmlFor="defineIssue">{t('defineIssue')}</label>
          <SelectDropdown
            name="defineIssue"
            options={defineIssuesOptions}
            placeholder={t('selectPlaceholder')}
            className={styles['select']}
            onChange={(value) => {
              setFieldValue('concern', null);
              setFieldValue('desiredProduct', null);
              setFieldValue('defineIssue', value?.value);
            }}
            selectedOption={defineIssuesOptions.find((option) => {
              return option.value === Number(values.defineIssue);
            })}
          />
          <ErrorMessage
            name="defineIssue"
            component="div"
            className="form__error"
          />
        </div>
      ) : null}
      {productConcern &&
        ((values.desiredProduct === DesiredProductOptionsEnum.OTHER &&
          values.defineIssue === DefineIssueValues.AVAILABILTY) ||
          (values.defineIssue === DefineIssueValues.CONCERN &&
            values.concern === ConcernOptionsEnum.OTHERS)) && (
          <div>
            <label htmlFor="productDetails">{t('productDetails')}</label>
            <Field
              name="productDetails"
              className={styles['feedback-text']}
              type="text"
              as="textarea"
              placeholder={t('productDetailsPlaceholder')}
            />
            <ErrorMessage
              name="productDetails"
              component="div"
              className="form__error"
            />
          </div>
        )}
      {others ? (
        <div className="form__input">
          <label htmlFor="clarifyReasons">{t('clarifyreasons')}</label>
          <Field
            name="clarifyReasons"
            className={styles['feedback-text']}
            type="text"
            as="textarea"
            placeholder={t('feedbackPlaceholder')}
          />
          <ErrorMessage
            name="clarifyReasons"
            component="div"
            className="form__error"
          />
        </div>
      ) : null}
      {productConcern && values.defineIssue === DefineIssueValues.CONCERN && (
        <div>
          <label htmlFor="concern">{t('concern')}</label>
          <SelectDropdown
            name="concern"
            options={concernOptions}
            placeholder={t('selectPlaceholder')}
            className={styles['select']}
            onChange={(value) => {
              setFieldValue('concern', value?.value);
            }}
            invalid={touched.concern && !!errors.concern}
            selectedOption={concernOptions.find((option) => {
              return option.value === values.concern;
            })}
          />
          <ErrorMessage
            name="concern"
            component="div"
            className="form__error"
          />
        </div>
      )}
      {productConcern &&
        values.defineIssue === DefineIssueValues.AVAILABILTY && (
          <div>
            <label htmlFor="desiredProduct">{t('desiredProduct')}</label>
            <SelectDropdown
              name="desiredProduct"
              options={desiredProductOptions}
              placeholder={t('selectPlaceholder')}
              className={styles['select']}
              onChange={(value) => {
                setFieldValue('desiredProduct', value?.value);
              }}
              invalid={touched.desiredProduct && !!errors.desiredProduct}
              selectedOption={desiredProductOptions.find((option) => {
                return option.value === values.desiredProduct;
              })}
            />
            <ErrorMessage
              name="desiredProduct"
              component="div"
              className="form__error"
            />
          </div>
        )}
    </>
  );
}

export default ConditionalFields;
