/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @author Salma Hefnawy
 * @date 2024-08-12
 * @description implementation of network calls.
 * @filename network.ts
 */

import i18next from 'i18next';

/**
 * class responsible for executing network requests.
 *
 * @export
 * @class network
 */
export class Network {
  /**
   * execute a request to a given url with given options
   *
   * @static
   * @param {string} url url of the request.
   * @param {RequestInit} init set of options sent with the url.
   * @returns {Promise<any>} promise to return the Json response of the request.
   * @memberof Network
   */
  public static fetch(
    url: string,
    init: RequestInit,
    contentType?: string | null,
    countrySlug?: string
  ): Promise<any> {
    const requestInit: RequestInit = {
      mode: 'cors',
      ...init,
      headers: Network.getHeaders(init.headers, true, contentType, countrySlug),
    };

    if (Number(process.env['NX_INCLUDE_FETCH_CREDS'])) {
      requestInit.credentials = 'include';
    }

    return fetch(url, requestInit)
      .catch((error: Error) => {
        return Promise.reject(error);
      })
      .then((response: Response) => {
        return Network.handleResponseBasedOnStatus(response);
      });
  }

  /**
   * handle various types of errors from network request based on response status.
   *
   * @static
   * @param {Response} response response from network request
   * @returns {Promise<any>} promise to return an error with a specific message
   * @memberof Network
   */
  private static handleResponseBasedOnStatus(
    response: Response
  ): Promise<unknown> {
    let promise: Promise<unknown>;

    switch (response.status) {
      case 200:
      case 201:
        promise = response.json();
        break;
      case 204:
        promise = Promise.resolve();
        break;
      case 400:
      case 403:
        promise = response.json().then((data) => {
          return Promise.reject(data.data);
        });
        break;
      case 401:
        promise = response.json().then((data) => {
          return Promise.reject(data?.data ? data?.data : data?.message);
        });
        break;
      case 409:
        promise = response.json().then((data) => {
          return Promise.reject(data);
        });
        break;
      case 422:
        promise = response.json().then((data) => {
          return Promise.reject(data && (data.errors || data.message));
        });
        break;

      default:
        promise = response.json().then((data) => {
          return Promise.reject({
            message: data?.data ? data?.data.message : data?.message,
            status: response.status,
          });
        });
    }

    return promise.catch((error: Error) => {
      return Promise.reject(error);
    });
  }

  /**
   * get headers that should be added to the request.
   *
   * @static
   * @param {HeadersInit} [originalHeaders] optional headers to be
   * added/overwrite the default headers.
   * @returns {HeadersInit} headers object that needs to be added to the request.
   * @memberof Network
   */
  public static getHeaders(
    originalHeaders?: HeadersInit,
    addAuth = true,
    contentType: string | null = 'application/json',
    countrySlug?: string
  ): HeadersInit {
    const headers = originalHeaders ?? {
      accept: 'application/json',
      locale: i18next.language ?? 'en',
    };

    if (
      addAuth &&
      localStorage.getItem(
        process.env['NX_SALESMAN_ACCESS_TOKEN_KEY'] as string
      )
    ) {
      (headers as any)['Authorization'] = `Bearer ${localStorage.getItem(
        process.env['NX_SALESMAN_ACCESS_TOKEN_KEY'] as string
      )}`;
    }

    const isMultipartForm = contentType?.includes('multipart/form-data');
    if (contentType && !isMultipartForm) {
      (headers as any)['content-type'] = contentType;
    }

    if (contentType) {
      (headers as any)['content-type'] = contentType;
    }

    if (countrySlug) {
      (headers as any)['country'] = countrySlug;
    }

    return headers;
  }
}
