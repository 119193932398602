import styles from './deals-applied-filters.module.scss';
import { useTranslation } from 'react-i18next';
import RemoveIcon from '../../assets/icons/close.svg';
import { format } from 'date-fns';
import { Dispatch, SetStateAction } from 'react';
import { DateFilters } from '../../definitions/interfaces/deals.interface';

interface DealsAppliedFiltersProps {
  filters: Record<string, string[]>;
  resetDateFilters: () => void;
  setFilters: Dispatch<SetStateAction<Record<string, string[]>>>;
  resetEngDate: boolean;
  resetExpDate: boolean;
  resetCreatedDate: boolean;
  resetFollowupDate: boolean;
  setResetCreatedDate: Dispatch<SetStateAction<boolean>>;
  setResetFollowupDate: Dispatch<SetStateAction<boolean>>;
  setResetExpDate: Dispatch<SetStateAction<boolean>>;
  setResetEngDate: Dispatch<SetStateAction<boolean>>;
  handleFilterChange: (column: string, selectedValues: string[]) => void;
  dateFilters: DateFilters;
  setDateFilters: Dispatch<SetStateAction<DateFilters>>;
}
export function DealsAppliedFilters(props: DealsAppliedFiltersProps) {
  const { t } = useTranslation();
  const hasActiveFilters = Object.values(props.filters).some(
    (values) => values.length > 0
  );

  const hasEngagementDateFilter =
    props.dateFilters.engagementDate?.startDate &&
    props.dateFilters.engagementDate?.endDate;

  const hasLeadExpirationDateFilter =
    props.dateFilters.leadExpirationDate?.startDate &&
    props.dateFilters.leadExpirationDate?.endDate;

  const hasFollowupDateFilter =
    props.dateFilters.followupDate?.startDate &&
    props.dateFilters.followupDate?.endDate;

  const hasAnyDateFilter =
    hasEngagementDateFilter ||
    hasLeadExpirationDateFilter ||
    hasFollowupDateFilter;

  return (
    <div className={styles['applied-filters']}>
      {Object.entries(props.filters)
        .flatMap(([key, values]) =>
          values.map((value) => ({
            key,
            value,
          }))
        )
        .map(({ key, value }) => (
          <div key={`${key}-${value}`} className={styles['filter-item']}>
            {value}
            <button
              onClick={() =>
                props.handleFilterChange(
                  key,
                  props.filters[key].filter((item) => item !== value)
                )
              }
              className={styles['remove-filter']}
            >
              <img src={RemoveIcon} alt="remove" />
            </button>
          </div>
        ))}

      {props.dateFilters.engagementDate?.startDate &&
        props.dateFilters.engagementDate?.endDate && (
          <div className={styles['filter-item']}>
            {t('engagementDate')}:{' '}
            {`${format(
              props.dateFilters.engagementDate.startDate,
              'dd MMM yyyy'
            )} - ${format(
              props.dateFilters.engagementDate.endDate,
              'dd MMM yyyy'
            )}`}
            <button
              onClick={() => {
                props.setResetEngDate(!props.resetEngDate);
                props.setDateFilters((prev) => ({
                  ...prev,
                  engagementDate: { startDate: null, endDate: null },
                }));
              }}
              className={styles['remove-filter']}
            >
              <img src={RemoveIcon} alt="remove" />
            </button>
          </div>
        )}
      {props.dateFilters.leadExpirationDate?.startDate &&
        props.dateFilters.leadExpirationDate?.endDate && (
          <div className={styles['filter-item']}>
            {t('leadExpirationDate')}:{' '}
            {`${format(
              props.dateFilters.leadExpirationDate.startDate,
              'dd MMM yyyy'
            )} - ${format(
              props.dateFilters.leadExpirationDate.endDate,
              'dd MMM yyyy'
            )}`}
            <button
              onClick={() => {
                props.setResetExpDate(!props.resetExpDate);
                props.setDateFilters((prev) => ({
                  ...prev,
                  leadExpirationDate: { startDate: null, endDate: null },
                }));
              }}
              className={styles['remove-filter']}
            >
              <img src={RemoveIcon} alt="remove" />
            </button>
          </div>
        )}
      {props.dateFilters.createdOn?.startDate &&
        props.dateFilters.createdOn?.endDate && (
          <div className={styles['filter-item']}>
            {t('leadExpirationDate')}:{' '}
            {`${format(
              props.dateFilters.createdOn.startDate,
              'dd MMM yyyy'
            )} - ${format(props.dateFilters.createdOn.endDate, 'dd MMM yyyy')}`}
            <button
              onClick={() => {
                props.setResetCreatedDate(!props.resetCreatedDate);
                props.setDateFilters((prev) => ({
                  ...prev,
                  createdOn: { startDate: null, endDate: null },
                }));
              }}
              className={styles['remove-filter']}
            >
              <img src={RemoveIcon} alt="remove" />
            </button>
          </div>
        )}

      {props.dateFilters.followupDate?.startDate &&
        props.dateFilters.followupDate?.endDate && (
          <div className={styles['filter-item']}>
            {t('followupDate')}:{' '}
            {`${format(
              props.dateFilters.followupDate.startDate,
              'dd MMM yyyy'
            )} - ${format(
              props.dateFilters.followupDate.endDate,
              'dd MMM yyyy'
            )}`}
            <button
              onClick={() => {
                props.setResetFollowupDate(!props.resetFollowupDate);
                props.setDateFilters((prev) => ({
                  ...prev,
                  followupDate: { startDate: null, endDate: null },
                }));
              }}
              className={styles['remove-filter']}
            >
              <img src={RemoveIcon} alt="remove" />
            </button>
          </div>
        )}
      {hasActiveFilters || hasAnyDateFilter ? (
        <button
          onClick={() => {
            props.setFilters({});
            props.setResetEngDate(!props.resetEngDate);
            props.setResetExpDate(!props.resetExpDate);
            props.setResetCreatedDate(!props.resetCreatedDate);
            props.setResetFollowupDate(!props.resetFollowupDate);
            props.resetDateFilters();
          }}
          className={styles['reset-filters']}
        >
          {t('reset')}
        </button>
      ) : null}
    </div>
  );
}

export default DealsAppliedFilters;
