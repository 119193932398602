import { UnitInterface } from '@orascom/api-interfaces';
import { Link } from 'react-router-dom';
import arrowIcon from '../../assets/icons/left-arrow.svg';
import styles from './dashboard-units.module.scss';
import { CircularArrow } from '@orascom/broker-sales-man-common-components';

export interface DashboardUnitsProps {
  title: string;
  anchorTitle: string;
  anchorLink: string;
  units: UnitInterface[];
  getUnitDetailsPath?: Function
}

export function DashboardUnits(props: Readonly<DashboardUnitsProps>) {
  return (
    <div className={styles['wrapper']}>
      <div className={styles['header']}>
        <h3>{props.title}</h3>
        <Link to={props.anchorLink} className={styles['anchor']}>
          <span className="anchor">{props.anchorTitle}</span>
          <img src={arrowIcon} alt="" role="presentation" />
        </Link>
      </div>
      <div className={styles['grid']}>
        {props.units.map((u) => (
          <Link
            key={u.id}
            to={props.getUnitDetailsPath?.(u.id) ?? ''}
            className={styles['unit']}
          >
            <img
              src={u.cover_image}
              alt={u.unit_type.name}
              className={styles['image']}
              loading="lazy"
            />
            <div className={styles['info']}>
              <h3>
                {u.name} | {u.unit_type.name}
              </h3>
              <CircularArrow />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default DashboardUnits;
