import { OptionValue } from '@orascom/api-interfaces';
import * as yup from 'yup';
import { LeadChannelValues } from './deal.utils';

export const phoneSchema = yup.object({
  countryCode: yup.string().required(),
  phone: yup.string().required('Please enter your phone numebr'),
});

export const newDealSchema = yup.object({
  firstName: yup.string().required('Please enter your first name'),
  lastName: yup.string().required('Please enter your last name'),
  phoneNumbers: yup.array().of(phoneSchema).max(3, 'maxThreePhoneNumbers'),
  feedback: yup.object().nullable(),
  customerInquiry: yup.object().nullable(),
});

export const egyptSchema = newDealSchema.concat(
  yup.object({
    leadChannel: yup.object().nullable().required('Required'),
    ambassadorName: yup
      .object()
      .nullable()
      .when('leadChannel', {
        is: (leadChannel: OptionValue) =>
          leadChannel?.value === LeadChannelValues.AMBASSADOR,
        then: yup.object().nullable().required('Please enter ambassador name'),
        otherwise: yup.object().nullable(),
      }),
    walkInName: yup
      .object()
      .nullable()
      .when('leadChannel', {
        is: (leadChannel: OptionValue) =>
          leadChannel?.value === LeadChannelValues.WALK_IN,
        then: yup.object().required('Please enter walk in name'),
        otherwise: yup.object().nullable(),
      }),
    eventsName: yup
      .object()
      .nullable()
      .when('leadChannel', {
        is: (leadChannel: OptionValue) =>
          leadChannel?.value === LeadChannelValues.EVENTS,
        then: yup.object().required('Please enter events name'),
        otherwise: yup.object().nullable(),
      }),
    brokerName: yup
      .object()
      .nullable()
      .when('leadChannel', {
        is: (leadChannel: OptionValue) =>
          leadChannel?.value === LeadChannelValues.BROKER,
        then: yup.object().required('Please enter broker name'),
        otherwise: yup.object().nullable(),
      }),
    exhibitionName: yup
      .object()
      .nullable()
      .when('leadChannel', {
        is: (leadChannel: OptionValue) =>
          leadChannel?.value === LeadChannelValues.EXHIBITIONS,
        then: yup.object().required('Please enter exhbition name'),
        otherwise: yup.object().nullable(),
      }),
    referralUnitDestination: yup.string().nullable(),
    referralCustomerName: yup.string().nullable(),
    unitNumber: yup
      .string()
      .nullable()
      .when('leadChannel', {
        is: (leadChannel: OptionValue) =>
          leadChannel?.value === LeadChannelValues.REFERRALS,
        then: yup.string().required('Please enter unit number'),
        otherwise: yup.string().nullable(),
      }),
    customer: yup
      .string()
      .nullable()
      .when('leadChannel', {
        is: (leadChannel: OptionValue) =>
          leadChannel?.value === LeadChannelValues.REFERRALS,
        then: yup.string().required('Please enter customer name'),
        otherwise: yup.string().nullable(),
      }),
  })
);

export const montenegroSchema = newDealSchema.concat(
  yup.object({
    destination: yup.object().nullable(),
    leadType: yup.object().nullable().required('Required'),
    country: yup.object().nullable().required('Required'),
    source: yup.object().nullable().required('Required'),
    subSource: yup
      .object()
      .nullable()
      .when('source', {
        is: (source: OptionValue) => source?.label !== 'Broker',
        then: yup.object().required('Required'),
        otherwise: yup.object().nullable(),
      }),
    systemUser: yup
      .object()
      .nullable()
      .when('source', {
        is: (source: OptionValue) => source?.label === 'Personal',
        then: yup.object().required('Required'),
        otherwise: yup.object().nullable(),
      }),
    broker: yup
      .object()
      .nullable()
      .when('source', {
        is: (source: OptionValue) => source?.label === 'Broker',
        then: yup.object().required('Required'),
        otherwise: yup.object().nullable(),
      }),
  })
);

export enum PipelineTypeEnum {
  MULTIPLE = 2,
  SINGLE = 1,
}

export enum UnitTypeEnum {
  CENTRALE_APARTMENT = 1,
  MV_APARTMENT = 2,
  MV_TOWNHOUSE = 3,
  MV_VILLA = 4,
  GOLF_APARTMENT = 5,
  GOLF_TOWNHOUSE = 6,
  GOLF_VILLA = 7,
  HOTEL_CONDO = 8,
}

export const unitTypeOptions = [
  { label: 'Centrale Apartment', value: 1 },
  { label: 'MV Apartment', value: 2 },
  { label: 'MV Townhouse', value: 3 },
  { label: 'MV Villa', value: 4 },
  { label: 'Golf Apartment', value: 5 },
  { label: 'Golf Townhouse', value: 6 },
  { label: 'Golf Villa', value: 7 },
  { label: 'Hotel Condo', value: 8 },
];
