import { Payload } from '@orascom/api-interfaces';
import { ENDPOINTS } from './endpoints';
import { Network } from './network';
import {
  CustomerCity,
  CustomerCountry,
  CustomerNationality,
  CustomerOccupation,
  QualifyCustomer as QualifyCustomerInterface,
  QualifyCustomerResponse,
} from '../definitions/interfaces/qualify-customer';
import { getFileExtension } from '../utils/getFileExtension';

export class QualifyCustomer {
  public static getCustomerNationalities(
    country: string
  ): Promise<Payload<CustomerNationality[]>> {
    const url = new URL(
      `${process.env['NX__API_ENDPOINT']}${ENDPOINTS.getCustomerNationalities.path}`
    );

    url.searchParams.append('country_slug', country);
    const fetchURL = decodeURIComponent(url.href);

    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getCustomerNationalities.method,
    });
  }

  public static getCustomerCities(
    country: string
  ): Promise<Payload<CustomerCity[]>> {
    const url = new URL(
      `${process.env['NX__API_ENDPOINT']}${ENDPOINTS.getCustomerCities.path}`
    );

    url.searchParams.append('country_slug', country);
    const fetchURL = decodeURIComponent(url.href);

    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getCustomerCities.method,
    });
  }

  public static getCustomerCountries(
    country: string
  ): Promise<Payload<CustomerCountry[]>> {
    const url = new URL(
      `${process.env['NX__API_ENDPOINT']}${ENDPOINTS.getCustomerCountries.path}`
    );

    url.searchParams.append('country_slug', country);
    const fetchURL = decodeURIComponent(url.href);

    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getCustomerCountries.method,
    });
  }

  public static getCustomerOccupations(
    country: string
  ): Promise<Payload<CustomerOccupation[]>> {
    const url = new URL(
      `${process.env['NX__API_ENDPOINT']}${ENDPOINTS.getCustomerOccupations.path}`
    );

    url.searchParams.append('country_slug', country);
    const fetchURL = decodeURIComponent(url.href);

    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getCustomerOccupations.method,
    });
  }

  public static qualifyCustomer(
    formData: QualifyCustomerInterface,
    country: string
  ): Promise<Payload<Payload<QualifyCustomerResponse>>> {
    return Network.fetch(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.qualifyCustomer.url}`,
      {
        method: ENDPOINTS.qualifyCustomer.method,
        body: JSON.stringify(formData),
      },
      undefined,
      country
    );
  }

  public static attachementCustomer(
    customerId: string,
    destination: string,
    file: File,
    country: string
  ): Promise<Payload<void>> {
    const fileExtension = getFileExtension(file.name);
    const newFileName = `qualify-customer-attachment.${fileExtension}`;

    // backend has some limitations on file names, so to be sure we are safe
    const renamedFile = new File(
      [file],
      newFileName,
      { type: file.type } // Preserve MIME type
    );

    const formData = new FormData();
    formData.append('attachment', renamedFile);
    formData.append('destination_slug', destination);
    return Network.fetch(
      `${
        process.env['NX__SALESMAN_API_ENDPOINT']
      }${ENDPOINTS.attachementCustomer.url(customerId)}`,
      {
        method: ENDPOINTS.attachementCustomer.method,
        body: formData,
      },
      null,
      country
    );
  }
}
