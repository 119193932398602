import { Loader } from '@orascom/common-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../api/routes';
import arrowIcon from '../../assets/icons/left-arrow.svg';
import PipelineDetails from '../../components/pipeline-details/pipeline-details';
import {
  UserPipelineProvider,
  useUserPipelineContext,
} from '../../context/user-pipeline-context';
import styles from './deal-details.module.scss';
import { COUNTRY_SELECTED_CONTEXT } from '../../context/country-selected-context';
import { useContext } from 'react';
import PipelineDetailsMontenegro from '../../components/pipeline-details/pipeline-details-montenegro';
import { SalesManagerCountries } from '../../utils/country-selection';
import { useSetActiveCountryFromParams } from '../../hooks/use-active-country-disabling';
import { PipelineDetails as PipelineDetailsInterface } from '../../definitions/interfaces/lead-stages.interface';
import PipelineDetailsStatic from '../../components/pipeline-details/pipeline-details-static';
import { PipelineStageEnum } from '../../definitions/interfaces/deals.interface';

const walkthroughLocalstorageCheck =
  localStorage.getItem('walkthrough_status') === 'running';

export function DealDetails() {
  const { t } = useTranslation();

  const activeCountry = useContext(COUNTRY_SELECTED_CONTEXT);
  useSetActiveCountryFromParams(activeCountry);

  const staticPipeline: PipelineDetailsInterface = {
    logical_name: 'test',
    activity_date: 'test',
    owning_business_unit: 'test',
    current_phase: PipelineStageEnum.LEAD_DISCOVERY,
    lead_type: 'test',
    pipeline_name: 'test',
    is_lost: 'test',
    source_created_at: 'test',
    is_meeting_done: 'test',
    owner_name: 'test',
    future_budget_availability: 'test',
    lead_name: 'test',
    pipeline_expiration_status: 'test',
    pipeline_source_id: 'test',
    is_extension_confirmed: 'test',
    currency: 'test',
    exchange_rate: 'test',
    destination_name: 'test',
    is_payments: 'test',
    objections_handled: 'test',
    is_unit_type_selected: true,
    created_by: 'test',
    pipeline_type: 'Single Unit',
    current_phase_expiration_date: 'test',
    is_resale: 'test',
    is_future_opportunity: 'test',
    objection_name: 'test',
    destination_slug: 'test',
    initial_meeting_date: 'test',
    objection_notes: 'test',
    deal_progress: 'test',
    competitor_pipeline: 'test',
    lost_reactivated_date: 'test',
    closure_date: 'test',
    lost_reactivated_by: 'test',
    sales_comment: 'test',
    feedback_pipeline_date: 'test',
    feedback_id: 1,
    feedback_value: 'test',
    unit_type_count_rollup: 1,
    is_eoi: false,
    is_units_selected: false,
    is_interest_identified: false,
    is_payment_terms: false,
  };

  const { pendingCustomerData } = useUserPipelineContext();

  const pageDetails = walkthroughLocalstorageCheck ? (
    <PipelineDetailsStatic page="Lead" selectedPipeline={staticPipeline} />
  ) : activeCountry.activeCountry?.value === SalesManagerCountries.EGYPT ? (
    <PipelineDetails page="Lead" />
  ) : (
    <PipelineDetailsMontenegro page="Lead" />
  );

  if (pendingCustomerData && !walkthroughLocalstorageCheck) {
    return (
      <div className={styles['loader-wrapper']}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles['wrapper']}>
      <Link to={ROUTES['MyDeals'].path} className={styles['back-anchor']}>
        <img src={arrowIcon} alt="" role="presentation" />
        <span className="anchor">{t('back')}</span>
      </Link>
      <h1 className={styles['title']}>{t('leadDetails')}</h1>

      {pageDetails}
    </div>
  );
}

const DealDetailsPage = () => {
  if (walkthroughLocalstorageCheck) return <DealDetails />;
  return (
    <UserPipelineProvider>
      <DealDetails />
    </UserPipelineProvider>
  );
};

export default DealDetailsPage;
