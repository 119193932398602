import { SelectDropdown } from '@orascom/common-components';
import styles from './lead-feedback.module.scss';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useRef, useState } from 'react';
import { LeadDiscoveryInput } from '../../definitions/interfaces/lead-stages.interface';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import {
  feedbackOptions,
  feedbackOptionsMontenegro,
  leadDiscoveryFeedbackOptionsEnum,
} from '../../utils/deal.utils';
import { addDays, format } from 'date-fns';
import Calendar from 'react-calendar';
import LeftArrowIcon from '../../assets/icons/calendar-left-arrow.svg';
import RightArrowIcon from '../../assets/icons/calendar-right-arrow.svg';
import ChevronLeft from '../../assets/icons/left-arrow.svg';
import {
  PipelineStageEnum,
  MontenegroPipelineStageEnum,
} from '../../definitions/interfaces/deals.interface';
import { COUNTRY_SELECTED_CONTEXT } from '../../context/country-selected-context';
import { SalesManagerCountries } from '../../utils/country-selection';
import { ReusableCalendar } from '../reusable-calendar/reusable-calendar';
import ConditionalFields from './conditional-fields';

type Value = Date | null;

export interface LeadFeedbackProps {
  dealStatus: PipelineStageEnum | MontenegroPipelineStageEnum;
  addFeedback?: boolean;
  setAddFeedback?: React.Dispatch<React.SetStateAction<boolean>>;
  pipelineFeedback?: string;
}
export function LeadFeedback({
  dealStatus,
  addFeedback,
  setAddFeedback,
  pipelineFeedback,
}: LeadFeedbackProps) {
  const { errors, touched, setFieldValue, values } =
    useFormikContext<LeadDiscoveryInput>();
  const { t } = useTranslation();
  const [isEgypt, setIsEgypt] = useState(false);
  const [selectedFollowUpDate, setSelectedFollowUpDate] = useState<Value>(null);

  const activeCountry = useContext(COUNTRY_SELECTED_CONTEXT);

  useEffect(() => {
    if (activeCountry.activeCountry?.value === SalesManagerCountries.EGYPT) {
      setIsEgypt(true);
    } else {
      setIsEgypt(false);
    }
  }, [activeCountry]);

  const feedbackDropdownOptions = isEgypt
    ? feedbackOptions
    : feedbackOptionsMontenegro;

  const [selectedDate, setSelectedDate] = useState<Value>();
  const [, setSelectedExpectedDate] = useState<Value>();

  const dialogRefFollowUp = useRef<HTMLDialogElement | null>(null);

  const toggleDialog = () => {
    if (dialogRefFollowUp.current) {
      if (dialogRefFollowUp.current.open) {
        dialogRefFollowUp.current.close();
      } else {
        dialogRefFollowUp.current.show();
      }
    }
  };
  const selectedFeedbackValue = values.feedbackId;

  useEffect(() => {
    if (
      selectedFeedbackValue !== leadDiscoveryFeedbackOptionsEnum.PAYMENT_TERMS
    ) {
      setFieldValue('moreDetails', '');
      setFieldValue('issue', null);
    }

    if (
      selectedFeedbackValue !== leadDiscoveryFeedbackOptionsEnum.COMPETITION
    ) {
      setFieldValue('lostCompetitorSourceId', '');
      setFieldValue('furtherDetails', '');
      setFieldValue('competitorAdvantage', '');
    }

    if (
      selectedFeedbackValue !== leadDiscoveryFeedbackOptionsEnum.PRICE_CONCERN
    ) {
      setFieldValue('expectedBudget', '');
      setFieldValue('currency', '');
      setFieldValue('futureBudgetAvailability', null);
      setFieldValue('futureOpportunity', null);
      setFieldValue('excpectedDate', '');
      setSelectedExpectedDate(null);
    }

    if (
      selectedFeedbackValue !== leadDiscoveryFeedbackOptionsEnum.PRODUCT_CONCERN
    ) {
      setFieldValue('defineIssue', null);
      setFieldValue('futureOpportunity', null);
      setFieldValue('desiredProduct', null);
      setFieldValue('concern', null);
    }

    if (selectedFeedbackValue !== leadDiscoveryFeedbackOptionsEnum.OTHERS) {
      setFieldValue('clarifyReasons', '');
    }
  }, [selectedFeedbackValue]);

  const afterFirstStageCheck = isEgypt
    ? dealStatus === PipelineStageEnum.SALES_PITCH ||
      dealStatus === PipelineStageEnum.DEAL_CLOSING
    : dealStatus === MontenegroPipelineStageEnum.SECOND_STAGE ||
      dealStatus === MontenegroPipelineStageEnum.THIRD_STAGE ||
      dealStatus === MontenegroPipelineStageEnum.FOURTH_STAGE;

  const showAddExtraFeedback = afterFirstStageCheck && addFeedback;
  const showAddFeedback = isEgypt
    ? dealStatus === PipelineStageEnum.LEAD_DISCOVERY || showAddExtraFeedback
    : dealStatus === MontenegroPipelineStageEnum.FIRST_STAGE ||
      showAddExtraFeedback;

  return (
    <div className={styles['lead-feedback']}>
      <div className={styles['header']}>
        <p className={styles['title']}>{t('leadFeedback')}</p>
        {afterFirstStageCheck && (
          <button
            type="button"
            onClick={() => setAddFeedback?.(true)}
            className={styles['add-btn']}
          >
            {t('addAnotherFeedback')}
          </button>
        )}
      </div>
      {afterFirstStageCheck && (
        <div className={styles['pipeline-feedback']}>
          <p>Feedback:</p>
          <p>{pipelineFeedback}</p>
        </div>
      )}

      {showAddFeedback && (
        <div className={styles['feedback-input']}>
          <div>
            <label>{t('feedback')}</label>
            <SelectDropdown
              name="feedbackId"
              options={feedbackDropdownOptions}
              placeholder={t('selectPlaceholder')}
              className={`${styles['select']}`}
              invalid={touched.feedbackId && !!errors.feedbackId}
              onChange={(value) => {
                setFieldValue('feedbackId', value?.value);
              }}
              selectedOption={feedbackDropdownOptions.find((option) => {
                return option.value === values.feedbackId;
              })}
            />
            <ErrorMessage
              name="feedbackId"
              component="div"
              className="form__error"
            />
          </div>
          <div>
            <label htmlFor="salesComment">
              {t('yourFeedback')} ({t('optional')})
            </label>
            <Field
              className={styles['feedback-text']}
              type="text"
              as="textarea"
              name="salesComment"
              placeholder={t('feedbackPlaceholder')}
            />
          </div>
          {!isEgypt && (
            <div>
              <ReusableCalendar
                label={`${t('followUpDate')} (${t('optional')})`}
                name="feedbackFollowUpDate"
                onDateChange={(date) => {
                  setSelectedFollowUpDate(date as Value);
                  setFieldValue('feedbackFollowUpDate', date);
                }}
                selectedDate={selectedFollowUpDate}
                view="month"
              />
            </div>
          )}

          {selectedFeedbackValue ===
            leadDiscoveryFeedbackOptionsEnum.FOLLOW_UP &&
            isEgypt && (
              <div>
                <label htmlFor="followup_date">{t('followUpDate')}</label>
                <div className={styles['feedback-modal-wrapper']}>
                  <button
                    type="button"
                    onClick={toggleDialog}
                    className={styles['btn-outline']}
                  >
                    <p>
                      {selectedDate
                        ? format(new Date(selectedDate), 'yyyy-MM-dd')
                        : t('selectDate')}
                    </p>
                    <img
                      style={{ height: 'auto', rotate: '-90deg' }}
                      src={ChevronLeft}
                      alt=""
                      role="presentation"
                    />
                  </button>
                  <dialog
                    ref={dialogRefFollowUp}
                    className={styles['feedback-modal']}
                  >
                    <Calendar
                      value={selectedDate}
                      // tommorow
                      minDate={addDays(new Date(), 1)}
                      onChange={(date) => {
                        setSelectedDate(date as Value);
                        setFieldValue(
                          'followUpdate',
                          format(date as Date, 'yyyy-MM-dd')
                        );
                        toggleDialog();
                      }}
                      view={'month'}
                      prevLabel={
                        <img
                          style={{ height: 'auto' }}
                          src={LeftArrowIcon}
                          alt=""
                          role="presentation"
                        />
                      }
                      nextLabel={
                        <img
                          style={{ height: 'auto' }}
                          src={RightArrowIcon}
                          alt=""
                          role="presentation"
                        />
                      }
                    />
                  </dialog>
                  <ErrorMessage
                    name="followUpdate"
                    component="div"
                    className="form__error"
                  />
                </div>
              </div>
            )}
          <ConditionalFields
            setFieldValue={setFieldValue}
            paymentTerms={
              selectedFeedbackValue ===
                leadDiscoveryFeedbackOptionsEnum.PAYMENT_TERMS && isEgypt
            }
            competition={
              selectedFeedbackValue ===
                leadDiscoveryFeedbackOptionsEnum.COMPETITION && isEgypt
            }
            priceConcern={
              selectedFeedbackValue ===
                leadDiscoveryFeedbackOptionsEnum.PRICE_CONCERN && isEgypt
            }
            productConcern={
              selectedFeedbackValue ===
                leadDiscoveryFeedbackOptionsEnum.PRODUCT_CONCERN && isEgypt
            }
            others={
              selectedFeedbackValue ===
                leadDiscoveryFeedbackOptionsEnum.OTHERS && isEgypt
            }
            values={values}
            errors={errors}
            touched={touched}
          />
        </div>
      )}
    </div>
  );
}

export default LeadFeedback;
