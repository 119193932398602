import { FormikErrors, FormikTouched } from 'formik';
import {
  MontenegroPipelineStageEnum,
  PipelineStageEnum,
} from './deals.interface';

export interface LeadDiscoveryInterface {
  lead_source_id: string;
  customer_source_id?: string;
  pipeline_type: number | null;
  destination_slug?: string;
  initial_meeting_date?: number;
  unit_category_id?: number | null;
  feedback_id: number | null;
  sales_comment?: string;
  followup_date?: number;
  issue?: number | null;
  more_details?: string;
  lost_competitor_source_id?: string;
  further_details?: string;
  competitor_advantage?: string;
  expected_budget?: number | null;
  currency_source_id?: string;
  future_budget_availability?: string;
  expected_date?: number;
  future_opportunity?: number | null;
  define_issue?: string | null;
  concern?: number | null;
  desired_product?: number | null;
  clarify_reasons?: string;
  product_details?: string | null;
}

export enum DisqualifyReason {
  BLACKLIST = 100000002,
  LOST = 4,
  CANNOT_CONTACT = 5,
  NO_LONGER_INTERTRESTED = 6,
  CANCELED = 7,
}

export enum DisqalifyLeadMontenegro {
  NO_LONGER_INTERTRESTED = 6,
  NOT_ELIGIBLE = 753240004,
  OUT_OF_BUDGET = 753240005,
}

export interface DisqalifyLeadPayload {
  status_reason: DisqualifyReason;
}
export interface LeadDiscoveryInput {
  destinationSlug: string;
  pipelineType: number | null;
  initalMeetingDate: string;
  unitCategoryId: number | null;
  feedbackId: number | null;
  salesComment: string;
  followUpdate: string;
  issue: number | null;
  moreDetails: string;
  lostCompetitorSourceId: string;
  furtherDetails: string;
  competitorAdvantage: string;
  expectedBudget: number | null;
  currency: string;
  futureBudgetAvailability: string;
  expectedDate: string;
  futureOpportunity: number | null;
  defineIssue: string | null;
  concern: number | null;
  desiredProduct: number | null;
  clarifyReasons: string;
  productDetails: string | null;
}

export interface LeadCreationResponse {
  closure_date: string;
  customer_source_id: string;
  feedback_id: number;
  followup_date: string;
  initial_meeting_date: string;
  lead_source_id: string;
  objection_id: string;
  objection_notes: string;
  pipeline_source_id: string;
  sales_comment: string;
}

export interface FeedbackSubmissionInput {
  feedbackId: number;
  salesComment: string;
  followUpdate: string;
  issue: number | null;
  moreDetails: string;
  lostCompetitorSourceId: string;
  furtherDetails: string;
  competitorAdvantage: string;
  expectedBudget: number | null;
  currency: string;
  futureBudgetAvailability: string;
  expectedDate: string;
  futureOpportunity: number | null;
  defineIssue: string | null;
  clarifyReasons: string;
  desiredProduct?: number | null;
  concern: number | null;
  productDetails: string | null;
}

export interface LostPipelineInput {
  reason: number;
  description: string;
  issue: number | null;
  moreDetails: string;
  lostCompetitorSourceId: string;
  furtherDetails: string;
  competitorAdvantage: string;
  expectedBudget: number | null;
  currency: string;
  futureBudgetAvailability: string;
  expectedDate: string;
  futureOpportunity: number | null;
  defineIssue: number | null;
  clarifyReasons: string;
  desiredProduct?: number | null;
  concern: number | null;
  productDetails: string | null;
}
export interface FeedbackSubmission {
  lead_source_id: string;
  customer_source_id: string;
  destination_slug: string;
  feedback_id: number;
  sales_comment: string;
  followup_date: number;
  issue: number | null;
  more_details: string;
  lost_competitor_source_id: string;
  further_details: string;
  competitor_advantage: string;
  expected_budget: number | null;
  currency_source_id: string;
  future_budget_availability: string;
  expected_date: number;
  future_opportunity: number | null;
  define_issue: string | null;
  clarify_reasons: string;
  desired_product: number | null;
  concern: number | null;
  product_details: string | null;
}

export interface FormikPassedStateProps {
  errors?: FormikErrors<LeadDiscoveryInput>;
  touched?: FormikTouched<LeadDiscoveryInput>;
  setFieldValue: (
    field: string,
    value: string | number | undefined | null,
    shouldValidate?: boolean
  ) => void;
}

export interface LeadDetails {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  channel_id: number;
  ambassador_source_id: string;
  broker_source_id: string;
  event_source_id: string | null;
  expiration_date: string;
  last_engagement_date: string | null;
  source_created_at: string;
  destinations: DestinationInLeadDetails[];
  channel_details: string;
  customer_source_id?: string;
  destination_gouna: boolean;
  destination_makadi_byoum: boolean;
  destination_owest: boolean;
  lead_source_details?: string;
}

export interface DestinationInLeadDetails {
  account_number: string;
  brochure: string;
  id: number;
  masterplan_description: string;
  name: string;
  slug: string;
  time_range: string;
  units_sold: string;
  video_playlist: null;
  welcome_description: string;
}

export interface PipelineDetails {
  logical_name: string;
  activity_date: string;
  owning_business_unit: string;
  current_phase: PipelineStageEnum | MontenegroPipelineStageEnum;
  lead_type: string;
  pipeline_name: string;
  is_lost: string;
  source_created_at: string;
  is_meeting_done: string;
  owner_name: string;
  future_budget_availability: string;
  lead_name: string;
  pipeline_expiration_status: string;
  pipeline_source_id: string;
  is_extension_confirmed: string;
  currency: string;
  exchange_rate: string;
  destination_name: string;
  is_payments: string;
  objections_handled: string;
  is_unit_type_selected: boolean;
  created_by: string;
  pipeline_type: 'Single Unit' | 'Multiple Units' | 'Multi Units';
  current_phase_expiration_date: string;
  is_resale: string;
  is_future_opportunity: string;
  objection_name: string;
  destination_slug: string;
  initial_meeting_date: string;
  objection_notes: string;
  deal_progress: string;
  competitor_pipeline: string;
  lost_reactivated_date: string;
  closure_date: string;
  lost_reactivated_by: string;
  sales_comment: string;
  feedback_pipeline_date: string;
  feedback_id: number;
  feedback_value: string;
  unit_type_count_rollup?: number;
  is_eoi?: boolean;
  is_units_selected?: boolean;
  is_interest_identified?: boolean;
  is_payment_terms?: boolean;
  payments_agreement?: boolean;
}
