import {
  DestinationFacilities,
  DestinationFAQ,
  Destination as DestinationInterface,
  DestinationProject,
  PaginationPayload,
  Location,
} from '@orascom/api-interfaces';
import { Destination as DestinationAPI } from '../api/destination.api';
import { getPayloadData, handleError } from '@orascom/utils';

export class Destination {
  /**
   * list all Destination data.
   *
   * @static
   * @returns {Promise<DestinationInterface[]>}
   * @memberof Destination
   */
  public static listSalesManDestinations(
    countryId?: string
  ): Promise<DestinationInterface[]> {
    return DestinationAPI.listSalesManDestinations(countryId)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getSalesManDestinationInfo(
    destinationSlug: string
  ): Promise<DestinationInterface> {
    return DestinationAPI.getSalesManDestinationInfo(destinationSlug)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getSalesManDestinationLocations(
    destinationSlug: string
  ): Promise<Location[]> {
    return DestinationAPI.getSalesManDestinationLocations(destinationSlug)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getSalesManDestinationFacilities(
    destinationSlug: string
  ): Promise<DestinationFacilities[]> {
    return DestinationAPI.getSalesManDestinationFacilities(destinationSlug)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getSalesManDestinationProjects(
    destinationSlug: string
  ): Promise<DestinationProject[]> {
    return DestinationAPI.getSalesManDestinationProjects(destinationSlug)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getSalesManDestinationFAQ(
    destinationSlug: string
  ): Promise<PaginationPayload<DestinationFAQ[]>> {
    return DestinationAPI.getSalesManDestinationFAQ(destinationSlug)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }
}
