import { OptionValue } from '@orascom/api-interfaces';
import { NavyButton, SelectDropdown } from '@orascom/common-components';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SingleValue } from 'react-select';
import { useUserPipelineContext } from '../../context/user-pipeline-context';
import { MontenegroPipelineStageEnum } from '../../definitions/interfaces/deals.interface';
import DealInfoCard from '../deal-info-card/deal-info-card';
import styles from './pipeline-details.module.scss';
import { useEffect } from 'react';
import MontenegroDealDetailsBreadcrumbs from '../deal-details-breadcrumbs/montenegro-deal-details-breadcrumbs';
import { ROUTES } from '../../api/routes';
import { FirstStage } from '../pipeline-stages-montenegro/first-stage';
import { SecondStage } from '../pipeline-stages-montenegro/second-stage';
import { ThirdStage } from '../pipeline-stages-montenegro/third-stage';
import { FourthStage } from '../pipeline-stages-montenegro/fourth-stage';
import DisqualifyLink from '../pipeline-stages/disqualifyLink';

export interface PipelineDetailsProps {
  page: 'Customer' | 'Lead';
}
export function PipelineDetailsMontenegro({
  page,
}: Readonly<PipelineDetailsProps>) {
  const { t } = useTranslation();
  const location = useLocation();
  const { countryId } = useParams();
  const navigate = useNavigate();
  const {
    user,
    pipelines,
    selectedPipeline,
    setSelectedPipelineId,
    refetchingPipelines,
    leadId,
    lead,
    customerId,
  } = useUserPipelineContext();

  const piplinesOptions = pipelines
    ?.filter(
      (pipeline) =>
        (pipeline.current_phase === MontenegroPipelineStageEnum.FIRST_STAGE ||
          pipeline.current_phase === MontenegroPipelineStageEnum.SECOND_STAGE ||
          pipeline.current_phase === MontenegroPipelineStageEnum.THIRD_STAGE ||
          pipeline.current_phase === MontenegroPipelineStageEnum.FOURTH_STAGE ||
          pipeline.current_phase === MontenegroPipelineStageEnum.LAST_STAGE) &&
        !pipeline.is_lost
    )
    .map((pipeline) => ({
      label: pipeline.pipeline_name + ' - ' + pipeline.current_phase,
      value: pipeline.pipeline_source_id,
    }));

  useEffect(() => {
    const isCreateRoute = location.pathname.endsWith('/create');
    const defaultPipeline = isCreateRoute
      ? null
      : selectedPipeline?.pipeline_source_id ?? piplinesOptions?.[0]?.value;
    if (defaultPipeline) {
      setSelectedPipelineId(defaultPipeline);
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const selectedPipelineOption = selectedPipeline && {
    label:
      selectedPipeline.pipeline_name + ' - ' + selectedPipeline.current_phase,
    value: selectedPipeline.pipeline_source_id,
  };

  const onChangePipeline = (
    pipelineOption?: SingleValue<OptionValue<string | number>>
  ) => {
    if (!pipelineOption) {
      setSelectedPipelineId(undefined);
    }

    const selected = pipelines?.find(
      (pipeline) => pipeline.pipeline_source_id === pipelineOption?.value
    );
    if (selected) {
      setSelectedPipelineId(selected.pipeline_source_id);
    }
  };

  const dealStatus =
    selectedPipeline?.current_phase ?? MontenegroPipelineStageEnum.FIRST_STAGE;

  const isFirstStage = dealStatus === MontenegroPipelineStageEnum.FIRST_STAGE;
  const isSecondStage = dealStatus === MontenegroPipelineStageEnum.SECOND_STAGE;
  const isThirdStage = dealStatus === MontenegroPipelineStageEnum.THIRD_STAGE;
  const isFourthStage =
    dealStatus === MontenegroPipelineStageEnum.FOURTH_STAGE ||
    dealStatus === MontenegroPipelineStageEnum.LAST_STAGE;

  const isLostStage = dealStatus === MontenegroPipelineStageEnum.LOST;

  const qualifiedLead = lead?.customer_source_id;

  // We may have a lead with a customer ID, which is why we ensure customerId is null.
  const showDisqualifyLink = !qualifiedLead && !!leadId && !isLostStage;

  const hasOnePipeline = piplinesOptions?.length === 1;
  const hasMoreThanOnePipeline = piplinesOptions?.length > 1;

  return (
    <div className="card">
      <div className={styles['select-pipeline']}>
        {hasOnePipeline && selectedPipeline && (
          <div className={styles['selected-pipeline']}>
            <span>{t('pipeline')}: </span>
            <span className={styles['pipeline-name']}>
              {selectedPipeline?.pipeline_name} -{' '}
              {selectedPipeline?.current_phase}
            </span>
          </div>
        )}

        {hasMoreThanOnePipeline && (
          <>
            <p>{t('selectPipeline')}</p>
            <SelectDropdown
              name="pipeline"
              className={styles['select']}
              options={piplinesOptions}
              selectedOption={selectedPipelineOption}
              placeholder={t('selectPlaceholder')}
              onChange={onChangePipeline}
              isLoading={refetchingPipelines}
              isClearable
              noOptionsMessage={() => t('noPipelineFound')}
            />
          </>
        )}
      </div>

      <MontenegroDealDetailsBreadcrumbs
        dealStatus={dealStatus as MontenegroPipelineStageEnum}
        page={page}
      />

      <div className={styles['deal-content']}>
        {isFirstStage && <FirstStage />}
        {isSecondStage && <SecondStage />}
        {isThirdStage && <ThirdStage />}
        {isFourthStage && <FourthStage />}
        {isLostStage && <p>{t('thisPipelineIsLost')}</p>}
        {user && <DealInfoCard details={user} page={page} />}
      </div>

      {showDisqualifyLink && (
        <>
          <hr className={styles['line']} />
          <div className={styles['btns-wrapper']}>
            <NavyButton
              disabled={!selectedPipeline?.pipeline_source_id}
              onClick={() => {
                if (page === 'Customer') {
                  navigate(
                    ROUTES['LostCustomer'].getPath?.(customerId, countryId) ||
                      '',
                    {
                      state: {
                        pipelineId: selectedPipeline?.pipeline_source_id,
                      },
                    }
                  );
                } else {
                  navigate(
                    ROUTES['LostLead'].getPath?.(leadId, countryId) || '',
                    {
                      state: {
                        pipelineId: selectedPipeline?.pipeline_source_id,
                      },
                    }
                  );
                }
              }}
            >
              {t('markPipelineAsLost')}
            </NavyButton>
            <DisqualifyLink leadId={leadId} countryId={countryId} />
          </div>
        </>
      )}
    </div>
  );
}

export default PipelineDetailsMontenegro;
