import React, { createContext, useContext, useEffect, useState } from 'react';
import { CustomerDetails as CustomerDetailsInterface } from '../definitions/interfaces/customers.interface';
import {
  LeadDetails as LeadDetailsInterface,
  PipelineDetails as PipelineDetailsInterface,
} from '../definitions/interfaces/lead-stages.interface';

import { useLocation, useParams } from 'react-router-dom';
import useUserAndPipelines from '../hooks/useUserAndPipelines';
import { Customer as CustomerApi } from '../utils/customer';
import { Deal as LeadApi } from '../utils/deal.utils';
import {
  MontenegroPipelineStageEnum,
  PipelineStageEnum,
} from '../definitions/interfaces/deals.interface';

const piplinesStages = [
  PipelineStageEnum.DEAL_CLOSING,
  PipelineStageEnum.LEAD_DISCOVERY,
  PipelineStageEnum.SALES_PITCH,
  PipelineStageEnum.LOST,
  MontenegroPipelineStageEnum.FIRST_STAGE,
  MontenegroPipelineStageEnum.SECOND_STAGE,
  MontenegroPipelineStageEnum.THIRD_STAGE,
  MontenegroPipelineStageEnum.FOURTH_STAGE,
  MontenegroPipelineStageEnum.LAST_STAGE,
  MontenegroPipelineStageEnum.LOST,
];
interface PipelineContextProps {
  selectedPipeline?: PipelineDetailsInterface;
  setSelectedPipelineId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  pipelines: PipelineDetailsInterface[];
  setPipelines: React.Dispatch<
    React.SetStateAction<PipelineDetailsInterface[]>
  >;
  customerId?: string;
  customer?: CustomerDetailsInterface;
  leadId?: string;
  lead?: LeadDetailsInterface;
  user?: CustomerDetailsInterface | LeadDetailsInterface;
  pendingCustomerData: boolean;
  refetchingPipelines: boolean;
  setRefetchingPipelines: React.Dispatch<React.SetStateAction<boolean>>;
  refetchPipelines: () => Promise<PipelineDetailsInterface[] | undefined>;
}

// user can be a customer or a lead
const UserPipelineContext = createContext<PipelineContextProps>({
  selectedPipeline: undefined,
  setSelectedPipelineId: () => {
    throw new Error('setSelectedPipeline must be implemented');
  },
  pipelines: [],
  setPipelines: () => {
    throw new Error('setPipelines must be implemented');
  },
  customer: undefined,
  customerId: undefined,
  pendingCustomerData: true,
  refetchingPipelines: false,
  setRefetchingPipelines: () => {},
  lead: undefined,
  leadId: undefined,
  refetchPipelines: () => {
    throw new Error('refetchPipelines must be implemented');
  },
});

export const UserPipelineProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { customerId, dealId: leadId } = useParams();

  const { countryId } = useParams();

  const [refetchingPipelines, setRefetchingPipelines] = useState(false);

  const location = useLocation();
  const defaultPipelineId = location.state?.pipelineSourceId;

  const [selectedPipelineId, setSelectedPipelineId] = useState<
    string | undefined
  >(defaultPipelineId as string | undefined);

  const { customer, lead, pipelines, setPipelines, pendingCustomerData } =
    useUserAndPipelines(customerId, leadId);

  const user = customerId ? customer : lead;

  const selectedPipeline = pipelines.find((pipeline) => {
    return pipeline.pipeline_source_id === selectedPipelineId;
  });

  const filiteredPipelines = pipelines?.filter((pipeline) => {
    return piplinesStages.includes(pipeline.current_phase);
  });

  const refetchPipelines = async () => {
    const userId = customerId ?? leadId;

    if (!userId) return;

    const getPipeLines = customerId
      ? CustomerApi.getCustomerPipeline
      : LeadApi.getPipelineDetails;

    return getPipeLines(userId, countryId ?? '');
  };

  useEffect(() => {
    // clear browser location state
    window.history.replaceState({}, '');
    // eslint-disable-next-line
  }, []);

  return (
    <UserPipelineContext.Provider
      value={{
        selectedPipeline,
        setSelectedPipelineId,
        pipelines: filiteredPipelines,
        setPipelines,
        customer,
        pendingCustomerData,
        refetchingPipelines,
        setRefetchingPipelines,
        customerId,
        lead,
        leadId,
        user,
        refetchPipelines,
      }}
    >
      {children}
    </UserPipelineContext.Provider>
  );
};

export const useUserPipelineContext = (): PipelineContextProps => {
  const context = useContext(UserPipelineContext);
  if (!context) {
    throw new Error(
      'useUserPipelineContext must be used within a PipelineProvider'
    );
  }
  return context;
};
