import { OptionValue } from '@orascom/api-interfaces';

export interface SecondStageInitialValues {
  // TODO uncomment when needed
  // visitCheckbox: boolean;
  visitDate: string | null;
  visitType: number | null;
}

export interface ChooseUnitsInterface {
  desiredUnit: OptionValue | null;
  expressionOfInterestedPaid: string;
  isDesiredUnit: string;
}

export enum VisitTypeValues {
  PHYSICAL = 1,
  VIRTUAL = 2,
}

export const VisitOptions = [
  { label: 'Physical', value: VisitTypeValues.PHYSICAL },
  { label: 'Virtual', value: VisitTypeValues.VIRTUAL },
];
