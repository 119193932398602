import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Loader,
  RangeSlider,
  SelectDropdown,
} from '@orascom/common-components';
import Numeral from 'numeral';
import {
  InstallmentFrequencyMapper,
  OptionValue,
  UnitPaymentTerms,
  UnitDetails as UnitDetailsInterface,
  CountriesEnum,
} from '@orascom/api-interfaces';
import styles from './unit-payment-plan.module.scss';
import {
  usePaymentPlanCalculator,
  downloadSalesOffer,
  extractUniqueDurations,
  CommonEventParameters,
  CurrencyContext,
  handleSharePdf,
} from '@orascom/utils';
import InfoIcon from '../../assets/icons/info.svg';
import { useLocation, useParams } from 'react-router-dom';
import { USER_CONTEXT } from '@orascom/broker-sales-man-common-components';
import { toast } from 'react-toastify';

export interface UnitPaymentPlanProps {
  getUnitDetails: (unitId: number) => Promise<UnitDetailsInterface>;
  getUnitPaymentTerms: (unitId: number) => Promise<UnitPaymentTerms[]>;
  analyticsUnitSalesOfferDownloadCustomEvent?: (
    params: CommonEventParameters
  ) => void;
  downloadOmanSalesOffer?: (unitId: number, unitName: string) => Promise<any>;
  portal: 'Broker' | 'Sales Man';
}
export function UnitPaymentPlan(props: Readonly<UnitPaymentPlanProps>) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [paymentTerms, setPaymentTerms] = useState<UnitPaymentTerms[]>([]);
  const [downloadedPdf, setDownloadedPdf] = useState<File | null>(null);
  const [error, setError] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const {
    onSelectDownPayment,
    onSelectFrequency,
    onSelectDuration,
    selectedPaymentTerms,
    setSelectedPaymentTerms,
  } = usePaymentPlanCalculator();
  const [unit, setUnit] = useState<UnitDetailsInterface>();

  const { rates, currency, isLoading } = useContext(CurrencyContext);
  const convertPricePerCurrency = (price: number): number => {
    if (rates && currency !== selectedPaymentTerms?.currency) {
      const rateKey = `${currency}${selectedPaymentTerms?.currency}`;
      const convertedPrice = price / rates[rateKey];
      return convertedPrice;
    }
    return price;
  };

  const { unitId } = useParams();
  useEffect(() => {
    if (isNaN(Number(unitId))) {
      return;
    }
    props
      .getUnitDetails(Number(unitId))
      .then((res) => {
        setUnit(res);
        setError(false);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => setLoading(false));
  }, [unitId]);
  useEffect(() => {
    if (isNaN(Number(unitId))) {
      return;
    }
    props
      .getUnitPaymentTerms(Number(unitId))
      .then((terms) => {
        setPaymentTerms(terms);
        setSelectedPaymentTerms(terms[0]);
        setError(false);
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  }, [unitId]);

  let downPaymentOptions: OptionValue[] = [];
  let installmentFreqOptions: OptionValue[] = [];

  const selectedDownPayment = selectedPaymentTerms
    ? {
        label: `${selectedPaymentTerms.downpayment_percent}%`,
        value: selectedPaymentTerms.downpayment_percent,
      }
    : null;
  const installmentFreq = selectedPaymentTerms
    ? {
        label:
          InstallmentFrequencyMapper[
            selectedPaymentTerms.installment_frequency
          ],
        value: selectedPaymentTerms.installment_frequency,
      }
    : null;

  if (paymentTerms?.length > 0) {
    downPaymentOptions = paymentTerms.reduce((options: OptionValue[], term) => {
      if (!options.find((opt) => opt.value === term.downpayment_percent)) {
        options.push({
          label: `${term.downpayment_percent}%`,
          value: term.downpayment_percent,
        });
      }
      return options;
    }, []);
    installmentFreqOptions = paymentTerms.reduce(
      (options: OptionValue[], term) => {
        if (!options.find((opt) => opt.value === term.installment_frequency)) {
          options.push({
            label: InstallmentFrequencyMapper[term.installment_frequency],
            value: term.installment_frequency,
          });
        }
        return options;
      },
      []
    );
  }
  const userContext = useContext(USER_CONTEXT);
  const location = useLocation();

  async function handleDownloadSales() {
    props.analyticsUnitSalesOfferDownloadCustomEvent?.({
      userId: userContext.user?.id.toString(),
      timestamp: Date.now().toString(),
      portal: props.portal,
      pageName: location.pathname,
    });
    setLoadingPDF(true);
    try {
      if (unit && selectedPaymentTerms) {
        const pdfData = await downloadSalesOffer(unit, selectedPaymentTerms);
        if (!pdfData) {
          throw new Error('Failed to download PDF.');
        }

        const file = new File([pdfData], `Unit_${unit.name}_sales_offer.pdf`, {
          type: 'application/pdf',
        });
        setDownloadedPdf(file);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingPDF(false);
    }
  }
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return (
      <div id="payment-plan" className={`${styles['wrapper']} card`}>
        <h3>No payment plan found for this unit</h3>
      </div>
    );
  }

  const handleDownloadOmanSalesOffer = async () => {
    setLoadingPDF(true);

    if (unit) {
      try {
        props.analyticsUnitSalesOfferDownloadCustomEvent?.({
          userId: userContext.user?.id.toString(),
          timestamp: Date.now().toString(),
          portal: props.portal,
          pageName: location.pathname,
        });

        const pdfBlob = await props.downloadOmanSalesOffer?.(
          unit.id,
          unit.name
        );

        if (!pdfBlob) {
          throw new Error('Failed to download PDF.');
        }

        const file = new File([pdfBlob], `Unit_${unit.name}_sales_offer.pdf`, {
          type: 'application/pdf',
        });
        setDownloadedPdf(file);
      } catch (err) {
        console.error(err);
      } finally {
        setLoadingPDF(false);
      }
    }
  };
  const showSalesOfferBtn =
    unit?.project.destination.country.slug === CountriesEnum.OMAN ||
    (unit?.project.sales_offer && selectedPaymentTerms);

  const hideUnitPrice =
    !selectedPaymentTerms?.unit_price &&
    !selectedPaymentTerms?.discount_amount &&
    !selectedPaymentTerms?.price_after_discount;

  const showBeforeInstallment =
    Boolean(
      selectedPaymentTerms?.total_number_of_installments_before_delivery
    ) &&
    Boolean(
      selectedPaymentTerms?.unit_installment_before_delivery_per_frequency
    );

  const showAfterInstallment =
    Boolean(
      selectedPaymentTerms?.total_number_of_installments_after_delivery
    ) &&
    Boolean(
      selectedPaymentTerms?.unit_installment_after_delivery_per_frequency
    );

  const showInstallmentAmount = Boolean(
    selectedPaymentTerms?.installment_amount
  );

  const showDeliveryPercent = Boolean(selectedPaymentTerms?.delivery_percent);

  const hideUnitInstallments =
    unit?.project.destination.country.slug !== CountriesEnum.OMAN
      ? !selectedPaymentTerms?.first_installment_amount &&
        !selectedPaymentTerms?.delivery_amount &&
        !showBeforeInstallment &&
        !showAfterInstallment
      : !showInstallmentAmount && !showDeliveryPercent;

  const installmentDurations = extractUniqueDurations(paymentTerms);

  const displayedPaymentDetails = { ...selectedPaymentTerms };
  if (paymentTerms && selectedPaymentTerms) {
    if (currency !== selectedPaymentTerms.currency && !isLoading) {
      displayedPaymentDetails.unit_price = convertPricePerCurrency(
        selectedPaymentTerms.unit_price
      );
      displayedPaymentDetails.discount_amount = convertPricePerCurrency(
        selectedPaymentTerms.discount_amount
      );
      displayedPaymentDetails.price_after_discount = convertPricePerCurrency(
        selectedPaymentTerms.price_after_discount
      );
      if (unit?.project.destination.country.slug !== CountriesEnum.OMAN) {
        displayedPaymentDetails.unit_installment_before_delivery_per_frequency =
          convertPricePerCurrency(
            selectedPaymentTerms.unit_installment_before_delivery_per_frequency
          );
        displayedPaymentDetails.unit_installment_after_delivery_per_frequency =
          convertPricePerCurrency(
            selectedPaymentTerms.unit_installment_after_delivery_per_frequency
          );
        displayedPaymentDetails.first_installment_amount =
          convertPricePerCurrency(
            selectedPaymentTerms.first_installment_amount
          );
        displayedPaymentDetails.delivery_amount = convertPricePerCurrency(
          selectedPaymentTerms.delivery_amount
        );
      } else {
        displayedPaymentDetails.installment_amount = convertPricePerCurrency(
          selectedPaymentTerms.installment_amount as number
        );
      }
      displayedPaymentDetails.clubhouse_fees = convertPricePerCurrency(
        selectedPaymentTerms.clubhouse_fees
      );
      displayedPaymentDetails.maintenance_fees = convertPricePerCurrency(
        selectedPaymentTerms.maintenance_fees
      );
      displayedPaymentDetails.clubhouse_fees_installment_amount =
        convertPricePerCurrency(
          selectedPaymentTerms.clubhouse_fees_installment_amount
        );
      displayedPaymentDetails.total_unit_price = convertPricePerCurrency(
        selectedPaymentTerms.total_unit_price
      );
      displayedPaymentDetails.prereservation_amount = convertPricePerCurrency(
        selectedPaymentTerms.prereservation_amount
      );
      displayedPaymentDetails.downpayment_amount = convertPricePerCurrency(
        selectedPaymentTerms.downpayment_amount
      );
      displayedPaymentDetails.currency = currency;
    }
  }

  return (
    <div id="payment-plan" className={`${styles['wrapper']} card`}>
      <h3>{t('paymentPlan')}</h3>
      <p className={styles['note']}>
        {t('userOurPaymentCalculatorAndCustomizeYourPayment')}
      </p>
      <div className={styles['grid']}>
        <div className="form__input">
          <label>{t('downpayment')}</label>
          <div className={styles['select-wrapper']}>
            <SelectDropdown
              options={downPaymentOptions}
              onChange={(val) =>
                onSelectDownPayment(val?.value as number, paymentTerms)
              }
              placeholder={t('downpayment')}
              selectedOption={selectedDownPayment}
            />
            {displayedPaymentDetails && (
              <span className={styles['select-value']}>
                {Numeral(displayedPaymentDetails?.downpayment_amount).format(
                  '0,0'
                )}{' '}
                {displayedPaymentDetails?.currency}
              </span>
            )}
          </div>
        </div>
        <div className="form__input">
          <label>{t('installmentFrequency')}</label>
          <SelectDropdown
            options={installmentFreqOptions}
            onChange={(val) =>
              onSelectFrequency(val?.value as number, paymentTerms)
            }
            placeholder={t('installmentFrequency')}
            selectedOption={installmentFreq}
          />
        </div>
        <div className="form__input">
          <div className={styles['flex']}>
            <label>{t('installmentDuration')}</label>
            <h5>
              {displayedPaymentDetails?.installment_durationin_years}{' '}
              {t('years')}
            </h5>
          </div>
          <RangeSlider
            className={styles['range']}
            sliderValues={installmentDurations}
            inputValue={displayedPaymentDetails?.installment_durationin_years}
            onChange={(val) => {
              onSelectDuration(val, paymentTerms);
            }}
            sliderWrapperProps={{ className: styles['range-wrapper'] }}
          />
        </div>
      </div>
      {displayedPaymentDetails && (
        <>
          {hideUnitPrice || (
            <>
              <h4 className={styles['sub-title']}>{t('unitPrice')}</h4>
              <div className={styles['grid']}>
                <div className="card">
                  <p className={styles['label']}>{t('originalUnitPrice')}</p>
                  <h2 className={styles['value']}>
                    {`${Numeral(displayedPaymentDetails?.unit_price).format(
                      '0,0'
                    )} ${displayedPaymentDetails?.currency}`}
                  </h2>
                </div>
                <div className="card">
                  <p className={styles['label']}>{t('discount')}</p>
                  <h2 className={styles['value']}>
                    {`- ${Numeral(
                      displayedPaymentDetails?.discount_amount
                    ).format('0,0')} ${displayedPaymentDetails?.currency} (${
                      displayedPaymentDetails?.discount_percent
                    }%)`}
                  </h2>
                </div>
                {displayedPaymentDetails?.discount_amount !== 0 && (
                  <div className={`card ${styles['card-blue']}`}>
                    <p className={styles['label']}>
                      {t('unitPriceAfterDiscount')}
                    </p>
                    <h2 className={styles['value']}>
                      {`${Numeral(
                        displayedPaymentDetails?.price_after_discount
                      ).format('0,0')} ${displayedPaymentDetails?.currency}`}
                    </h2>
                  </div>
                )}
              </div>
            </>
          )}

          {hideUnitInstallments || (
            <>
              <h4 className={styles['sub-title']}>{t('unitInstallment')}</h4>
              <div className={styles['grid']}>
                {displayedPaymentDetails.first_installment_amount ? (
                  <div className="card">
                    <p className={styles['label']}>
                      {t('First Installment')} .{' '}
                      <small>
                        {displayedPaymentDetails.first_installment_percent}%
                      </small>
                    </p>
                    <h2 className={styles['value']}>
                      {`${Numeral(
                        displayedPaymentDetails?.first_installment_amount
                      ).format('0,0')} ${displayedPaymentDetails?.currency} `}
                    </h2>
                  </div>
                ) : null}
                {displayedPaymentDetails.total_number_of_installments_before_delivery ? (
                  <div className="card">
                    <p className={styles['label']}>
                      {t('installmentBeforeDelivery')} .{' '}
                      <small>
                        {
                          displayedPaymentDetails.total_number_of_installments_before_delivery
                        }
                      </small>
                    </p>
                    <h2 className={styles['value']}>
                      {`${Numeral(
                        displayedPaymentDetails?.unit_installment_before_delivery_per_frequency
                      ).format('0,0')} ${displayedPaymentDetails?.currency} `}
                      <small>
                        /{' '}
                        {
                          InstallmentFrequencyMapper[
                            displayedPaymentDetails?.installment_frequency as number
                          ]
                        }
                      </small>
                    </h2>
                  </div>
                ) : null}
                {displayedPaymentDetails.total_number_of_installments_before_delivery ? (
                  <div className="card">
                    <p className={styles['label']}>
                      {t('installmentAfterDelivery')} .{' '}
                      <small>
                        {
                          displayedPaymentDetails.total_number_of_installments_after_delivery
                        }
                      </small>
                    </p>
                    <h2 className={styles['value']}>
                      {`${Numeral(
                        displayedPaymentDetails?.unit_installment_after_delivery_per_frequency
                      ).format('0,0')} ${displayedPaymentDetails?.currency} `}
                      <small>
                        /{' '}
                        {
                          InstallmentFrequencyMapper[
                            displayedPaymentDetails?.installment_frequency as number
                          ]
                        }
                      </small>
                    </h2>
                  </div>
                ) : null}
                {displayedPaymentDetails?.installment_amount ? (
                  <div className="card">
                    <p className={styles['label']}>
                      {t('unitInstallment')} .{' '}
                      <small>
                        {displayedPaymentDetails.total_number_of_installments}
                      </small>
                    </p>
                    <h2 className={styles['value']}>
                      {`${Numeral(
                        displayedPaymentDetails?.installment_amount
                      ).format('0,0')} ${displayedPaymentDetails?.currency} `}
                      <small>
                        /{' '}
                        {
                          InstallmentFrequencyMapper[
                            displayedPaymentDetails?.installment_frequency as number
                          ]
                        }
                      </small>
                    </h2>
                  </div>
                ) : null}
                {displayedPaymentDetails?.delivery_amount ? (
                  <div className="card">
                    <p className={styles['label']}>
                      {t('deliveryAmount')} .{' '}
                      <small>{displayedPaymentDetails.delivery_percent}%</small>
                    </p>
                    <h2 className={styles['value']}>
                      {`${Numeral(
                        displayedPaymentDetails?.delivery_amount
                      ).format('0,0')} ${displayedPaymentDetails?.currency} `}
                    </h2>
                  </div>
                ) : null}
              </div>
            </>
          )}
          {displayedPaymentDetails?.clubhouse_fees ||
          displayedPaymentDetails?.maintenance_fees ? (
            <>
              <h4 className={styles['sub-title']}>
                {t('clubhouseAndMaintenanceFees')}
              </h4>
              <div className={styles['grid']}>
                {displayedPaymentDetails?.clubhouse_fees &&
                  !unit?.is_commercial && (
                    <>
                      <div className="card">
                        <p className={styles['label']}>{t('clubhouseFees')}</p>
                        <h2 className={styles['value']}>
                          {`${Numeral(
                            displayedPaymentDetails?.clubhouse_fees
                          ).format('0,0')} ${
                            displayedPaymentDetails?.currency
                          } `}
                        </h2>
                      </div>

                      <div className="card">
                        <p className={styles['label']}>
                          {t('clubhouseInstallments')} .{' '}
                          <small>
                            {
                              displayedPaymentDetails?.clubhouse_fees_installment_count
                            }{' '}
                            {t('installments')}
                          </small>
                        </p>
                        <h2 className={styles['value']}>
                          {`${Numeral(
                            displayedPaymentDetails?.clubhouse_fees_installment_amount
                          ).format('0,0')} ${
                            displayedPaymentDetails?.currency
                          } `}
                        </h2>
                      </div>
                    </>
                  )}
                {displayedPaymentDetails?.maintenance_fees && (
                  <div className="card">
                    <p className={styles['label']}>{t('maintenanceFees')}</p>
                    <h2 className={styles['value']}>
                      {`${Numeral(
                        displayedPaymentDetails.maintenance_fees
                      ).format('0,0')} ${displayedPaymentDetails?.currency}`}
                    </h2>
                  </div>
                )}
              </div>
            </>
          ) : null}

          <div className={`card ${styles['card-blue--container']}`}>
            <div className={` ${styles['total-price']} ${styles['card-blue']}`}>
              <h3>
                {t('totalUnitPrice')} <br />{' '}
                {displayedPaymentDetails?.clubhouse_fees ||
                displayedPaymentDetails?.maintenance_fees
                  ? t('includingClubhouseMaintenanceFees')
                  : ''}
              </h3>
              <h2>
                {`${Numeral(displayedPaymentDetails?.total_unit_price).format(
                  '0,0'
                )} ${displayedPaymentDetails?.currency}`}
              </h2>
            </div>
            {unit?.project.destination.country.slug === CountriesEnum.OMAN ? (
              <p>
                {' '}
                <img src={InfoIcon} alt="info-icon" />
                {t('omanPaymentDisclaimer')}
              </p>
            ) : null}
          </div>
        </>
      )}

      {showSalesOfferBtn && (
        <div className={`${styles['pdf-buttons']}`}>
          {' '}
          <button
            id="download-btn"
            className={`${styles['download-btn']}`}
            onClick={() => {
              if (
                unit?.project.destination.country.slug === CountriesEnum.OMAN
              ) {
                handleDownloadOmanSalesOffer();
              } else {
                handleDownloadSales();
              }
            }}
          >
            {loadingPDF ? <Loader /> : t('downloadSalesOffer')}
          </button>
          {props.portal === 'Sales Man' && (
            <div
              className={`${
                !downloadedPdf && styles['navigation-link--disabled']
              } `}
            >
              <button
                className={`${styles['download-btn']} ${
                  !downloadedPdf ? styles['disabled'] : ''
                }`}
                onClick={() => handleSharePdf(unit, downloadedPdf)}
                disabled={!downloadedPdf}
              >
                {t('shareSalesOffer')}
              </button>

              {!downloadedPdf ? (
                <span className="info-msg">
                  <p>{t('generationErrorMessage')}</p>
                </span>
              ) : null}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default UnitPaymentPlan;
