import { useContext, useEffect, useState } from 'react';
import styles from './lead-activity-log.module.scss';
import { useTranslation } from 'react-i18next';
import { Deal as DealUtils } from '../../utils/deal.utils';
import { Feedback } from '../../definitions/interfaces/deals.interface';
import { toast } from 'react-toastify';
import { Spinner } from '@orascom/common-components';
import { COUNTRY_SELECTED_CONTEXT } from '../../context/country-selected-context';
import { SalesManagerCountries } from '../../utils/country-selection';
import { useParams } from 'react-router-dom';

export function LeadActivityLog(
  props: Readonly<{ pipelineSourceId: string; refetchActivityLog: boolean }>
) {
  const { t } = useTranslation();
  const [feedbackList, setFeedbackList] = useState<Feedback[]>();
  const [loading, setLoading] = useState(true);

  const activeCountry = useContext(COUNTRY_SELECTED_CONTEXT);
  const { dealId } = useParams();

  useEffect(() => {
    setLoading(true);

    if (
      activeCountry.activeCountry?.value === SalesManagerCountries.MONTENEGRO
    ) {
      DealUtils.listMontenegroFeedback(
        dealId ?? '',
        activeCountry.activeCountry?.value.toString() ?? ''
      )
        .then((res) => {
          setFeedbackList(res);
          setLoading(false);
        })
        .catch(() => {
          toast.error('Error fetching activity log');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      DealUtils.listFeedback(
        props.pipelineSourceId,
        activeCountry.activeCountry?.value.toString() ?? ''
      )
        .then((res) => {
          setFeedbackList(res);
          setLoading(false);
        })
        .catch(() => {
          toast.error('Error fetching activity log');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [props.pipelineSourceId, props.refetchActivityLog, activeCountry]);

  const showNoLogs = !loading && feedbackList?.length === 0;

  return (
    <div className={styles['activity-log']}>
      <p className={styles['title']}>{t('activityLog')}</p>
      <table>
        <thead>
          <tr>
            <th>{t('action')}</th>
            <th>{t('date')}</th>
            <th>{t('comment')}</th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <div className={styles['spinner-wrapper']}>
                <Spinner />
              </div>
            </tr>
          )}
          {feedbackList?.map((feedback) => {
            const date =
              activeCountry.activeCountry?.value ===
              SalesManagerCountries.MONTENEGRO
                ? new Date(feedback.source_created_at)
                : new Date(feedback.source_updated_at);

            const formattedDate = date.toLocaleString('en-GB', {
              day: '2-digit',
              month: 'long',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              hour12: true,
            });
            return (
              <tr key={feedback.feedback_source_id}>
                <td>
                  <p>{feedback.feedback}</p>
                </td>
                <td>
                  <p>{formattedDate}</p>
                </td>
                <td>
                  <p>{feedback.feedback_comment ?? '-'}</p>
                </td>
              </tr>
            );
          })}
          {showNoLogs && (
            <tr>
              <p className={styles['no-logs']}>{t('noLogsFound')}</p>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default LeadActivityLog;
