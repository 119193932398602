import { useTranslation } from 'react-i18next';
import { MontenegroPipelineStageEnum } from '../../definitions/interfaces/deals.interface';
import styles from './deal-details-breadcrumbs.module.scss';
interface DealDetailsBreadcrumbsProps {
  dealStatus: MontenegroPipelineStageEnum;
  page: 'Customer' | 'Lead';
}

export function MontenegroDealDetailsBreadcrumbs({
  page,
  dealStatus,
}: Readonly<DealDetailsBreadcrumbsProps>) {
  const { t } = useTranslation();

  const isLostPipeline = dealStatus === MontenegroPipelineStageEnum.LOST;

  const isFirstStageActive = true;
  const isSecondStageActive = [
    MontenegroPipelineStageEnum.SECOND_STAGE,
    MontenegroPipelineStageEnum.THIRD_STAGE,
    MontenegroPipelineStageEnum.FOURTH_STAGE,
    MontenegroPipelineStageEnum.LAST_STAGE,
  ].includes(dealStatus);
  const isThirdStageActive = [
    MontenegroPipelineStageEnum.THIRD_STAGE,
    MontenegroPipelineStageEnum.FOURTH_STAGE,
    MontenegroPipelineStageEnum.LAST_STAGE,
  ].includes(dealStatus);
  const isFourthStageActive =
    dealStatus === MontenegroPipelineStageEnum.FOURTH_STAGE ||
    dealStatus === MontenegroPipelineStageEnum.LAST_STAGE;

  const getActiveClass = (isActive: boolean) => {
    let className = styles['breadcrumb'];
    if (isActive && !isLostPipeline) {
      className += ` ${styles['breadcrumb--active']}`;
    }
    return className;
  };

  return (
    <div className={`${styles['wrapper']} ${styles['wrapper-montenegro']}`}>
      <button
        className={`${styles['breadcrumb']}  ${getActiveClass(
          isFirstStageActive
        )}`}
      >
        <h4>{t('10percent')}</h4>
        <p>
          {page === 'Lead'
            ? t('10percentDescription')
            : t('10percentCustomerDescription')}
        </p>
      </button>
      <button className={getActiveClass(isSecondStageActive)}>
        <h4>{t('50percent')}</h4>
        <p>
          {page === 'Lead'
            ? t('50percentDescription')
            : t('50percentCustomerDescription')}
        </p>
      </button>
      <button className={getActiveClass(isThirdStageActive)}>
        <h4>{t('70percent')}</h4>
        <p>{t('70percentDescription')}</p>
      </button>
      <button className={getActiveClass(isFourthStageActive)}>
        <h4>{t('90percent')}</h4>
        <p>{t('90percentDescription')}</p>
      </button>
    </div>
  );
}

export default MontenegroDealDetailsBreadcrumbs;
