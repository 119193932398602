import updatesIcon from '../../assets/icons/updates.svg';
import { notificationTimeCalculator } from '@orascom/utils';
import styles from './notifications-wrapper.module.scss';
import { UpdatesInterface } from '@orascom/api-interfaces';
// import { Link } from 'react-router-dom';
// // import { Updates as UpdatesUtils } from '../../utils/updates';
// import arrowIcon from '../../assets/icons/left-arrow.svg';
// import whiteArrowIcon from '../../assets/icons/white-arrow.svg';

export interface NotificationsUpdatesProps {
  notification: UpdatesInterface;
  dashboard?: boolean;
}
export function NotificationsUpdates(
  props: Readonly<NotificationsUpdatesProps>
) {
  return (
    <div
      key={props.notification.id}
      className={`card ${props.dashboard ? styles['news'] : styles['update']}`}
    >
      <div className={styles['info']}>
        <div className={styles['icon']}>
          <img src={updatesIcon} alt="" role="presentation" />
        </div>
        <p
          style={{
            fontWeight:
              !props.dashboard && !props.notification.is_read ? 'bold' : '',
          }}
          className={styles['content']}
        >
          {props.notification.message}
        </p>
      </div>
      {/* TODO uncomment when needed */}
      {/* {props.notification.redirect_data.lead_id ? (
        // <Link
        //   to="/"
        //   state={{ saleId: props.notification.redirect_data.sale_id }}
        //   className={styles['more-details']}
        //   // onClick={() => {
        //   //   UpdatesUtils.readNotifications([props.notification.id])
        //   //     .then()
        //   //     .catch((err) => console.error(err));
        //   // }}
        // >
        //   {t('moreDetails')}
        //   <img
        //     className={styles['arrow-right']}
        //     src={props.dashboard ? whiteArrowIcon : arrowIcon}
        //     alt=""
        //     role="presentation"
        //   />
        // </Link>
      ) : null} */}
      <div className={styles['time']}>
        {notificationTimeCalculator(props.notification.created_at)}
      </div>
    </div>
  );
}

export default NotificationsUpdates;
